<script>
import useAlertStore from '@/stores/alert'
import Alert from './Alert'

export default {
  components: {
    Alert
  },
  setup() {
    const alertStore = useAlertStore()
    return {
      alertStore
    }
  },
  data() {
    return {
      alerts: []
    }
  },
  watch: {
    'alertStore.index'() {
      const { content, title, index, variant, timeout } = this.alertStore
      this.alerts.push({ content, title, index, variant, timeout })
      this.alertStore.resetAlert()
    },
    $route() {
      this.alerts = []
    }
  }
}
</script>
<template>
  <div class="global-alert-container">
    <alert v-for="alertData in alerts" :key="alertData.index" :alert="alertData" />
  </div>
</template>
<style scoped>
.global-alert-container {
  position: fixed;
  right: 2.5rem;
  top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
