<script>
export default {
  props: {
    currentValue: {
      type: Number,
      required: true
    },
    maxValue: {
      type: Number,
      required: true
    }
  }
}
</script>
<template>
  <div class="big-quota-bar">
    <b-progress id="quota-usage-progress-bar" :max="maxValue">
      <b-progress-bar :value="currentValue" variant="primary" />
    </b-progress>
    <label
      class="progress-value"
      :style="{ width: `${(currentValue / maxValue) * 100}%` }"
      for="quota-usage-progress-bar"
    >
      {{ currentValue | formatBytes(0) }}
    </label>
    <span class="max-value">
      {{ maxValue | formatBytes(0) }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
@use '/src/assets/css/app';
.big-quota-bar {
  position: relative;
  padding: 3.375rem 0;
}
@include app.media-breakpoint-up(xl) {
  .big-quota-bar {
    width: 80%;
  }
}
.progress-value {
  margin-top: 0.25rem;
  margin-bottom: 0;
  text-align: center;
  min-width: fit-content;
}
.max-value {
  position: absolute;
  margin-top: 0.25rem;
  right: 0;
}
</style>
