<script>
export default {
  name: 'ContactInformation',
  props: {
    contactName: {
      type: String,
      required: false,
      default: null
    },
    contactEmail: {
      type: String,
      required: true,
      default: null
    },
    contactPhone: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>

<template>
  <span>
    <span v-if="contactName && (contactEmail || contactPhone)"> {{ contactName }},&nbsp; </span>
    <span v-if="contactName && !contactEmail && !contactPhone">
      {{ contactName }}
    </span>
    <span v-if="contactEmail.length > 0">
      <b-icon icon="envelope" />
    </span>
    <a v-if="contactEmail && contactPhone" :href="`mailto:${contactEmail}`" target="_blank">
      {{ contactEmail }},&nbsp;
    </a>
    <a v-if="contactEmail && !contactPhone" :href="`mailto:${contactEmail}`" target="_blank">
      {{ contactEmail }}
    </a>
    <span v-if="contactPhone">
      <b-icon icon="phone" />
      {{ contactPhone }}
    </span>
  </span>
</template>
