import UserProfileBackendAccess from '@/services/UserProfileBackendAccess'
import BackendAccess from '@/services/BackendAccess'
import { getters, mutations } from '@/common/store.js'

class AuthenticationService {
  async attemptAuthentication() {
    await this.storeUserProfile()
    await this.updateSessionToken()
  }

  async storeUserProfile() {
    const profileBackend = new UserProfileBackendAccess()
    const profile = await profileBackend.getProfile()
    mutations.setUser(profile)
  }

  async updateSessionToken() {
    const access = new UserProfileBackendAccess()

    const token = await access.createUserSessionToken()
    mutations.setSessionToken(token)

    // Token is active for 1 hour; renew it 5 minutes in advance
    const self = this
    setTimeout(async () => {
      await self.updateSessionToken()
    }, 55 * 60 * 1000)
  }

  isAuthenticated() {
    return Boolean(getters.getUser())
  }

  loginUrl() {
    return getters.getBackendConfiguration().ssoApiUrl
      + '/login?service=PAS&redirect_url='
      + encodeURIComponent(window.document.location)
  }

  logoutUrl() {
    return getters.getBackendConfiguration().ssoApiUrl
      + '/logout?service=PAS&redirect_url='
      + encodeURIComponent(window.location.origin + window.location.pathname)
  }

  async logout() {
    const access = new BackendAccess()
    await access.service.get(`${access.basePath}logout`)
    // The call to SSO could in theory hang and timeout, leaving the logout incomplete.
    // Wipe out the user manually in case that happens
    mutations.setUser(null)
    document.location = this.logoutUrl()
  }
}

export default AuthenticationService
