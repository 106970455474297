<script>
export default {
  name: 'AppFooter',
  methods: {
    getAccessibilityLocation() {
      return '/docs/' + this.$gettext('accessibility.html')
    }
  }
}
</script>

<template>
  <footer id="footer" class="d-flex flex-column align-items-center p-4 text-center">
    <p>
      <translate>Contact: </translate>
      <a href="mailto:pas-support@csc.fi" target="_blank"> pas-support@csc.fi </a>
    </p>
    <p>
      <a :href="getAccessibilityLocation()">
        <translate>Accessibility statement</translate>
      </a>
      |
      <a href="/docs/">
        <translate translate-comment="Refers to manage.fairdata.fi/docs"> Documentation </translate>
      </a>
    </p>
  </footer>
</template>

<style lang="scss" scoped>
@use '/src/assets/css/app';

#footer {
  margin-top: auto;
  box-shadow: 0px -3px 5px 0px app.$gray-footer-shadow;
}
#footer a,
p {
  color: app.$gray-footer-text;
}
</style>
