<script>
import FileStorageEntry from '@/views/FileStorageBrowserPage/FileStorageEntry'

export default {
  components: {
    FileStorageEntry
  },
  props: {
    entries: {
      type: Array,
      required: true
    },
    pendingDirectories: {
      type: Array,
      required: true
    }
  },
  computed: {
    storageItems() {
      return this.entries.map(
        ({
          depth,
          name,
          opened,
          path,
          type,
          uploadActive,
          bytesUploaded,
          bytesTotal,
          extractDirName
        }) => {
          const isLoadingItem = type === 'loading'
          const id = isLoadingItem ? `${path}-loading` : path
          return {
            id,
            title: name,
            depth: depth - 1,
            isOpen: opened,
            isDir: type === 'dir',
            isRoot: path === '/',
            isLoadingItem,
            type,
            uploadActive,
            bytesUploaded,
            bytesTotal,
            extractDirName
          }
        }
      )
    }
  }
}
</script>
<template>
  <div>
    <DynamicScroller :items="storageItems" :min-item-size="40" page-mode class="mb-4">
      <template #default="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :data-index="index"
          :size-dependencies="[item.type, item.title]"
        >
          <file-storage-entry :item="item" v-on="$listeners" />
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
  </div>
</template>
