<script>
import AgreementBackendAccess from '@/services/AgreementBackendAccess'
import Pagination from '@/common/Pagination'
import QuotaProgressBar from '@/common/QuotaProgressBar'

export default {
  components: {
    Pagination,
    QuotaProgressBar
  },
  data() {
    return {
      rows: 0,
      perPage: 5,
      currentPage: 1,
      isBusy: true,
      items: []
    }
  },
  computed: {
    fields() {
      return [
        { key: 'agreementName', label: this.$gettext('Agreement name') },
        { key: 'quotaUsed', label: this.$gettext('Quota used') },
        { key: 'rights', label: this.$gettext('Rights') }
      ]
    }
  },
  async mounted() {
    this.isBusy = false
    await this.getAgreements()
  },
  methods: {
    formatRights(rights) {
      const rightsArray = []
      for (const right in rights) {
        if (rights[right]) {
          rightsArray.push(right)
        }
      }
      return rightsArray.join(', ')
    },
    mapAgreements(agreements) {
      return agreements.map((agreement) => ({
        agreementName: agreement,
        rights: this.formatRights(agreement.rights),
        quotaUsed: {
          current: agreement.quota_used,
          max: agreement.quota
        }
      }))
    },
    async getAgreements() {
      this.isBusy = true
      const access = new AgreementBackendAccess()
      const data = await access.searchAgreements(
        this.perPage,
        (this.currentPage - 1) * this.perPage
      )
      this.isBusy = false
      this.rows = data.total_count
      this.items = this.mapAgreements(data.agreements)
    }
  }
}
</script>

<template>
  <div>
    <template v-if="!isBusy && items.length == 0">
      <p>
        <strong>{{ $gettext('No agreements found') }}</strong>
      </p>
    </template>
    <template v-else>
      <b-table
        id="agreement-table"
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        stacked="xl"
      >
        <template #cell(agreementName)="data">
          <router-link
            :to="{
              name: 'AgreementPage',
              params: { id: `${data.value.identifier}` }
            }"
          >
            {{ data.value.name }}
          </router-link>
        </template>
        <template #cell(quotaUsed)="data">
          <quota-progress-bar :current-value="data.value.current" :max-value="data.value.max" />
        </template>
        <template #cell(rights)="data">
          <code class="text-default">{{ data.value }}</code>
        </template>
      </b-table>
      <pagination
        :items-amount="items.length"
        :current-page.sync="currentPage"
        :per-page="perPage"
        :rows="rows"
        :input="getAgreements"
        aria-controls="agreement-table"
      />
    </template>
  </div>
</template>
