<script>
import UserProfileBackendAccess from '@/services/UserProfileBackendAccess'
import ClipboardCopyBadge from '@/common/ClipboardCopyBadge'
import HeaderWithBackButton from '@/common/HeaderWithBackButton'
import dayjs from 'dayjs'
import { titleMixin } from '@/mixins/titleMixin'
import FormInput from '@/common/FormInput'

export default {
  components: {
    ClipboardCopyBadge,
    HeaderWithBackButton,
    FormInput
  },
  mixins: [titleMixin],
  data() {
    return {
      form: {
        name: '',
        expirationDate: '',
        projects: []
      },
      selectAll: false,
      projectsHaveBeenChanged: false,
      userProjects: [],
      createdToken: null,
      createInProgress: false,
      formSubmitTried: false,
      requestErrorMessage: ''
    }
  },
  computed: {
    isNameValid() {
      return this.form.name.length > 0
    },
    isProjectsValid() {
      const shouldShowError = this.projectsHaveBeenChanged || this.formSubmitTried
      return shouldShowError ? this.form.projects.length > 0 : null
    },
    isFormValid() {
      return this.isNameValid && this.isProjectsValid
    },
    titleItems() {
      return [this.$gettext('Create Token')]
    },
    projectsLabel() {
      return this.$gettext('Projects') + '*'
    },
    allProjectsAreSelected() {
      return this.form.projects.length === this.userProjects.length
    }
  },
  watch: {
    selectAll() {
      if (this.selectAll) {
        this.form.projects = this.userProjects
      } else if (this.allProjectsAreSelected) {
        this.form.projects = []
      }
    },
    'form.projects'() {
      this.projectsHaveBeenChanged = true
      if (!this.allProjectsAreSelected) {
        this.selectAll = false
      } else {
        this.selectAll = true
      }
    }
  },
  async mounted() {
    await this.getProjects()
  },
  methods: {
    async getProjects() {
      const access = new UserProfileBackendAccess()
      const projects = await access.getProjects()
      const projectNames = []

      for (const project of projects) {
        projectNames.push(project.identifier)
      }

      this.userProjects = projectNames
    },
    async submitForm(evt) {
      evt.preventDefault()
      this.formSubmitTried = true
      if (this.isFormValid) {
        this.createInProgress = true
        const access = new UserProfileBackendAccess()

        // Set token to null if no expiration date and to expire at midnight in local time otherwise
        const expirationDate =
          this.form.expirationDate === ''
            ? null
            : dayjs(this.form.expirationDate).hour(23).minute(59).second(59).toISOString()

        try {
          const token = await access.createUserToken({
            name: this.form.name,
            expirationDate: expirationDate,
            projects: this.form.projects
          })

          this.createdToken = token
          this.createInProgress = false
        } catch (e) {
          this.requestErrorMessage = e.message
        }
      }
    },
    checkDateValidity(ymd, date) {
      // Date picker only allows the present day or future dates
      let now = new Date().toISOString()
      // Get the YYYY-MM-DD portion of the string to compare against `ymd`
      now = now.substr(0, 10)
      return now >= ymd
    }
  }
}
</script>

<template>
  <page-container>
    <header-with-back-button :title="$gettext('Create token')" has-small-header />
    <b-form
      v-if="!createdToken && !createInProgress"
      id="tokenForm"
      name="tokenForm"
      novalidate
      @submit="submitForm"
    >
      <form-input
        id="input-token-name"
        v-model="form.name"
        :label="$gettext('Name')"
        :required="true"
        :is-valid="isNameValid"
        :form-submit-tried="formSubmitTried"
      />
      <b-form-group
        id="expiration-date-picker"
        :description="$gettext(`If no date selected, token won't expire unless revoked manually`)"
        label-for="input-expiration-date"
        :label="$pgettext('Form Input', 'Expiration date')"
      >
        <b-form-datepicker
          id="input-expiration-date"
          v-model="form.expirationDate"
          type="date"
          name="input-expiration-date"
          :locale="$language.current"
          :date-disabled-fn="checkDateValidity"
          :label-calendar="$pgettext('Date picker', 'Calendar')"
          :label-close-button="$pgettext('Date picker', 'Close')"
          :label-current-month="$pgettext('Date picker', 'Current month')"
          :label-help="$pgettext('Date picker', 'Use cursor keys to navigate calendar dates')"
          :label-nav="$pgettext('Date picker', 'Calendar navigation')"
          :label-next-decade="$pgettext('Date picker', 'Next decade')"
          :label-next-month="$pgettext('Date picker', 'Next month')"
          :label-next-year="$pgettext('Date picker', 'Next year')"
          :label-no-date-selected="$pgettext('Date picker', 'No date selected')"
          :label-prev-decade="$pgettext('Date picker', 'Previous decade')"
          :label-prev-month="$pgettext('Date picker', 'Previous month')"
          :label-prev-year="$pgettext('Date picker', 'Previous year')"
          :label-reset-button="$pgettext('Date picker', 'Reset')"
          :label-today="$pgettext('Date picker', 'Today')"
          :label-today-button="$pgettext('Date picker', 'Select today')"
          reset-button
        />
      </b-form-group>
      <b-form-group
        id="project-selection-group"
        :label="projectsLabel"
        label-for="input-project-selection"
      >
        <b-form-checkbox
          id="input-select-all-projects"
          v-model="selectAll"
          name="select-all"
          variant="outline-secondary"
          :value="true"
          :unchecked-value="false"
          class="mt-3"
        >
          <translate>Select all</translate>
        </b-form-checkbox>
        <hr />
        <b-form-checkbox-group
          id="input-project-selection"
          v-model="form.projects"
          :options="userProjects"
          stacked
          :aria-label="projectsLabel"
          :state="isProjectsValid === false ? false : null"
        />
        <b-form-invalid-feedback :state="isProjectsValid">
          <translate>At least one project has to be selected</translate>
        </b-form-invalid-feedback>
      </b-form-group>
      <hr class="bottom-divider" />
      <div class="bottom-buttons">
        <b-button type="button" variant="outline-primary" @click="$router.back()">{{
          $gettext('Cancel')
        }}</b-button>
        <b-button type="submit" variant="primary" class="create-button">{{
          $gettext('Create')
        }}</b-button>
      </div>
    </b-form>
    <div v-else-if="createInProgress">
      <span v-if="requestErrorMessage" class="text-danger">
        {{ requestErrorMessage }}
      </span>
      <b-spinner v-else />
    </div>
    <div v-else>
      <p><translate>The created token</translate>:</p>
      <p class="token-text">
        {{ createdToken }}
        <clipboard-copy-badge :value="createdToken" class="copy-badge" />
      </p>
      <p class="smallText">
        {{
          $gettext(
            'This token will only be shown once. If you lose access to a token, you will need to create a new one.'
          )
        }}
      </p>
      <hr class="end-divider" />
      <div class="d-flex justify-content-center">
        <b-button
          type="button"
          class="ok-button"
          variant="primary"
          :to="{ name: 'FileStorageTokenPage' }"
          >Ok</b-button
        >
      </div>
    </div>
  </page-container>
</template>

<style lang="scss" scoped>
@use '/src/assets/css/app';
#button-create-new-token {
  float: right;
}
#expiration-date-picker {
  max-width: app.$input-max-width;
}
#project-selection-group {
  max-width: app.$input-max-width;
}
#project-selection-group hr {
  margin: 1.5rem 0 1.5rem - app.$checkbox-padding-y;
}
.bottom-divider {
  margin-top: 3.25rem - app.$checkbox-padding-y;
  margin-bottom: 2.5rem;
}
.bottom-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  max-width: app.$input-max-width;
}
#main-container .token-text {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin: 0.75rem 0;
}
.end-divider {
  margin-top: 3.5rem;
  margin-bottom: 2.5rem;
}
.ok-button {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  margin-bottom: 2.5rem;
}
</style>
