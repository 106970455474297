<script>
import { getters } from '@/common/store.js'
import { titleMixin } from '@/mixins/titleMixin'

export default {
  name: 'ProfilePage',
  mixins: [titleMixin],
  data() {
    return {
      user: undefined
    }
  },
  computed: {
    titleItems() {
      return [this.$gettext('Profile')]
    }
  },
  async mounted() {
    this.user = getters.getUser()
  }
}
</script>

<template>
  <page-container>
    <div v-if="user">
      <h1 v-translate>User profile</h1>
      <hr />
      <dl id="user-info">
        <dt>{{ $pgettext('User profile', 'Name') }}</dt>
        <dd>{{ user.name }}</dd>
        <dt>{{ $pgettext('User profile', 'Organization') }}</dt>
        <dd>{{ user.organization }}</dd>
      </dl>
      <p v-if="user.admin" class="d-flex align-items-center mb-4">
        <b-icon icon="check" variant="success" font-scale="2" />{{
          $gettext('User has admin rights')
        }}
      </p>
    </div>
  </page-container>
</template>

<style>
#user-info {
  display: grid;
  grid-template-columns: 10.25rem auto;
  grid-row-gap: 1rem;
  margin-top: 2rem;
}
</style>
