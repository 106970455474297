import Vue from 'vue'

// DO NOT ADD NEW FIELDS HERE unless absolutely necessary.
// If you need to persist UI state (eg. pagination state) when the user
// leaves the page and comes back later, use Vue's keep-alive element
// and keep the relevant state inside the component istead.

export const state = Vue.observable({
  user: undefined,
  sessionToken: null,
  backendConfiguration: {},
  httpOperationPending: false
})

export const getters = {
  isHttpOperationPending: () => state.httpOperationPending,
  getUser: () => state.user,
  getSessionToken: () => state.sessionToken,
  getBackendConfiguration: () => state.backendConfiguration
}

export const mutations = {
  setUser: (val) => {
    state.user = val
  },
  setSessionToken: (val) => {
    state.sessionToken = val
  },
  setBackendConfiguration: (val) => {
    state.backendConfiguration = val
  },
  setHttpOperationPending: (val) => {
    state.httpOperationPending = val
  }
}
