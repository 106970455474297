<script>
import DatasetFilterForm from '@/views/DatasetsPage/DatasetFilterForm'
import DatasetList from '@/views/DatasetsPage/DatasetList.vue'
import Pagination from '@/common/Pagination.vue'
import filesize from 'filesize'
import TranslationService from '@/services/TranslationService'

export default {
  components: {
    DatasetFilterForm,
    DatasetList,
    Pagination
  },
  props: {
    datasets: {
      type: Array,
      default: () => []
    },
    currentPage: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 5
    },
    rows: {
      type: Number,
      default: 0
    },
    doOnPaginationChange: {
      type: Function,
      required: false,
      default: () => null
    },
    isBusy: {
      type: Boolean,
      default: false,
      required: false
    },
    searchWord: {
      type: String,
      required: false,
      default: ''
    },
    showOnlyId: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    formattedDatasets() {
      return this.datasets.map((x) => ({
        preservation_state: x.passtate,
        name: TranslationService.getPreferredValue(x.name, x.datasetLanguages),
        identifier: x.identifier,
        created: x.created ? this.$options.filters.formatDate(x.created, true) : '-',
        size: x.size ? filesize(x.size, { standard: 'iec', round: 0 }) : '-',
        agreement: x.agreement || '-'
      }))
    }
  }
}
</script>

<template>
  <div>
    <dataset-filter-form
      :datasets-per-page-default="perPage"
      @search-values-changed="$emit('search-values-changed', $event)"
    />
    <div class="dataset-list">
      <template v-if="!isBusy && datasets.length == 0">
        <p class="mt-2">
          <strong>{{ $gettext('No datasets found') }}</strong>
        </p>
      </template>
      <template v-else>
        <dataset-list
          :per-page="perPage"
          :search-word="searchWord"
          :is-busy="isBusy"
          :datasets="formattedDatasets"
          :show-only-id="showOnlyId"
        />
        <pagination
          :items-amount="datasets.length"
          :per-page="perPage"
          :rows="rows"
          :input="doOnPaginationChange"
          aria-controls="dataset-table"
          :current-page="currentPage"
          @update:currentPage="$emit('update:currentPage', $event)"
        />
      </template>
    </div>
  </div>
</template>
<style scoped>
.dataset-list {
  min-height: 20rem;
}
</style>
