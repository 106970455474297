import { defineStore } from 'pinia'

const useAlertStore = defineStore('alert', {
  state: () => ({
    variantState: '',
    contentState: '',
    titleState: '',
    timeoutState: null,
    indexState: 0
  }),
  getters: {
    content: (state) => state.contentState,
    variant: (state) => state.variantState,
    title: (state) => state.titleState,
    timeout: (state) => state.timeoutState,
    index: (state) => state.indexState
  },
  actions: {
    setAlert({ variant, content, title, timeout }) {
      this.variantState = variant || ''
      this.contentState = content || ''
      this.titleState = title || ''
      this.timeoutState = timeout
      this.indexState++
    },
    resetAlert() {
      this.variantState = ''
      this.contentState = ''
      this.titleState = ''
      this.timeoutState = null
    }
  }
})

export default useAlertStore
