<script>
import FileChecksumListInput from '@/views/FileStorageBrowserPage/FileChecksumListInput'

export default {
  name: 'FileStorageUploadFilesModal',
  components: {
    FileChecksumListInput
  },
  props: {
    files: {
      type: Object,
      default: null
    },
    project: {
      type: Object,
      required: true
    },
    fileMaxSize: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      modalShow: false,
      path: '',

      filesToUpload: [],

      addChecksums: false,
      checksumsValid: false,
      checksumMap: {}
    }
  },
  computed: {
    totalQuota() {
      return this.project.quota
    },
    usedQuota() {
      return this.project.usedQuota
    },
    fileNames() {
      const names = []
      for (const file of this.filesToUpload) {
        names.push(file.name)
      }
      return names
    },
    modalTitle() {
      return this.$pgettext('Modal title', 'Upload files')
    },
    modalOkText() {
      return this.$pgettext('Button text', 'Upload')
    },
    modalCancelText() {
      return this.$pgettext('Button text', 'Cancel')
    },
    fileBrowseText() {
      return this.$pgettext('File form text', 'Browse')
    },
    uploadPlaceholder() {
      return this.$gettext('Choose files or drop them here...')
    },
    filesThatAreTooLarge() {
      return this.filesToUpload.filter((file) => file.size >= this.fileMaxSize)
    },
    uploadDropPlaceholder() {
      return this.$gettext('Drop files here...')
    },
    totalUploadSize() {
      const sizes = this.filesToUpload.map((a) => a.size)
      return sizes.reduce((a, b) => a + b, 0)
    },
    uploadExceedsQuota() {
      // TODO: This does not account for ongoing uploads
      return this.totalUploadSize + this.usedQuota > this.totalQuota
    },
    fileNameThatsTooLong() {
      const fileNameThatsTooLong = this.filesToUpload?.find((file) => file.name.length > 200)
      const fileName = fileNameThatsTooLong ? fileNameThatsTooLong.name : undefined
      return fileName
    },
    fileNameThatAlreadyExists() {
      const filesNamesInCurrentDirectory = this.files ? this.files[this.path] : []
      const fileThatAlreadyExists = this.filesToUpload?.find((file) =>
        filesNamesInCurrentDirectory?.includes(file.name)
      )
      const fileName = fileThatAlreadyExists ? fileThatAlreadyExists.name : undefined
      return fileName
    },
    fileNamesAreValid() {
      return !this.fileNameThatsTooLong && !this.fileNameThatAlreadyExists
    },
    canUploadFiles() {
      const hasFilesThatAreTooLarge = this.filesThatAreTooLarge.length > 0
      return (
        this.filesToUpload.length > 0 &&
        !hasFilesThatAreTooLarge &&
        !this.uploadExceedsQuota &&
        this.fileNamesAreValid &&
        // If checksums are enabled, ensure all files have checksums
        (!this.addChecksums || this.checksumsValid)
      )
    },
    formattedMaxFileSize() {
      return this.$options.filters.formatBytes(this.fileMaxSize, 0)
    },
    filesTooLargeErrorMessage() {
      const oversizedFiles = this.filesThatAreTooLarge.map((file) => file.name)
      const oversizedFilesText = oversizedFiles.join(', ')

      const localizeText = this.$ngettext(
        'File %{ oversizedFiles } is too large. Maximum single file size is %{ formattedMaxFileSize }.',
        'Files %{ oversizedFiles } are too large. Maximum single file size is %{ formattedMaxFileSize }.',
        oversizedFiles.length
      )

      const text = this.$gettextInterpolate(localizeText, {
        oversizedFiles: oversizedFilesText,
        formattedMaxFileSize: this.formattedMaxFileSize
      })

      return text
    }
  },
  methods: {
    returnNullIfFilesEmpty(boolean) {
      return this.filesToUpload?.length === 0 ? null : boolean
    },
    async openDialog(path) {
      this.filesToUpload = []
      this.path = path
      this.modalShow = true
      this.addChecksums = false

      // Update the remaining project quota to ensure the up-to-date value
      // is displayed
      this.$emit('update-project-quota')
    },
    uploadFiles() {
      const entries = []

      for (const file of this.filesToUpload) {
        const entry = {
          file: file
        }

        if (this.addChecksums) {
          entry.checksumAlgorithm = this.checksumMap[file.name].algorithm
          entry.checksum = this.checksumMap[file.name].checksum
        }
        entries.push(entry)
      }
      this.$emit('files-submitted', this.path, entries)
    }
  }
}
</script>

<template>
  <div>
    <b-modal
      v-model="modalShow"
      :title="modalTitle"
      :ok-title="modalOkText"
      :cancel-title="modalCancelText"
      :ok-disabled="!canUploadFiles"
      :return-focus="`#file-entry-${path.replaceAll('/', '-')}`"
      @ok="uploadFiles"
      @show="$emit('show')"
      @hidden="$emit('hidden')"
    >
      <p class="upload-title">
        <translate>Select files to upload under directory</translate>:
        <strong>{{ path }}</strong>
      </p>
      <b-form-group>
        <b-form-file
          v-model="filesToUpload"
          :state="returnNullIfFilesEmpty(canUploadFiles)"
          :browse-text="fileBrowseText"
          :placeholder="uploadPlaceholder"
          :drop-placeholder="uploadDropPlaceholder"
          multiple
        />
        <b-form-invalid-feedback :state="returnNullIfFilesEmpty(fileNamesAreValid)">
          <template v-if="fileNameThatsTooLong">
            <translate key="1"> File name too long (max 200 characters): </translate>
            <samp> {{ fileNameThatsTooLong }}</samp>
          </template>
          <template v-else-if="fileNameThatAlreadyExists">
            <translate key="2"> File name already exists: </translate>
            <samp>{{ fileNameThatAlreadyExists }}</samp>
          </template>
          <template v-else-if="filesThatAreTooLarge.length > 0">
            {{ filesTooLargeErrorMessage }}
          </template>
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- Checksum input -->
      <b-form-group>
        <b-form-checkbox v-model="addChecksums" switch>
          <translate>Verify file integrity</translate>
        </b-form-checkbox>
        <b-collapse v-model="addChecksums">
          <file-checksum-list-input
            :file-names="fileNames"
            @checksums-changed="checksumMap = $event"
            @is-valid-changed="checksumsValid = $event"
          />
        </b-collapse>
      </b-form-group>
      <hr />
      <!-- Total remaining space -->
      <b-progress v-if="totalQuota > 0" class="mt-2" :max="totalQuota">
        <b-progress-bar :value="usedQuota" variant="secondary" />
        <b-progress-bar
          :value="totalUploadSize"
          :variant="uploadExceedsQuota ? 'danger' : 'success'"
        />
      </b-progress>
      <dl class="row">
        <dd class="col-6">
          <translate>File count</translate>
        </dd>
        <dt class="col-6">
          {{ filesToUpload.length }}
        </dt>
        <dd class="col-6">
          <translate>Total upload size</translate>
        </dd>
        <dt class="col-6">
          {{ totalUploadSize | formatBytes(0) }}
        </dt>
        <dd class="col-6">
          <translate>Remaining quota after upload</translate>
        </dd>
        <dt class="col-6">
          {{ (totalQuota - totalUploadSize - usedQuota) | formatBytes(0) }}
        </dt>
      </dl>
      <b-form-invalid-feedback v-if="uploadExceedsQuota" :state="false">
        {{
          $gettext(
            'This upload will exceed the project quota. Please reduce amount of files to upload or contact service administration to increase the project quota.'
          )
        }}
      </b-form-invalid-feedback>
    </b-modal>
  </div>
</template>
<style scoped>
.upload-title {
  word-break: break-word;
}
samp {
  font-size: 0.9em;
}
</style>
