<script>
import PasStateBadge from '@/common/PasStateBadge'
import DatasetBackendAccess from '@/services/DatasetBackendAccess'
import DatasetMetadata from '@/views/DatasetPage/DatasetMetadata'
import DatasetHistory from '@/views/DatasetPage/DatasetHistory'
import ProposeDataset from '@/views/DatasetPage/ProposeDataset'
import AcceptDataset from '@/views/DatasetPage/AcceptDataset'
import DatasetData from '@/views/DatasetPage/DatasetData'
import DatasetStatus from '@/views/DatasetPage/DatasetStatus'
import Breadcrumbs from '@/views/DatasetPage/Breadcrumbs'
import DatasetSubPages from '@/services/DatasetSubPages'
import TranslationService from '@/services/TranslationService'
import { States } from '@/services/DatasetStates'
import { titleMixin } from '@/mixins/titleMixin'
import DatasetStateService from '@/services/DatasetStateService'
import TaskStatus from '@/services/TaskStatus'

/*
  Fetch dataset every minute if dataset is being processed in the
  background and its preservation status may change.
  Fetch more often when dataset is generating metadata or under validation.
*/
const DATASET_FETCH_INTERVAL = 60000
const DATASET_FETCH_INTERVAL_SMALL = 15000

export default {
  name: 'DatasetPage',
  components: {
    DatasetMetadata,
    Breadcrumbs,
    PasStateBadge,
    DatasetHistory,
    DatasetData,
    ProposeDataset,
    AcceptDataset,
    DatasetStatus
  },
  mixins: [titleMixin],
  beforeRouteLeave: async function (from, to, next) {
    // Clear the dataset background check
    clearTimeout(this.datasetCheckTimeout)
    next(true)
  },
  data() {
    return {
      TaskStatus,
      DatasetSubPages,

      dataset: undefined,
      currentPage: 0,
      datasetCheckTimeout: null,
      datasetFetchStatus: TaskStatus.PENDING,
      datasetFetchIdentifier: 0,

      // Dataset state is being changed (it's being proposed, accepted, rejected, etc.)
      // The periodic dataset fetch does *not* trigger this.
      datasetStateChanging: false
    }
  },
  computed: {
    titleItems() {
      const items = [this.$gettext('Dataset')]
      if (this.dataset) {
        const name = this.getPreferredValue(this.dataset.name)
        items.push(name)
      }
      return items
    },
    datasetCheckInterval() {
      const shouldCheckMoreOften = DatasetStateService.isInProgress(this.dataset?.passtate)
      if (shouldCheckMoreOften) {
        return DATASET_FETCH_INTERVAL_SMALL
      } else {
        return DATASET_FETCH_INTERVAL
      }
    },
    isShowDatasetStatusPage() {
      return this.dataset.passtate >= States.ACCEPTED_TO_DIGITAL_PRESERVATION
    },
    errorMessage() {
      if (this.datasetFetchStatus === TaskStatus.FAILURE) {
        return this.$pgettext('Page error message', 'Dataset could not be retrieved')
      }
      if (this.datasetFetchStatus === TaskStatus.SUCCESS && !this.dataset) {
        return this.$pgettext('Page error message', 'Dataset not found')
      }

      return null
    },
    hasError() {
      return Boolean(this.errorMessage)
    }
  },
  async mounted() {
    await this.updateDataset()
    this.startDatasetStatusCheckTimeout()
  },
  methods: {
    async updateDataset() {
      try {
        const access = new DatasetBackendAccess()
        // Use a request identifier to prevent duplicate requests. The identifier
        // is set before the request and the identifier must match after the
        // response is received. Therefore, only the most recent request
        // wins.
        const fetchIdentifier = Math.floor(Math.random() * 2 ** 32)
        this.datasetFetchIdentifier = fetchIdentifier

        const dataset = await access.getDataset(this.$route.params.id)
        // Redirect user to PAS version if it exists
        if (dataset?.pasDatasetIdentifier) {
          this.$router.push({
            name: 'DatasetPage',
            params: { id: dataset.pasDatasetIdentifier}
          })
        }

        if (this.datasetFetchIdentifier !== fetchIdentifier) {
          // This is an old request, abort
          return
        }

        this.dataset = dataset
        this.updateTitle()

        // Reset the polling to avoid a potentially redundant check immediately
        // after the update, and to ensure the possibly new check interval
        // is used.
        this.startDatasetStatusCheckTimeout()
        this.datasetFetchStatus = TaskStatus.SUCCESS
        this.datasetStateChanging = false
      } catch (e) {
        this.datasetFetchStatus = TaskStatus.FAILURE
        this.datasetStateChanging = false
        throw e
      }
    },
    /**
     * Start/restart a timer that will check the dataset status after some
     * time has passed
     */
    startDatasetStatusCheckTimeout() {
      clearTimeout(this.datasetCheckTimeout)
      this.datasetCheckTimeout = setTimeout(this.checkDatasetStatus, this.datasetCheckInterval)
    },
    /**
     * Set the flag that dataset change is in progress. The flag will be unset
     * whenever either the 'update-dataset' OR 'finish-dataset-state-change'
     * signal is received.
     */
    startDatasetStateChange() {
      this.datasetStateChanging = true
    },
    finishDatasetStateChange() {
      this.datasetStateChanging = false
    },
    /**
     * Perform a periodic check for dataset if its current preservation state
     * is something that might change without user interaction
     * (eg. it's under processing)
     */
    async checkDatasetStatus() {
      if (DatasetStateService.isVolatile(this.dataset?.passtate)) {
        await this.updateDataset()
      }
      this.startDatasetStatusCheckTimeout()
    },
    getPreferredValue(values) {
      return TranslationService.getPreferredValue(values, this.dataset.datasetLanguages)
    },
    handleSetCurrentPage(pageIndex) {
      this.currentPage = pageIndex
    },
    handleNextPage() {
      // +2 Because skips over loading page
      this.currentPage += 2
    },
    handlePreviousPage() {
      // -2 Because skips over loading page
      this.currentPage -= 2
    },
    /* Sets local dataset to what new state should be, to be more responsive to user */
    handleLocalDatasetChange(stateChanges) {
      this.dataset = { ...this.dataset, ...stateChanges }
    }
  }
}
</script>

<template>
  <page-container :has-error="hasError" :error-message="errorMessage">
    <template v-if="dataset">
      <breadcrumbs
        :pas-state="dataset.passtate"
        :current-page="currentPage"
        :dataset-state-changing="datasetStateChanging"
        @set-page="handleSetCurrentPage"
      />
      <hr />
      <h1 id="dataset-title">{{ getPreferredValue(dataset.name) }}</h1>
      <pas-state-badge :pas-state="dataset.passtate" />
      <div id="preservation-steps-container">
        <b-tabs v-if="currentPage === DatasetSubPages.IDENTIFY" id="dataset-info-tabs">
          <b-tab :title="$gettext('Dataset')" data-test="test">
            <dataset-metadata
              :dataset="dataset"
              :dataset-state-changing="datasetStateChanging"
              @local-dataset-change="handleLocalDatasetChange"
              @start-dataset-state-change="startDatasetStateChange"
              @finish-dataset-state-change="finishDatasetStateChange"
              @update-dataset="updateDataset"
              @next-page="handleNextPage"
            />
          </b-tab>
          <b-tab :title="$gettext('Data')">
            <dataset-data :dataset="dataset" />
          </b-tab>
          <b-tab :title="$gettext('History and events')">
            <dataset-history :dataset="dataset" />
          </b-tab>
        </b-tabs>
        <div v-else-if="currentPage === DatasetSubPages.PROPOSE">
          <propose-dataset
            :dataset="dataset"
            :dataset-state-changing="datasetStateChanging"
            @update-dataset="updateDataset"
            @start-dataset-state-change="startDatasetStateChange"
            @finish-dataset-state-change="finishDatasetStateChange"
            @previous-page="handlePreviousPage"
            @next-page="handleNextPage"
          />
        </div>
        <div v-else-if="currentPage === DatasetSubPages.ACCEPT">
          <dataset-status
            v-if="isShowDatasetStatusPage"
            :dataset="dataset"
            :dataset-state-changing="datasetStateChanging"
          />
          <accept-dataset
            v-else
            :dataset="dataset"
            :dataset-state-changing="datasetStateChanging"
            @update-dataset="updateDataset"
            @start-dataset-state-change="startDatasetStateChange"
            @finish-dataset-state-change="finishDatasetStateChange"
            @previous-page="handlePreviousPage"
          />
        </div>
        <div
          v-else-if="
            currentPage === DatasetSubPages.IDENTIFY_IN_PROGRESS ||
            currentPage === DatasetSubPages.VALIDATION_IN_PROGRESS
          "
        >
          <p class="mb-3">{{ $gettext('This might take several hours.') }}</p>
        </div>
      </div>
    </template>
  </page-container>
</template>
<style>
#dataset-title {
  font-weight: bold;
  font-size: 1.125rem;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
}
#preservation-steps-container {
  margin-top: 2.5rem;
}
</style>
