<script>
export default {
  props: {
    alert: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isShown: true
    }
  },
  mounted() {
    if (this.alert.timeout) {
      setTimeout(() => this.hide(), this.alert.timeout)
    }
  },
  methods: {
    hide() {
      this.isShown = false
    }
  }
}
</script>
<template>
  <div v-if="isShown" :class="`alert-${alert.variant} fd-alert`" role="alert">
    <header>
      <span>{{ alert.title || $gettext('Alert') }}</span>
      <button class="no-style-button" @click="hide">&#10799;</button>
    </header>
    <p>
      <span>{{ alert.content }}</span>
    </p>
  </div>
</template>
<style lang="scss" scoped>
@use '/src/assets/css/app';
.fd-alert {
  display: relative;
  padding: 1rem;
  border-radius: 0.25rem;
  opacity: 0.9;
  width: 20rem;
}
header {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}
button {
  color: app.$gray;
}
span {
  padding-right: 2rem;
}
header,
button,
span {
  line-height: 1rem;
}
.show {
  visibility: visible;
  opacity: 1;
}
.hide {
  visibility: hidden;
  opacity: 0;
}
</style>
