<script>
import FileBackendAccess from '@/services/FileBackendAccess'
import StorageItem from '@/common/StorageItem'
import DatasetFileMetadata from '@/views/DatasetPage/DatasetFileMetadata'

export default {
  components: {
    StorageItem,
    DatasetFileMetadata
  },
  props: {
    dataset: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      storageItems: [
        {
          isRoot: true,
          parents: [],
          id: this.dataset.directory_identifier,
          isOpen: false,
          isDir: true,
          size: this.dataset.size,
          fileCount: 0, // The real fileCount is calculated in calculateRootStorageItemFileCount()
          depth: -1
        }
      ],
      storageItemIdsWithFetchInProgress: []
    }
  },
  mounted() {
    this.addSubStorageItems(this.storageItems[0])
  },
  methods: {
    async addSubStorageItems(storageItem) {
      const isFetchingAlready = this.storageItemIdsWithFetchInProgress.includes(storageItem.id)
      if (isFetchingAlready) {
        return
      }
      this.storageItemIdsWithFetchInProgress.push(storageItem.id)

      const spinnerItem = {
        isLoadingItem: true,
        id: `spinner-${storageItem.id}`,
        depth: storageItem.depth + 1,
        parents: [storageItem.id]
      }
      this.addStorageItemsAfter(storageItem, [spinnerItem])

      const access = new FileBackendAccess()
      const response = await access.getDatasetDirectory(this.dataset.identifier, storageItem.id)
      const { data } = response

      const newSubstorageItems = []

      const addToNewSubStorageItems = (item, otherFields) => {
        newSubstorageItems.push({
          id: item.identifier,
          parents: [...storageItem.parents, storageItem.id],
          title: item.title,
          size: item.size,
          fileCount: item.file_count,
          depth: storageItem.depth + 1,
          ...otherFields
        })
      }

      for (const dir of data.directories) {
        addToNewSubStorageItems(dir, { isDir: true, isOpen: false })
      }

      for (const file of data.files) {
        addToNewSubStorageItems(file, { isDir: false, metadataOpen: false })
      }

      if (storageItem.isRoot) {
        this.calculateRootStorageItemFileCount(storageItem, newSubstorageItems)
      }

      // Only add items if fetch still in progress (as in, don't add items, if user closes folder before items are done fetching)
      const IsFetchInProgress = this.storageItemIdsWithFetchInProgress.includes(storageItem.id)
      if (IsFetchInProgress) {
        this.storageItems = this.storageItems.filter((item) => item.id !== spinnerItem.id)
        this.addStorageItemsAfter(storageItem, newSubstorageItems)
        this.setFetchNoLongerInProgress(storageItem.id)
      }
    },
    calculateRootStorageItemFileCount(rootStorageItem, rootItemSubStorageItems) {
      const subDirectoryFilesCount = rootItemSubStorageItems
        .filter((item) => item.isDir)
        .map((item) => item.fileCount)
        .reduce((a, b) => a + b, 0)

      const rootDirectoryFilesCount = rootItemSubStorageItems.filter((item) =>
        this.isFile(item)
      ).length
      const rootFileCount = subDirectoryFilesCount + rootDirectoryFilesCount
      this.storageItems[0] = { ...rootStorageItem, fileCount: rootFileCount }
    },
    addStorageItemsAfter(storageItem, storageItemsToInsert) {
      const parentIndex = this.storageItems.findIndex((item) => item.id === storageItem.id)
      this.storageItems.splice(parentIndex + 1, 0, ...storageItemsToInsert)
    },
    removeSubStorageItems(storageItem) {
      const IsFetchInProgress = this.storageItemIdsWithFetchInProgress.includes(storageItem.id)
      if (IsFetchInProgress) {
        this.setFetchNoLongerInProgress(storageItem.id)
      }
      // Remove all child/sub items of storageItem
      this.storageItems = this.storageItems.filter(
        (item) => !item?.parents.includes(storageItem.id)
      )
    },
    toggleFolder(storageItem) {
      const isOpen = !storageItem.isOpen
      this.setStorageItemFields(storageItem.id, { isOpen })
      if (isOpen) {
        this.addSubStorageItems(storageItem)
      } else {
        this.removeSubStorageItems(storageItem)
      }
    },
    toggleMetadata(storageItem) {
      this.setStorageItemFields(storageItem.id, { metadataOpen: !storageItem.metadataOpen })
      if (!storageItem.metadata) {
        this.fetchFileMetadata(storageItem)
      }
    },
    async fetchFileMetadata(storageItem) {
      const access = new FileBackendAccess()
      const metadata = await access.getDatasetFile(this.dataset.identifier, storageItem.id)
      this.setStorageItemFields(storageItem.id, { metadata })
    },
    setStorageItemFields(storageItemId, fields) {
      this.storageItems = this.storageItems.map((item) =>
        item.id === storageItemId ? { ...item, ...fields } : item
      )
    },
    setFetchNoLongerInProgress(storageItemId) {
      this.storageItemIdsWithFetchInProgress = this.storageItemIdsWithFetchInProgress.filter(
        (id) => id !== storageItemId
      )
    },
    isFile(storageItem) {
      return !storageItem.isDir
    }
  }
}
</script>

<template>
  <div id="dataset-data" data-test="dataset-data">
    <DynamicScroller :items="storageItems" :min-item-size="40" page-mode>
      <template #default="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :data-index="index"
          :size-dependencies="[item.metadataOpen, item.metadata]"
        >
          <storage-item
            :storage-item="item"
            @toggle-storage-item-folder="toggleFolder"
            @toggle-storage-item-metadata="toggleMetadata"
          >
            <template #default="slotProps">
              <b-icon v-if="slotProps.storageItem.isDir" icon="folder" />
              <b-icon v-else icon="file-text" />
              {{ slotProps.storageItem.title }}
            </template>
            <template #right-side-content="slotProps">
              <span v-if="isFile(slotProps.storageItem)">
                <b-button
                  variant="link"
                  size="sm"
                  class="show-metadata-button text-decoration-none"
                  @click="() => toggleMetadata(slotProps.storageItem)"
                >
                  <b-icon v-if="slotProps.storageItem.metadataOpen" icon="chevron-down" />
                  <b-icon v-else icon="chevron-right" />
                  {{ $pgettext('File action', 'Show metadata') }}
                </b-button>
              </span>
            </template>
            <template #extra="slotProps">
              <dataset-file-metadata :storage-item="slotProps.storageItem"
            /></template>
          </storage-item>
        </DynamicScrollerItem>
      </template>
      <template #after><hr class="bottom-divider" /></template>
    </DynamicScroller>
  </div>
</template>

<style scoped>
.vue-recycle-scroller {
  margin-bottom: 3.125rem;
}
.folder-content-placeholder {
  height: 100%;
}
#dataset-data {
  margin-top: 2.5rem;
}
.bottom-divider {
  margin-top: 0.5rem;
}
</style>
