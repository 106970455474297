<template>
  <div class="line-chart">
    <LineChart :data="chartData" :options="options" :height="200" />
  </div>
</template>

<script>
import { Line as LineChart } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import dayjs from 'dayjs'

ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale)

ChartJS.defaults.color = '#4F4F4F'
ChartJS.defaults.font.family = 'Lato'

export default {
  components: { LineChart },
  props: {
    data: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    formatAsBytes: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    chartData() {
      return {
        datasets: [
          {
            label: this.title,
            data: this.data,
            borderColor: '#0075A3',
            backgroundColor: '#0075A3'
          }
        ]
      }
    },
    options() {
      return {
        plugins: {
          title: {
            display: true,
            text: this.title,
            font: {
              size: 16,
              weight: 'normal'
            },
            padding: {
              top: 16,
              bottom: 16
            }
          },
          legend: {
            display: false
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              title(value) {
                return dayjs(value[0].label).format('MMM D, YYYY H:mm:ss')
              },
              label: this.formatAsBytes
                ? (value) =>
                    `${value.dataset.label}: ${this.$options.filters.formatBytes(
                      value.parsed.y,
                      0
                    )}`
                : (value) => `${value.dataset.label}: ${value.parsed.y}`
            }
          }
        },
        scales: {
          x: {
            ticks: {
              autoSkip: true,
              maxTicksLimit: 4,
              callback(index) {
                const label = this.getLabelForValue(index)
                return dayjs(label).format('MMM D, YYYY')
              }
            }
          },
          y: {
            ticks: {
              autoSkip: true,
              maxTicksLimit: 4,
              callback: this.formatAsBytes
                ? (value) => this.$options.filters.formatBytes(value, 0)
                : (value) => value
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '/src/assets/css/app';
.line-chart {
  outline: 1px solid app.$gray-light;
  margin-top: 1px;
  margin-left: 1px;
  padding: 0.5rem 0.5rem;
  width: 100%;
}
@include app.media-breakpoint-up(lg) {
  .line-chart {
    width: 50%;
  }
}
</style>
