<script>
export default {
  name: 'RelatedService',
  props: {
    serviceName: {
      type: String,
      required: true
    },
    serviceIdentifier: {
      type: String,
      required: true
    }
  }
}
</script>

<template>
  <span> {{ serviceName }}, {{ serviceIdentifier }} </span>
</template>

<style scoped></style>
