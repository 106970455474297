<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    isValid: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: false
    },
    formSubmitTried: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:value'],
  data() {
    return {
      hasBeenUnFocused: false,
      hasBeenEdited: false
    }
  },
  computed: {
    isValidAndItShouldBeShown() {
      const shouldShowValidationError =
        this.hasBeenUnFocused || this.hasBeenEdited || this.formSubmitTried
      if (shouldShowValidationError) {
        return this.isValid
      }
      return null
    },
    labelWithRequire() {
      return this.label + (this.required ? '*' : '')
    }
  },
  methods: {
    handleUnFocus() {
      this.hasBeenUnFocused = true
    },
    handleValueChange(newValue) {
      this.hasBeenEdited = true
      this.$emit('input', newValue)
    }
  }
}
</script>
<template>
  <b-form-group :label="labelWithRequire" :label-for="id" class="fd-form-input">
    <b-form-input
      :id="id"
      :name="id"
      :type="type"
      :state="isValidAndItShouldBeShown"
      :value="value"
      :required="required"
      @input="handleValueChange"
      @focusout="handleUnFocus"
    />
    <b-form-invalid-feedback v-if="value.length === 0">
      <translate>Field cannot be empty</translate>
    </b-form-invalid-feedback>
    <slot name="feedback" />
    <small><slot name="description" /></small>
  </b-form-group>
</template>

<style lang="scss">
@use '/src/assets/css/app';
#main-container .fd-form-input input {
  max-width: app.$input-max-width;
}
#main-container .fd-form-input {
  margin-bottom: 2rem;
}
</style>
