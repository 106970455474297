<script>
import Vue from 'vue'
import dayjs from 'dayjs'
export default {
  name: 'LangSelector',
  mounted() {
    const vm = new Vue()
    const code = localStorage.getItem('currentLanguage') || 'en'
    vm.$language.current = code
    dayjs.locale(code)
  },
  methods: {
    langChanged(key) {
      const vm = new Vue()
      vm.$language.current = key
      localStorage.setItem('currentLanguage', key)
      dayjs.locale(key)
    }
  }
}
</script>
<template>
  <b-nav-item-dropdown
    id="language-dropdown"
    right
    class="text-uppercase"
    :text="$language.current"
  >
    <b-dropdown-item-button
      v-for="(_, key) in $language.available"
      :key="key"
      @click="langChanged(key)"
    >
      {{ key.toUpperCase() }}
    </b-dropdown-item-button>
  </b-nav-item-dropdown>
</template>

<style>
#language-dropdown .dropdown-item {
  width: 100%;
}
</style>
