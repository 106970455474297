import BackendAccess from '@/services/BackendAccess'

import dayjs from 'dayjs'

function convertToDate(value) {
  const date = dayjs(value)

  if (date.isValid()) {
    return date
  } else {
    // Assume non-parseable dates are some sort of empty value
    // (null or empty string)
    return null
  }
}

class FileBackendAccess {
  constructor() {
    this.access = new BackendAccess()
  }

  /**
   * Retrieve metadata for a file in dataset. The metadata is retrieved from
   * Metax and may be located in upload-rest-api or IDA.
   *
   * Returns an object with the fields:
   * {
   *   id: integer,
   *   identifier: string,
   *   fileName: string,
   *   filePath: string,
   *
   *   fileSize: integer,
   *
   *   created: Date,
   *   modified: Date,
   *   fileUploaded: Date,
   *   fileModified: Date,
   *
   *   checksum: {algorithm, checked, value}
   *   fileStorage: {id, identifier}
   *   fileCharacteristics: {file_format, format_version, ...}
   *   fileCharacteristicsExtension: arbitrary nested object
   * }
   */
  async getDatasetFile(datasetId, fileId) {
    const response = await this.access.service.get(
      `${this.access.basePath}datasets/${datasetId}/files/${fileId}`
    )
    const data = response.data
    const result = {
      id: data.id,
      identifier: data.identifier,

      fileName: data.file_name || null,
      filePath: data.file_path || null,

      fileSize: data.byte_size,

      created: convertToDate(data.date_created),
      modified: convertToDate(data.date_modified),

      fileUploaded: convertToDate(data.file_uploaded),
      fileModified: convertToDate(data.file_modified),
      fileStorage: data.file_storage || null,
      checksum: data.checksum || null,

      // These fields are reproduced as-is, snake_case and all.
      // This makes it easier to convert them to a natural form
      // (eg. "foo_and_bar" -> "Foo and bar").
      fileCharacteristics: data.file_characteristics,
      fileCharacteristicsExtension: data.file_characteristics_extension
    }
    return result
  }

  async getDatasetDirectory(datasetId, directoryId) {
    const path = `${this.access.basePath}/datasets/${datasetId}/files`
    return await this.access.service.get(path, { params: { directory_identifier: directoryId } })
  }
}

export default FileBackendAccess
