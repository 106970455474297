<script>
import DatasetStateService from '@/services/DatasetStateService'
import { States } from '@/services/DatasetStates'
export default {
  data() {
    return {
      datasetState: States.ALL_STATES.toString()
    }
  },
  computed: {
    options() {
      const states = []
      states.push({
        value: States.ALL_STATES.toString(),
        text: this.$gettext('All')
      })
      for (const state of States.ALL_STATES) {
        const text = this.getStateLabel(state)
        states.push({
          value: state,
          text: text
        })
      }
      return states
    }
  },
  methods: {
    getStateLabel(state) {
      return DatasetStateService.getStateLabel(state)
    },
    handleChange() {
      this.$emit('dataset-state-changed', this.datasetState)
    }
  }
}
</script>
<template>
  <b-form-group>
    <label id="select-dataset-state-label" v-translate for="select-dataset-state">
      Dataset state
    </label>
    <b-form-select
      id="select-dataset-state"
      v-model="datasetState"
      :options="options"
      name="select-dataset-state"
      @input="handleChange"
    />
  </b-form-group>
</template>
