<script>
import FileStorageBackendAccess from '@/services/FileStorageBackendAccess'
import TranslationService from '@/services/TranslationService'
import { States } from '@/services/DatasetStates'

export default {
  name: 'FileStorageDeleteModal',
  props: {
    project: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      modalShow: false,

      loaded: false,
      canDelete: false,
      path: '',
      type: '',

      datasets: []
    }
  },
  computed: {
    modalTitle() {
      if (this.type === 'file') {
        return this.$pgettext('Modal title', 'Delete file')
      } else {
        return this.$pgettext('Modal title', 'Delete directory')
      }
    },
    modalOkText() {
      return this.$pgettext('Button action', 'Delete')
    },
    modalCancelText() {
      return this.$pgettext('Button text', 'Cancel')
    },
    belongsToDatasetsText() {
      if (this.datasets.length > 1) {
        if (this.type === 'file') {
          return this.$gettext('The file belongs to the following datasets')
        } else {
          return this.$gettext(
            'The directory belongs to or contains files belonging to the following datasets'
          )
        }
      } else {
        if (this.type === 'file') {
          return this.$gettext('The file belongs to the following dataset')
        } else {
          return this.$gettext(
            'The directory belongs to or contains files belonging to the following dataset'
          )
        }
      }
    },
    /**
     * Pending datasets that prevent file/directory deletion, if any
     */
    pendingDatasets() {
      return this.datasets.filter(
        (dataset) =>
          dataset.preservation_state < States.ACCEPTED_TO_DIGITAL_PRESERVATION ||
          dataset.preservation_state === States.REJECTED_IN_DIGITAL_PRESERVATION
      )
    }
  },
  methods: {
    openDialog: async function (path, type) {
      this.path = path
      this.type = type
      this.modalShow = true
      this.loaded = false
      this.canDelete = false

      // Check whether the file/directory can be deleted. If it contains
      // a pending dataset, it cannot be deleted.
      const fsAccess = new FileStorageBackendAccess()
      const datasetInfo = await fsAccess.getEntryDatasetInfo(this.project, this.path)

      this.canDelete = !datasetInfo.hasPendingDataset
      this.datasets = datasetInfo.datasets
      this.loaded = true
    },
    deleteResource: function () {
      this.$emit('delete-selected', this.path, this.type)
    },
    getDatasetName: function (name, languages) {
      return TranslationService.getPreferredValue(name, languages)
    }
  }
}
</script>

<template>
  <b-modal
    v-model="modalShow"
    :title="modalTitle"
    :cancel-title="modalCancelText"
    :ok-title="modalOkText"
    ok-variant="danger"
    :ok-disabled="!loaded || !canDelete"
    :return-focus="`#file-entry-${path.replaceAll('/', '-')}`"
    @ok="deleteResource"
    @show="$emit('show')"
    @hidden="$emit('hidden')"
  >
    <template v-if="loaded && canDelete">
      <template v-if="type === 'file'">
        <p class="break-word">
          <translate>Are you sure you want to delete file</translate>
          <b>{{ path }}</b
          >?
        </p>
      </template>
      <template v-else>
        <p class="break-word">
          <translate>Are you sure you want to delete directory</translate>
          <b>{{ path }}</b
          >?
        </p>
      </template>
    </template>
    <template v-else-if="loaded && !canDelete">
      <b-alert variant="danger" show>
        <h4 class="alert-heading">
          <translate v-if="type === 'file'"> This file cannot be deleted. </translate>
          <translate v-else> This directory cannot be deleted. </translate>
        </h4>
        <p>{{ belongsToDatasetsText }}:</p>
        <ul>
          <li v-for="dataset in pendingDatasets" :key="dataset.identifier">
            <router-link :to="{ name: 'DatasetPage', params: { id: dataset.identifier } }">
              {{ getDatasetName(dataset.title, dataset.languages) }}
            </router-link>
          </li>
        </ul>
      </b-alert>
    </template>
    <template v-else>
      <div class="text-center">
        <b-spinner class="loading-spinner" />
      </div>
    </template>
  </b-modal>
</template>

<style scoped>
.break-word {
  word-break: break-word;
}

.loading-spinner {
  width: 5em;
  height: 5em;
}
</style>
