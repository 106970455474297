<script>
import DatasetBackendAccess from '@/services/DatasetBackendAccess'
import { States } from '@/services/DatasetStates'
import DatasetListAndFilter from '@/views/DatasetsPage/DatasetListAndFilter.vue'
import DatasetSearchByWord from '@/views/DatasetsPage/DatasetSearchByWord.vue'
import { titleMixin } from '@/mixins/titleMixin'
export default {
  name: 'DatasetsPage',
  components: {
    DatasetListAndFilter,
    DatasetSearchByWord
  },
  mixins: [titleMixin],
  data() {
    return {
      rows: 0,
      currentPage: 1,
      isBusy: true,
      datasets: [],
      searchCounter: 0,
      searchWord: '',
      perPage: 5,
      datasetState: States.ALL_STATES.toString()
    }
  },
  computed: {
    titleItems() {
      return [this.$gettext('Datasets')]
    }
  },
  watch: {
    perPage() {
      this.currentPage = 1
      this.getDatasets()
    },
    datasetState() {
      this.currentPage = 1
      this.getDatasets()
    },
    searchWord() {
      this.currentPage = 1
      this.getDatasets()
    }
  },
  beforeMount() {
    this.isBusy = false
    this.getDatasets()
    this.$root.$on('do-dataset-search', (data) => {
      this.currentPage = 1
      this.getDatasets(data)
    })
  },
  methods: {
    getDatasets() {
      const sortField = 'preservation_state'
      const sortOrder = false
      this.isBusy = true
      this.searchCounter++
      const access = new DatasetBackendAccess()
      access.searchDatasets(
        this.perPage,
        (this.currentPage - 1) * this.perPage,
        this.searchWord,
        this.datasetState,
        sortField,
        sortOrder,
        this.callback,
        this.errorCallback
      )
    },
    callback(data) {
      this.searchCounter--
      if (this.searchCounter === 0) this.isBusy = false
      this.rows = data.total_count
      this.datasets = data.datasets
    },
    errorCallback() {
      this.searchCounter--
      if (this.searchCounter === 0) this.isBusy = false
      this.datasets = []
    },
    filterValuesChangedHandler({ perPage, datasetState } = {}) {
      this.perPage = perPage
      this.datasetState = datasetState
    }
  }
}
</script>

<template>
  <main>
    <dataset-search-by-word @dataset-search-word-changed="searchWord = $event" />
    <b-container>
      <dataset-list-and-filter
        :search-word="searchWord"
        :datasets="datasets"
        :is-busy="isBusy"
        :current-page.sync="currentPage"
        :per-page="perPage"
        :rows="rows"
        :do-on-pagination-change="getDatasets"
        @search-values-changed="filterValuesChangedHandler($event)"
      />
    </b-container>
  </main>
</template>
