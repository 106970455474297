<script>
import AgreementBackendAccess from '@/services/AgreementBackendAccess'
import DatasetBackendAccess from '@/services/DatasetBackendAccess'
import DatasetStateService from '@/services/DatasetStateService'

export default {
  props: {
    dataset: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      agreements: [],
      selectedAgreementIdentifier: this.dataset.agreementIdentifier,
      isFormOpen: false,
      isAgreementChangeInProgress: false
    }
  },
  computed: {
    buttonIsEnabled() {
      return DatasetStateService.canChooseAgreement(this.dataset.passtate)
    },
    isFormLoading() {
      return this.agreements.length === 0
    }
  },
  methods: {
    async handleAgreementChange(e) {
      /* e.preventDefault() prevents default form submit event, which would cause a page refresh. 
         For some reason refresh only happens in Cypress tests (different browser?), but this prevents it.
      */
      e?.preventDefault()
      this.isFormOpen = false

      const agreement = this.agreements.find(
        (item) => item.value === this.selectedAgreementIdentifier
      ).text
      const agreementIdentifier = this.selectedAgreementIdentifier

      const access = new DatasetBackendAccess()
      this.isAgreementChangeInProgress = true
      await access.changeAgreement(this.dataset.identifier, {
        identifier: agreementIdentifier
      })
      this.$emit('local-dataset-change', {
        agreementIdentifier,
        agreement
      })
      this.isAgreementChangeInProgress = false
      this.$emit('update-dataset')
    },
    async openForm() {
      this.isFormOpen = true

      if (this.agreements.length === 0) {
        const agreementAccess = new AgreementBackendAccess()
        const data = await agreementAccess.searchAgreements(100, 0)

        this.agreements = data.agreements.map((agreement) => ({
          value: agreement.identifier,
          text: agreement.name
        }))
      }
    },
    closeForm() {
      this.isFormOpen = false
    }
  }
}
</script>

<template>
  <dd>
    <b-form v-if="isFormOpen && !isFormLoading" @submit="handleAgreementChange">
      <b-form-select
        v-model="selectedAgreementIdentifier"
        :options="agreements"
        data-test="select-agreement"
      />
      <div class="button-container">
        <b-button variant="outline-primary" @click="closeForm">
          {{ $pgettext('verb', 'Cancel') }}
        </b-button>
        <b-button variant="primary" type="submit" data-test="finalize-change-agreement-button">
          {{ $pgettext('verb', 'Change') }}
        </b-button>
      </div>
    </b-form>
    <div v-else-if="!isFormOpen && !isAgreementChangeInProgress" class="d-flex flex-column">
      <span v-if="dataset.agreement">{{ dataset.agreement }}</span>
      <span v-else class="font-italic"> {{ $gettext('No agreement selected') }}</span>
      <b-button
        variant="outline-primary"
        class="mt-1"
        data-test="change-agreement-button"
        :disabled="!buttonIsEnabled"
        @click="openForm"
      >
        {{ dataset.agreement ? $pgettext('verb', 'Change') : $pgettext('verb', 'Select') }}
      </b-button>
    </div>
    <b-spinner v-else />
  </dd>
</template>

<style scoped>
.button-container {
  display: flex;
  margin-top: 0.5rem;
  gap: 0.5rem;
}
</style>
