<script>
import AuthenticationService from '@/services/AuthenticationService'

export default {
  computed: {
    loginAddress() {
      return new AuthenticationService().loginUrl()
    }
  }
}
</script>

<template>
  <b-button
    class="login-button"
    variant="primary"
    :href="loginAddress"
  >
    <translate>
      Login
    </translate>
  </b-button>
</template>