<script>
import DatasetBackendAccess from '@/services/DatasetBackendAccess'
import DatasetStateService from '@/services/DatasetStateService'
export default {
  name: 'RejectDataset',
  props: {
    dataset: {
      type: Object,
      required: true
    },
    datasetStateChanging: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      reason: '',
      loading: false,
      alertInfo: undefined,
      alertVariant: undefined
    }
  },
  computed: {
    reasonPlaceHolder() {
      return this.$gettext('Provide a reason why this dataset will be rejected.')
    }
  },
  methods: {
    rejectable() {
      return DatasetStateService.canBeAcceptedOrRejected(this.dataset.passtate)
    },
    showModal() {
      this.$modal.show('reject-dataset')
    },
    closeModal() {
      this.$modal.hide('reject-dataset')
    },
    reject() {
      this.loading = true
      this.$emit('start-dataset-state-change')
      const access = new DatasetBackendAccess()
      access.rejectDataset(
        this.dataset.identifier,
        this.reason,
        this.rejectCallback,
        this.rejectErrorCallback
      )
    },
    rejectCallback() {
      this.$emit('reject-dataset')
      this.loading = false
      this.closeModal()
    },
    rejectErrorCallback(error) {
      this.loading = false
      this.showModal()
      if (error.response.data.error) {
        this.alertInfo = error.response.data.error
      } else {
        this.alertInfo = error
      }
      this.$emit('finish-dataset-state-change')
    }
  }
}
</script>

<template>
  <span>
    <b-button
      id="button-reject"
      variant="outline-primary"
      :disabled="!dataset.rights.approve || !rejectable() || datasetStateChanging"
      @click="showModal()"
    >
      <translate>Reject</translate>
      <b-icon icon="x-circle" class="ml-1" />
    </b-button>
    <modal name="reject-dataset" height="auto">
      <div class="reject-modal">
        <b-alert v-if="alertInfo" show variant="danger">
          {{ alertInfo }}
        </b-alert>
        <h2 v-translate>Reject dataset</h2>
        <p v-translate>
          Dataset will be rejected from the preservation service. An email message about the
          rejection will be sent to the contact and the dataset's status will be marked as rejected.
        </p>
        <label id="input-reject-reason-label" v-translate for="input-reject-reason">
          Reason (optional):
        </label>
        <b-form-textarea
          id="input-reject-reason"
          v-model="reason"
          name="input-reject-reason"
          :placeholder="reasonPlaceHolder"
        />
        <b-button
          id="button-close"
          v-translate
          name="button-close"
          size="md"
          variant="outline-primary"
          @click="closeModal()"
        >
          Cancel
        </b-button>
        <b-button
          id="button-reject-modal"
          name="button-reject-modal"
          size="md"
          variant="primary"
          :disabled="loading"
          @click="reject"
        >
          <div v-if="!loading" v-translate>Reject</div>
          <div v-else>
            <b-spinner class="mr-1" small />
          </div>
        </b-button>
      </div>
    </modal>
  </span>
</template>

<style scoped>
#button-reject-modal {
  float: right;
}
#input-reject-reason {
  margin-bottom: 10px;
}
#input-reject-reason-label {
  font-size: 1.75rem;
  margin-top: 0.6rem;
  color: #337ab7;
}
.reject-modal {
  margin: 5%;
}
</style>
