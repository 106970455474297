<script>
export default {
  props: {
    datasetsPerPageDefault: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 50, text: '50' },
        { value: 100, text: '100' }
      ],
      datasetsPerPage: this.datasetsPerPageDefault
    }
  },
  methods: {
    handleChange() {
      this.$emit('datasets-per-page-changed', this.datasetsPerPage)
    }
  }
}
</script>

<template>
  <b-form-group>
    <label id="input-datasets-per-page-label" for="input-datasets-per-page">
      <translate>Datasets per page</translate>
    </label>
    <b-form-select
      id="input-datasets-per-page"
      v-model="datasetsPerPage"
      :options="options"
      @input="handleChange"
    />
  </b-form-group>
</template>
