<script>
import bigInt from 'big-integer'
import dayjs from 'dayjs'
import _ from 'lodash'
import RelatedService from './RelatedService'
import ContactInformation from './ContactInformation'
import AgreementBackendAccess from '@/services/AgreementBackendAccess'
import ModalDialog from '@/common/ModalDialog'
import { titleMixin } from '@/mixins/titleMixin'

export default {
  name: 'CreateAgreementPage',
  components: {
    RelatedService,
    ContactInformation,
    ModalDialog
  },
  mixins: [titleMixin],
  data() {
    return {
      originalAgreement: null,
      isBusy: false,
      agreementId: '',
      editMode: false,
      submitted: false,
      form: {
        title: '',
        description: '',
        identifier: '',
        quota: '',
        created: '',
        organization: {
          name: '',
          organization_identifier: ''
        },
        validity: {
          start_date: '',
          end_date: ''
        },
        related_service: [],
        contact: []
      },
      relatedService: {
        name: '',
        identifier: ''
      },
      contact: {
        name: '',
        email: '',
        phone: ''
      },
      selectedQuotaUnit: 4,
      isSavingAgreement: false,
      showModal: false,
      modalTitle: '',
      modalMessage: '',
      modalVariant: ''
    }
  },
  computed: {
    agreementChanged() {
      if (this.titleChanged) {
        return true
      }
      if (this.descriptionChanged) {
        return true
      }
      if (this.createdChanged) {
        return true
      }
      if (this.quotaChanged) {
        return true
      }
      if (this.organizationChanged) {
        return true
      }
      if (this.validityChanged) {
        return true
      }
      if (this.relatedServiceChanged) {
        return true
      }
      if (this.contactChanged) {
        return true
      }
      return false
    },
    quotaUnitOptions() {
      return [
        { value: 8, text: 'YiB' },
        { value: 7, text: 'ZiB' },
        { value: 6, text: 'EiB' },
        { value: 5, text: 'PiB' },
        { value: 4, text: 'TiB' },
        { value: 3, text: 'GiB' },
        { value: 2, text: 'MiB' },
        { value: 1, text: 'KiB' },
        { value: 0, text: 'Bytes' }
      ]
    },
    createdDateInvalidFeedback() {
      return this.getDateErrorMessage(this.form.created)
    },
    validityStartDateInvalidFeedback() {
      return this.getDateErrorMessage(this.form.validity.start_date)
    },
    validityEndDateInvalidFeedback() {
      return this.getDateErrorMessage(this.form.validity.end_date)
    },
    isCreatedDateValid() {
      return this.validateDate(this.form.created)
    },
    isStartDateValid() {
      return this.validateDate(this.form.validity.start_date)
    },
    isEndDateValid() {
      return this.validateDate(this.form.validity.end_date)
    },
    isQuotaValid() {
      return this.form.quota > 0 && this.form.quota < 1000000000000000
    },
    isTitleValid() {
      return this.form.title.length > 0
    },
    isIdentifierValid() {
      return /urn:uuid:[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/.test(
        this.form.identifier
      )
    },
    identifierInputState() {
      if (this.form.identifier === '') {
        return null
      }

      return this.isIdentifierValid
    },
    isOrganizationValid() {
      return this.form.organization.name.length > 0
    },
    isRelatedServicesValid() {
      return this.form.related_service.length > 0
    },
    isContactsValid() {
      return this.form.contact.length > 0
    },
    quotaInvalidFeedback() {
      if (this.form.quota) {
        if (this.form.quota === '0') {
          return this.$gettext('Valid values 1...')
        } else {
          return this.$gettext('Value too big')
        }
      } else {
        return ''
      }
    },
    createdPlaceholder() {
      return this.$gettext('YYYY-MM-DD')
    },
    validityStartDatePlaceholder() {
      return this.$gettext('Starts (YYYY-MM-DD) *')
    },
    validityEndDatePlaceholder() {
      return this.$gettext('Ends (YYYY-MM-DD)')
    },
    organizationNamePlaceholder() {
      return this.$gettext('Name *')
    },
    namePlaceholder() {
      return this.$gettext('Name')
    },
    identifierPlaceholder() {
      return this.$gettext('Identifier')
    },
    emailPlaceholder() {
      return this.$gettext('Email *')
    },
    phonePlaceholder() {
      return this.$gettext('Phone')
    },
    isInputContactEmailValid() {
      // Deliberately simple email validation regex to prevent common errors.
      // More complicated regexes have a bunch of false positives, as
      // comprehensive client-side email address validation is more or less
      // impossible.
      // For instance, email addresses exist that are technically against the
      // RFC standard but are used nonetheless (eg. addresses containing
      // international symbols).
      const emailRegex = /[^@]+@[^@]+\.[^@]+/
      return emailRegex.test(this.contact.email)
    },
    inputRelatedServiceFieldEmpty() {
      return !this.relatedService.name || !this.relatedService.identifier
    },
    titleChanged() {
      return this.form.title !== this.originalAgreement.name
    },
    descriptionChanged() {
      return this.form.description !== this.originalAgreement.description
    },
    createdChanged() {
      return (
        this.form.created !== dayjs(String(this.originalAgreement.created)).format('YYYY-MM-DD')
      )
    },
    quotaChanged() {
      return (
        bigInt(1024).pow(this.selectedQuotaUnit).multiply(this.form.quota).toString() !==
        this.originalAgreement.quota.toString()
      )
    },
    organizationChanged() {
      return !_.isEqual(
        _.omitBy(this.form.organization, _.isEmpty),
        _.omitBy(this.originalAgreement.organization, _.isEmpty)
      )
    },
    validityChanged() {
      return !_.isEqual(
        _.omitBy(this.form.validity, _.isEmpty),
        _.omitBy(this.originalAgreement.validity, _.isEmpty)
      )
    },
    relatedServiceChanged() {
      return !_.isEqual(this.form.related_service, this.originalAgreement.related_service)
    },
    contactChanged() {
      const formContacts = []
      for (const contact of this.form.contact) {
        formContacts.push(_.omitBy(contact, _.isEmpty))
      }
      const originalContacts = []
      for (const contact of this.originalAgreement.contact) {
        originalContacts.push(_.omitBy(contact, _.isEmpty))
      }
      return !_.isEqual(formContacts, originalContacts)
    },
    titleItems() {
      if (this.editMode) {
        return [this.$gettext('Modify agreement')]
      } else {
        return [this.$gettext('Create new agreement')]
      }
    }
  },
  beforeMount() {
    if (this.editMode) {
      this.getAgreement()
    }
  },
  created() {
    if (this.$route.params.id) {
      this.editMode = true
      this.agreementId = this.$route.params.id
      this.updateTitle()
    }
  },
  methods: {
    async getAgreement() {
      const access = new AgreementBackendAccess()
      this.isBusy = true
      try {
        const data = await access.getAgreement(this.agreementId)
        this.populateForm(data)
      } catch (e) {
        this.isBusy = false
        throw e
      }
    },
    setQuota(value) {
      let units = 0
      let tmp = value
      while (tmp > 999) {
        tmp /= 1024
        units++
      }
      if (tmp % 1 !== 0) {
        this.selectedQuotaUnit = 0
        this.form.quota = value
      } else {
        this.selectedQuotaUnit = units
        this.form.quota = tmp
      }
    },
    populateForm(data) {
      this.originalAgreement = data
      this.isBusy = false
      this.editMode = true
      this.form.title = data.name
      this.setQuota(data.quota)
      this.form.identifier = data.identifier
      this.form.organization.name = data.organization.name
      this.form.organization.organization_identifier = data.organization.organization_identifier
      this.form.related_service = []
      for (const service of data.related_service) {
        const tmp = {}
        for (const key in service) {
          tmp[key] = service[key]
        }
        this.form.related_service.push(tmp)
      }
      this.form.contact = []
      for (const contact of data.contact) {
        const tmp = {}
        for (const key in contact) {
          tmp[key] = contact[key]
        }
        this.form.contact.push(tmp)
      }
      this.form.description = data.description
      this.form.created = dayjs(String(data.created)).format('YYYY-MM-DD')
      if (data.validity) {
        this.form.validity.start_date = data.validity.start_date
        if (data.validity.end_date) {
          this.form.validity.end_date = data.validity.end_date
        }
      }
    },
    getDateErrorMessage(dateString) {
      if (dateString && dateString.length > 0) {
        return this.$gettext('Date should be of form YYYY-MM-DD')
      } else {
        return ''
      }
    },
    validateDate(dateString) {
      if (dateString.length > 0) {
        const parts = dateString.split('-')
        if (
          parts.length === 3 &&
          parts[0].length === 4 &&
          parts[1].length === 2 &&
          parts[2].length === 2
        ) {
          const years = parseInt(parts[0])
          const months = parseInt(parts[1])
          const days = parseInt(parts[2])
          if (years > 0 && years < 9999) {
            if (months > 0 && months < 13) {
              if (days > 0 && days < 32) {
                return true
              }
            }
          }
        }
      } else {
        return true
      }
      return false
    },
    onSubmit(event) {
      this.submitted = true
      this.showModal = false
      event.preventDefault()
      if (
        this.isTitleValid &&
        this.isIdentifierValid &&
        this.isQuotaValid &&
        this.isOrganizationValid &&
        this.validateDate(this.form.created) &&
        this.validateDate(this.form.validity.start_date) &&
        this.validateDate(this.form.validity.end_date) &&
        this.isRelatedServicesValid &&
        this.isContactsValid
      ) {
        /*
         * as issues with BigInt serialization in flask, quota send as
         * string
         */
        const quotaAsString = bigInt(1024)
          .pow(this.selectedQuotaUnit)
          .multiply(this.form.quota)
          .toString()
        const modified = new Date().toISOString()
        const created = this.form.created + 'T00:00:00.000Z'
        const validity = {}
        validity.start_date = this.form.validity.start_date
        if (this.form.validity.end_date) {
          validity.end_date = this.form.validity.end_date
        }
        const agreement = {
          contract_json: {
            title: this.form.title,
            description: this.form.description,
            identifier: this.form.identifier,
            quota: quotaAsString,
            created: created,
            modified: modified,
            validity: validity,
            contact: [],
            organization: this.form.organization,
            related_service: []
          }
        }
        for (const i in this.form.contact) {
          const tmp = {}
          tmp.email = this.form.contact[i].email
          tmp.phone = this.form.contact[i].phone

          if (this.form.contact[i].name) {
            tmp.name = this.form.contact[i].name
          }

          agreement.contract_json.contact.push(tmp)
        }
        for (const i in this.form.related_service) {
          agreement.contract_json.related_service.push(this.form.related_service[i])
        }
        this.isSavingAgreement = true
        const access = new AgreementBackendAccess()
        if (this.editMode) {
          access.modifyAgreement(
            this.agreementId,
            agreement,
            this.agreementCallback,
            this.agreementErrorCallback
          )
        } else {
          access.createAgreement(agreement, this.agreementCallback, this.agreementErrorCallback)
        }
      }
    },
    agreementCallback(status) {
      this.submitted = false
      this.isSavingAgreement = false
      this.showModal = true
      this.modalVariant = 'primary'
      this.modalTitle = this.$gettext('Information')
      if (this.editMode) {
        this.modalMessage = this.$gettext('Modifications saved')
        this.getAgreement()
      } else {
        this.modalMessage = this.$gettext('New agreement created')
      }
    },
    agreementErrorCallback(error) {
      this.submitted = false
      this.isSavingAgreement = false
      this.showModal = true
      this.modalVariant = 'danger'
      this.modalTitle = this.$gettext('Application error')
      if (error.response.data.error) {
        this.modalMessage = error.response.data.error
      } else {
        this.modalMessage = this.$gettext('System error')
      }
    },
    addRelatedService() {
      this.form.related_service.push({
        name: this.relatedService.name,
        identifier: this.relatedService.identifier
      })
      this.relatedService.name = ''
      this.relatedService.identifier = ''
    },
    addContact() {
      this.form.contact.push({
        name: this.contact.name,
        email: this.contact.email,
        phone: this.contact.phone
      })
      this.contact.name = ''
      this.contact.email = ''
      this.contact.phone = ''
    },
    deleteService(index) {
      this.form.related_service.splice(index, 1)
    },
    deleteContact(index) {
      this.form.contact.splice(index, 1)
    },
    resetForm() {
      this.submitted = false
      this.form.title = ''
      this.form.description = ''
      this.form.identifier = ''
      this.selectedQuotaUnit = 4
      this.form.quota = ''
      this.form.created = ''
      this.form.organization.name = ''
      this.form.organization.organization_identifier = ''
      this.form.validity.start_date = ''
      this.form.validity.end_date = ''
      this.form.related_service = []
      this.form.contact = []
      this.relatedService.name = ''
      this.relatedService.identifier = ''
      this.contact.name = ''
      this.contact.email = ''
      this.contact.phone = ''
    }
  }
}
</script>

<template>
  <page-container>
    <!-- TODO: Add 'Back' button; user can only leave the page by using the
         browser's back functionality -->
    <modal-dialog
      v-if="showModal"
      :dialog-variant="modalVariant"
      :dialog-title="modalTitle"
      :dialog-message="modalMessage"
    />
    <div v-if="!isBusy">
      <h1 v-if="!editMode" v-translate class="page-header">Create new agreement</h1>
      <h1 v-if="editMode" v-translate class="page-header">Modify agreement</h1>
      <p v-if="!editMode" class="mb-4">
        {{
          $gettext(
            'Please, fill in the following fields and press Save button to create a new agreement. Fields marked with asterisk are mandatory.'
          )
        }}
      </p>
      <p v-if="editMode" class="mb-4">
        {{
          $gettext(
            'Modify any of the following fields and press Save button to save changes. Fields marked with asterisk are mandatory.'
          )
        }}
      </p>
      <b-form id="agreementForm" name="agreementForm">
        <b-row>
          <b-col>
            <b-form-group id="input-group-agreement-title">
              <label
                v-translate
                :class="{ changed: editMode && titleChanged }"
                for="input-agreement-title"
              >
                Agreement title *
              </label>
              <b-form-input
                id="input-agreement-title"
                v-model="form.title"
                type="text"
                name="input-agreement-title"
              />
              <span
                v-if="submitted && form.title.length == 0"
                v-translate
                class="text-danger small"
              >
                Field cannot be empty
              </span>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group id="input-group-description">
              <label
                v-translate
                :class="{ changed: editMode && descriptionChanged }"
                for="input-agreement-description"
              >
                Agreement description
              </label>
              <b-form-textarea
                id="input-agreement-description"
                v-model="form.description"
                rows="4"
                cols="39"
                name="input-agreement-description"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <b-form-group id="input-group-agreement-identifier">
              <label v-translate for="input-agreement-identifier"> Agreement identifier * </label>
              <b-form-input
                id="input-agreement-identifier"
                v-model="form.identifier"
                :disabled="editMode"
                :state="identifierInputState"
                type="text"
                name="input-agreement-identifier"
              />
              <span
                v-if="submitted && form.identifier.length == 0"
                v-translate
                class="text-danger small"
              >
                Field cannot be empty
              </span>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group>
          <legend v-translate :class="{ changed: editMode && quotaChanged }">
            Agreement quota *
          </legend>
          <b-row>
            <b-col cols="3">
              <b-form-group
                id="input-group-quota"
                :state="isQuotaValid"
                :invalid-feedback="quotaInvalidFeedback"
              >
                <label v-translate class="sr-only" for="input-agreement-quota">
                  Agreement quota *
                </label>
                <b-form-input
                  id="input-agreement-quota"
                  v-model="form.quota"
                  type="number"
                  name="quota"
                  min="1"
                  placeholder="1..."
                />
                <span v-if="submitted && !form.quota" v-translate class="text-danger small">
                  Field cannot be empty
                </span>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group id="input-group-quota-unit-selection">
                <label v-translate for="input-agreement-quota-unit-selection" class="sr-only">
                  Agreement quota units
                </label>
                <b-form-select
                  id="input-agreement-quota-unit-selection"
                  v-model="selectedQuotaUnit"
                  :options="quotaUnitOptions"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>
        <label
          v-translate
          :class="{ changed: editMode && createdChanged }"
          for="input-agreement-created"
        >
          Agreement created *
        </label>
        <b-row>
          <b-col cols="6">
            <b-form-group
              id="input-group-created"
              :state="isCreatedDateValid"
              :invalid-feedback="createdDateInvalidFeedback"
              label-for="input-agreement-created"
            >
              <label v-translate class="sr-only" for="input-agreement-created">
                Agreement created date
              </label>
              <b-form-input
                id="input-agreement-created"
                v-model="form.created"
                type="text"
                name="input-agreement-created"
                size="40"
                :placeholder="createdPlaceholder"
              />
              <span
                v-if="submitted && form.created.length == 0"
                v-translate
                class="text-danger small"
              >
                Field cannot be empty
              </span>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group>
          <legend v-translate :class="{ changed: editMode && organizationChanged }">
            Agreement organization *
          </legend>
          <b-row>
            <b-col>
              <b-form-group id="input-group-agreement-organization-name">
                <label v-translate class="sr-only" for="input-agreement-organization-name">
                  Name of the organization
                </label>
                <b-form-input
                  id="input-agreement-organization-name"
                  v-model="form.organization.name"
                  type="text"
                  name="input-agreement-organization-name"
                  :placeholder="organizationNamePlaceholder"
                />
                <span
                  v-if="submitted && form.organization.name.length == 0"
                  v-translate
                  class="text-danger small"
                >
                  Field cannot be empty
                </span>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="input-group-agreement-organization-identifier">
                <label v-translate class="sr-only" for="input-agreement-organization-identifier">
                  Identifier of the organization
                </label>
                <b-form-input
                  id="input-agreement-organization-identifier"
                  v-model="form.organization.organization_identifier"
                  type="text"
                  name="input-agreement-organization-identifier"
                  :placeholder="identifierPlaceholder"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group>
          <legend v-translate :class="{ changed: editMode && validityChanged }">
            Agreement validity *
          </legend>
          <b-row>
            <b-col>
              <b-form-group
                id="input-group-agreement-validity-start-date"
                :state="isStartDateValid"
                :invalid-feedback="validityStartDateInvalidFeedback"
              >
                <label v-translate class="sr-only" for="input-agreement-validity-start-date">
                  Agreement validity start date
                </label>
                <b-form-input
                  id="input-agreement-validity-start-date"
                  v-model="form.validity.start_date"
                  type="text"
                  name="input-agreement-validity-start-date"
                  :placeholder="validityStartDatePlaceholder"
                />
                <span
                  v-if="submitted && form.validity.start_date.length == 0"
                  v-translate
                  class="text-danger small"
                >
                  Field cannot be empty
                </span>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-agreement-validity-end-date"
                :state="isEndDateValid"
                :invalid-feedback="validityEndDateInvalidFeedback"
              >
                <label v-translate class="sr-only" for="input-agreement-validity-end-date">
                  Agreement validity end date
                </label>
                <b-form-input
                  id="input-agreement-validity-end-date"
                  v-model="form.validity.end_date"
                  type="text"
                  name="input-agreement-validity-end-date"
                  :placeholder="validityEndDatePlaceholder"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group>
          <legend v-translate :class="{ changed: editMode && relatedServiceChanged }">
            Agreement related services *
          </legend>
          <label v-translate class="sr-only" for="agreement-related-services-list">
            Related services of the agreement
          </label>
          <ul v-if="form.related_service.length > 0" id="agreement-related-services-list">
            <li v-for="(service, index) in form.related_service" :key="index" class="mb-1">
              <RelatedService
                :service-name="service.name"
                :service-identifier="service.identifier"
              />
              <label v-translate class="sr-only" for="button-delete-related-service">
                Delete related service
              </label>
              <b-button
                id="button-delete-related-service"
                v-translate
                size="sm"
                variant="outline-primary"
                name="button-delete-related-service"
                @click="deleteService(index)"
              >
                Delete
              </b-button>
            </li>
          </ul>
          <b-row>
            <b-col cols="6">
              <b-form-group id="input-group-agreement-related-service-name">
                <label v-translate class="sr-only" for="input-agreement-related-service-name">
                  Name of the related service for the agreement
                </label>
                <b-form-input
                  id="input-agreement-related-service-name"
                  v-model="relatedService.name"
                  name="input-agreement-related-service-name"
                  :placeholder="namePlaceholder"
                />
                <span
                  v-if="submitted && form.related_service.length == 0"
                  v-translate
                  class="text-danger small"
                >
                  At least one related service must be added
                </span>
              </b-form-group>
            </b-col>
            <b-col cols="5">
              <b-form-group id="input-group-agreement-related-service-identifier">
                <label v-translate class="sr-only" for="input-agreement-related-service-identifier">
                  Identifier of the related service for the agreement
                </label>
                <b-form-input
                  id="input-agreement-related-service-identifier"
                  v-model="relatedService.identifier"
                  type="text"
                  name="input-agreement-related-service-identifier"
                  :placeholder="identifierPlaceholder"
                />
              </b-form-group>
            </b-col>
            <b-col cols="1">
              <label v-translate class="sr-only" for="button-add-related-service">
                Add related service
              </label>
              <b-button
                id="button-add-related-service"
                v-translate
                :disabled="inputRelatedServiceFieldEmpty"
                variant="outline-primary"
                name="button-add-related-service"
                @click="addRelatedService()"
              >
                Add
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group>
          <legend v-translate :class="{ changed: editMode && contactChanged }">
            Agreement contact information *
          </legend>
          <label v-translate class="sr-only" for="agreement-contact-list">
            Contacts of the agreement
          </label>
          <ul v-if="form.contact.length > 0" id="agreement-contact-list">
            <li v-for="(con, index) in form.contact" :key="index" class="mb-1">
              <ContactInformation
                :contact-name="con.name"
                :contact-email="con.email"
                :contact-phone="con.phone"
              />
              <label v-translate class="sr-only" for="button-delete-contact">
                Delete contact
              </label>
              <b-button
                id="button-delete-contact"
                v-translate
                size="sm"
                variant="outline-primary"
                name="button-delete-contact"
                @click="deleteContact(index)"
              >
                Delete
              </b-button>
            </li>
          </ul>
          <b-row>
            <b-col>
              <b-form-group id="input-group-agreement-contact-name">
                <label v-translate class="sr-only" for="input-agreement-contact-name">
                  Name of the contact of the agreement
                </label>
                <b-form-input
                  id="input-agreement-contact-name"
                  v-model="contact.name"
                  type="text"
                  name="input-agreement-contact-name"
                  :placeholder="namePlaceholder"
                />
                <span
                  v-if="submitted && form.contact.length == 0"
                  v-translate
                  class="text-danger small"
                >
                  At least one contact must be added
                </span>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="input-group-agreement-contact-email">
                <label v-translate class="sr-only" for="input-agreement-contact-email">
                  Email address of the contact of the agreement
                </label>
                <b-form-input
                  id="input-agreement-contact-email"
                  v-model="contact.email"
                  type="email"
                  name="input-agreement-contact-email"
                  :placeholder="emailPlaceholder"
                />
                <span
                  v-if="contact.email && !isInputContactEmailValid"
                  v-translate
                  class="text-danger small"
                >
                  Incorrect email address
                </span>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="input-group-agreement-contact-name">
                <label v-translate class="sr-only" for="input-agreement-contact-phone">
                  Phone number of the contact of the agreement
                </label>
                <b-form-input
                  id="input-agreement-contact-phone"
                  v-model="contact.phone"
                  type="tel"
                  class="form-control"
                  name="input-agreement-contact-phone"
                  :placeholder="phonePlaceholder"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <label v-translate class="sr-only" for="button-add-contact"> Add contact </label>
              <b-button
                id="button-add-contact"
                v-translate
                :disabled="!isInputContactEmailValid"
                variant="outline-primary"
                name="button-add-contact"
                @click="addContact()"
              >
                Add
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
        <b-row class="mb-3">
          <b-col>
            <label v-translate class="sr-only" for="button-save-agreement"> Save agreement </label>
            <b-button
              id="button-save-agreement"
              class="mr-3"
              variant="outline-primary"
              :disabled="isSavingAgreement || (editMode && !agreementChanged)"
              name="button-save-agreement"
              @click="onSubmit"
            >
              <span v-if="isSavingAgreement">
                <b-spinner small />
                <span v-translate class="ml-1"> Saving... </span>
              </span>
              <span v-if="!isSavingAgreement" v-translate> Save </span>
            </b-button>
            <label v-translate class="sr-only" for="button-reset-agreement"> Reset form </label>
            <b-button
              v-if="!editMode"
              id="button-reset-agreement"
              v-translate
              variant="outline-danger"
              :disabled="isSavingAgreement"
              name="button-reset-agreement"
              @click="resetForm"
            >
              Reset form
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </page-container>
</template>

<style scoped>
.changed {
  font-weight: bold;
  font-style: italic;
}
</style>
