import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import GetTextPlugin from 'vue-gettext'
import VModal from 'vue-js-modal'
import translations from '@/translations/translations.json'
import App from './App.vue'
import { DynamicScroller, DynamicScrollerItem, RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { createPinia, PiniaVuePlugin } from 'pinia'
import PageContainer from '@/common/PageContainer'

import router from './router'
import '@/assets/css/app.scss'

// Polyfill `Array.at`
import 'core-js/es/array/at'

import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/fi'
import relativeTime from 'dayjs/plugin/relativeTime'
import filesize from 'filesize'
import useAlertStore from '@/stores/alert'

import { mutations } from '@/common/store.js'
import BackendConfiguration from '@/services/BackendConfiguration.js'

dayjs.extend(relativeTime)

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.use(GetTextPlugin, {
  availableLanguages: {
    en: 'English',
    fi: 'Suomi'
  },
  defaultLanguage: 'en',
  translations: translations,
  silent: true
})

const AlertPlugin = {
  install(app, options) {
    app.prototype.$setAlert = function (alert) {
      const alertStore = useAlertStore()
      alertStore.setAlert(alert)
    }
  }
}

Vue.use(AlertPlugin)

Vue.use(VModal)
Vue.component('PageContainer', PageContainer)
Vue.component('DynamicScroller', DynamicScroller)
Vue.component('RecycleScroller', RecycleScroller)
Vue.component('DynamicScrollerItem', DynamicScrollerItem)
Vue.filter(
  'formatDate',
  function (value, hideHours = false, showWeekday = false, capitalizeFirst = false) {
    const weekDayFormat = showWeekday ? 'dddd' : ''
    const hoursFormat = hideHours ? '' : 'H:mm:ss'
    const format = `${weekDayFormat} MMM D, YYYY ${hoursFormat}`

    const isDayJsObjOrNumber = dayjs.isDayjs(value) || typeof value === 'number'
    const valueToUse = isDayJsObjOrNumber ? value : String(value)

    const formatted = dayjs(valueToUse).format(format).trim()
    const firstLetterCapitalized = formatted.charAt(0).toUpperCase()
    const formattedWithFirstLetterCapitalized = firstLetterCapitalized + formatted.slice(1)
    return capitalizeFirst ? formattedWithFirstLetterCapitalized : formatted
  }
)
Vue.filter('formatBytes', function (size, decimals = 3) {
  return filesize(size, { standard: 'iec', round: decimals })
})
Vue.filter('translate', function (value) {
  if (value) {
    return Vue.prototype.$gettext(value)
  }
})
Vue.filter('listComma', function (index, listLength, shouldHaveAnd) {
  const isLastItem = index === listLength - 1
  if (listLength <= 1 || isLastItem) {
    return ''
  }
  const isSecondLastItem = index === listLength - 2
  if (shouldHaveAnd && isSecondLastItem) {
    return ' ' + Vue.prototype.$gettext('and')
  }
  return ','
})

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

async function storeBackendConfiguration() {
  const access = new BackendConfiguration()

  try {
    const config = await access.getConfiguration(window.location.pathname, process.env.NODE_ENV)
    mutations.setBackendConfiguration(config)
  } catch {
    // Errors in resolving backend configuration will be shown in App.vue once the app has rendered
    mutations.setBackendConfiguration(null)
  }
}

async function initialization() {
  await storeBackendConfiguration()
}

initialization().then(() => {
  new Vue({
    router,
    pinia,
    render: (h) => h(App)
  }).$mount('#app')
})
