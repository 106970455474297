// Various path manipulation functions
export function ensureTrailingSlash(path) {
  if (path.endsWith('/')) {
    return path
  } else {
    return `${path}/`
  }
}

// Ensure the path starts with a slash.
// This is the format used by the web UI.
export function ensureLeadingSlash(path) {
  if (path.startsWith('/')) {
    return path
  } else {
    return `/${path}`
  }
}

// Ensure the path doesn't start with a slash.
// This is the format used by the API.
export function stripLeadingSlash(path) {
  if (path.startsWith('/')) {
    return path.slice(1)
  } else {
    return path
  }
}

export function stripTrailingSlash(path) {
  if (path.endsWith('/')) {
    return path.slice(0, -1)
  } else {
    return path
  }
}

function _removeConsecutiveSlashes(path) {
  return path.replace(/(\/(\/)+)/g, '/')
}

/**
 * Join multiple path components and return the complete path
 */
export function join(path, ...paths) {
  let newPath = stripTrailingSlash(path)
  newPath = [newPath, ...paths].join('/')

  // Eliminate consecutive slashes
  newPath = _removeConsecutiveSlashes(newPath)

  return newPath
}

/**
 * Split path into a (head, tail) where tail is the last pathname component,
 * and head is the starting part.
 */
export function split(path) {
  if (path === '') {
    return ['', '']
  }

  const startsWithSlash = path.startsWith('/')

  path = _removeConsecutiveSlashes(path)
  path = stripTrailingSlash(path)

  const components = path.split('/')
  const tail = components.pop()
  let head = null

  if (components.length > 1) {
    head = components.join('/')
  } else {
    head = startsWithSlash ? '/' : ''
  }

  return [head, tail]
}
