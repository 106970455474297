<script>
import TranslationService from '@/services/TranslationService'
export default {
  props: {
    dataset: {
      type: Object,
      required: true
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'title',
          label: this.$pgettext('table header', 'Title')
        },
        {
          key: 'description',
          label: this.$pgettext('table header', 'Description')
        },
        {
          key: 'time',
          label: this.$pgettext('table header', 'Time')
        },
        {
          key: 'eventOutcome',
          label: this.$pgettext('table header', 'Event outcome')
        },
        {
          key: 'outcomeDescription',
          label: this.$pgettext('table header', 'Outcome description')
        },
        {
          key: 'wasAssociatedWith',
          label: this.$pgettext('table header', 'Associated with')
        }
      ]
    },
    items() {
      if (!this.dataset.provenance) {
        return []
      }
      return this.dataset.provenance.map((provenanceEvent) => {
        const wasAssociatedWith = provenanceEvent.wasAssociatedWith.map((actor) =>
          this.getPreferredValue(actor.name)
        )
        const [rawStartTime, rawEndTime] = provenanceEvent.time.split(' - ')
        const startTime = this.$options.filters.formatDate(rawStartTime)
        const endTime = this.$options.filters.formatDate(rawEndTime)
        return {
          title: this.getPreferredValue(provenanceEvent.title),
          description: this.getPreferredValue(provenanceEvent.description),
          time: `${startTime} - ${endTime}`,
          eventOutcome: this.getPreferredValue(provenanceEvent.eventOutcome),
          outcomeDescription: this.getPreferredValue(provenanceEvent.outcomeDescription),
          wasAssociatedWith: wasAssociatedWith.join(', ')
        }
      })
    }
  },
  methods: {
    getPreferredValue(values) {
      return TranslationService.getPreferredValue(values, this.dataset.datasetLanguages)
    }
  }
}
</script>

<template>
  <b-table v-if="items.length > 0" hover outlined stacked="xl" :items="items" :fields="fields" />
  <p v-else>{{ $gettext('No history or event information available.') }}</p>
</template>
