import { States } from '@/services/DatasetStates'
import { translate } from 'vue-gettext'

const { gettext: $gettext } = translate

export default {
  getStateLabel(datasetState) {
    switch (datasetState) {
      case States.INITIALIZED:
        return $gettext('Initialized')
      case States.GENERATING_TECHNICAL_METADATA:
        return $gettext('Generating technical metadata')
      case States.TECHNICAL_METADATA_GENERATED:
        return $gettext('Technical metadata generated')
      case States.TECHNICAL_METADATA_GENERATION_FAILED:
        return $gettext('Technical metadata generation failed')
      case States.INVALID_METADATA:
        return $gettext('Invalid dataset')
      case States.METADATA_VALIDATION_FAILED:
        return $gettext('Dataset validation failed')
      case States.VALIDATED_METADATA_UPDATED:
        return $gettext('Validated metadata updated')
      case States.VALIDATING_METADATA:
        return $gettext('Validating dataset')
      case States.REJECTED_BY_USER:
        return $gettext('Rejected by user')
      case States.METADATA_CONFIRMED:
        return $gettext('Metadata confirmed')
      case States.ACCEPTED_TO_DIGITAL_PRESERVATION:
        return $gettext('Preparing dataset for preservation')
      case States.IN_PACKAGING_SERVICE:
        return $gettext('Processing dataset')
      case States.PACKAGING_FAILED:
        return $gettext('Packaging failed')
      case States.SIP_SENT_TO_INGESTION_IN_DPRES_SERVICE:
        return $gettext('SIP sent to ingestion in digital preservation service')
      case States.IN_DIGITAL_PRESERVATION:
        return $gettext('In digital preservation')
      case States.REJECTED_IN_DIGITAL_PRESERVATION_SERVICE:
        return $gettext('Rejected in digital preservation service')
      case States.IN_DISSEMINATION:
        return $gettext('In dissemination')
      default:
        return $gettext('Unknown')
    }
  },
  canBeIdentified: (state) => state === States.INITIALIZED,
  canBeProposed: (state) =>
    state < States.VALIDATING_METADATA && state > States.GENERATING_TECHNICAL_METADATA,
  canBeReset: (state) =>
    [
      States.TECHNICAL_METADATA_GENERATED,
      States.TECHNICAL_METADATA_GENERATION_FAILED,
      States.INVALID_METADATA,
      States.METADATA_VALIDATION_FAILED,
      States.VALIDATED_METADATA_UPDATED,
      States.REJECTED_BY_USER,
      States.METADATA_CONFIRMED,
      States.ACCEPTED_TO_DIGITAL_PRESERVATION,
      States.PACKAGING_FAILED,
      States.REJECTED_IN_DIGITAL_PRESERVATION_SERVICE
    ].includes(state),
  canBeAcceptedOrRejected: (state) => state === States.METADATA_CONFIRMED,
  canChooseAgreement: (state) => state < States.ACCEPTED_TO_DIGITAL_PRESERVATION,
  isDatasetTabEnabled: (state) => state >= States.INITIALIZED,
  isProposeTabEnabled: (state) => state >= States.TECHNICAL_METADATA_GENERATED,
  isAcceptTabEnabled: (state) => state >= States.METADATA_CONFIRMED,
  isGeneratingMetadata: (state) => state === States.GENERATING_TECHNICAL_METADATA,
  isValidatingMetadata: (state) => state === States.VALIDATING_METADATA,
  stateAfterIdentify: States.GENERATING_TECHNICAL_METADATA,
  stateAfterPropose: States.VALIDATING_METADATA,
  stateAfterAccept: States.ACCEPTED_TO_DIGITAL_PRESERVATION,
  shouldShowProposePageAsDone: (state) => state >= States.VALIDATING_METADATA,
  isInProgress: (state) =>
    [States.GENERATING_TECHNICAL_METADATA, States.VALIDATING_METADATA].includes(state),

  /* Dataset states that might change without user interaction. These usually
     mean the dataset is undergoing some background task. */
  isVolatile: (state) =>
    [
      States.GENERATING_TECHNICAL_METADATA,
      States.VALIDATING_METADATA,
      States.ACCEPTED_TO_DIGITAL_PRESERVATION,
      States.IN_PACKAGING_SERVICE,
      States.SIP_SENT_TO_INGESTION_IN_DPRES_SERVICE,
      States.IN_DISSEMINATION
    ].includes(state)
}
