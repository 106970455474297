<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    hasSmallHeader: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <div id="header-with-back-button">
    <b-button variant="link" class="back-link" @click="$router.back()">
      <b-icon icon="chevron-left" />
      <translate translate-context="Verb"> Back </translate>
    </b-button>
    <hr />
    <header>
      <h1 :class="{ smallHeader: hasSmallHeader }">
        {{ title }}
      </h1>
      <slot />
    </header>
    <hr />
  </div>
</template>

<style scoped>
h1 {
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: bold;
  margin: 2.5rem 0;
}
.smallHeader {
  font-size: 1.125rem;
  margin: 2rem 0;
}
#header-with-back-button {
  margin-bottom: 2rem;
}
.back-link {
  padding: 0 0;
  margin: 1rem 0 1rem;
}
</style>
