import { States } from '@/services/DatasetStates'

const STATE_TO_VARIANT = {
  [States.GENERATING_TECHNICAL_METADATA]: 'info',
  [States.TECHNICAL_METADATA_GENERATION_FAILED]: 'danger',
  [States.INVALID_METADATA]: 'danger',
  [States.METADATA_VALIDATION_FAILED]: 'danger',
  [States.VALIDATING_METADATA]: 'info',
  [States.REJECTED_BY_USER]: 'danger',
  [States.IN_PACKAGING_SERVICE]: 'info',
  [States.PACKAGING_FAILED]: 'danger',
  [States.SIP_SENT_TO_INGESTION_IN_DPRES_SERVICE]: 'info',
  [States.IN_DIGITAL_PRESERVATION]: 'success',
  [States.VALIDATING_METADATA]: 'info',
  [States.REJECTED_IN_DIGITAL_PRESERVATION_SERVICE]: 'danger',
  [States.IN_DISSEMINATION]: 'success'
}

const getVariant = (datasetState) => {
  return STATE_TO_VARIANT[datasetState] || 'warning'
}

export default {
  getClass(datasetState) {
    const variant = getVariant(datasetState)
    return `border-${variant} text-${variant} pas-status-label card`
  },
  getTextClass(datasetState) {
    const variant = getVariant(datasetState)
    return `text-${variant}`
  }
}
