import BackendAccess from '@/services/BackendAccess'

class ReportAccess {
  constructor() {
    this.access = new BackendAccess()
  }

  static get INGEST_REPORT() {
    return 1
  }

  static get FULL_REPORT() {
    return 2
  }

  async queryDryRun(datasetId) {
    const path = `${this.reportUrl(ReportAccess.INGEST_REPORT, datasetId)}&dry_run=true`
    const response = await this.access.service.get(path, { silenceAlertForStatuses: [404] })
    return response.data
  }

  async queryReport(reportType, datasetId) {
    const url = this.reportUrl(reportType, datasetId)
    const response = await this.access.service.get(url, { silenceAlertForStatuses: [404] })
    return response.data
  }

  reportUrl(reportType, datasetId) {
    let path = `${this.access.basePath}datasets/${datasetId}`
    if (reportType === ReportAccess.INGEST_REPORT) {
      path += '/ingest-report?type=html'
    } else if (reportType === ReportAccess.FULL_REPORT) {
      path += '/ingest-report?type=xml'
    }
    return path
  }
}

export default ReportAccess
