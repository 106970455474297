<script>
import ReportAccess from '@/services/ReportAccess'
export default {
  props: {
    dataset: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ingestReportAvailable: null
    }
  },
  computed: {
    ingestReports() {
      const access = new ReportAccess()
      const ingestReports = [
        { name: this.$gettext('Ingest report summary'), i: 1 },
        { name: this.$gettext('Ingest report'), i: 2 }
      ].map(({ name, i }) => ({
        name,
        url: access.reportUrl(i, this.dataset.identifier)
      }))
      return ingestReports
    }
  },
  async mounted() {
    const access = new ReportAccess()
    try {
      await access.queryDryRun(this.dataset.identifier)
      this.ingestReportAvailable = true
    } catch (e) {
      this.ingestReportAvailable = false
    }
  }
}
</script>

<template>
  <div id="dataset-reports">
    <h2>{{ $gettext('Reports') }}</h2>
    <div v-if="ingestReportAvailable">
      <p v-for="report in ingestReports" :key="report.name">
        <a :href="report.url" download><b-icon icon="file-text" class="mr-2" />{{ report.name }}</a>
      </p>
    </div>
    <b-spinner v-else-if="ingestReportAvailable === null" />
    <p v-else>
      <i>{{ $gettext('Reports are being generated. Try again later.') }}</i>
    </p>
  </div>
</template>
<style scoped>
h2 {
  font-size: 1rem;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
}
</style>
