import { render, staticRenderFns } from "./DatasetMetadataBlock.vue?vue&type=template&id=681e995c&scoped=true"
import script from "./DatasetMetadataBlock.vue?vue&type=script&lang=js"
export * from "./DatasetMetadataBlock.vue?vue&type=script&lang=js"
import style0 from "./DatasetMetadataBlock.vue?vue&type=style&index=0&id=681e995c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "681e995c",
  null
  
)

export default component.exports