<script>
export default {
  props: {
    currentValue: {
      required: true,
      type: Number
    },
    maxValue: {
      required: true,
      type: Number
    },
    barIsOnRight: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    formatQuota(decimals = 0) {
      return `${this.$options.filters.formatBytes(
        this.currentValue,
        decimals
      )} / ${this.$options.filters.formatBytes(this.maxValue, decimals)}`
    }
  }
}
</script>

<template>
  <span :title="formatQuota(3)" :class="{ 'bar-on-right-container': barIsOnRight }">
    <span>{{ formatQuota(0) }}</span>
    <span :class="{ 'bar-on-right': barIsOnRight }">
      <b-progress :max="maxValue" :value="currentValue" />
    </span>
  </span>
</template>

<style scoped>
.bar-on-right-container {
  display: flex;
  align-items: center;
  width: 100%;
}
.bar-on-right {
  margin-left: 1rem;
  width: 100%;
  max-width: 16rem;
}
</style>
