<script>
import BusySpinner from '@/layout/AppHeader/BusySpinner'
import LangSelector from '@/layout/AppHeader/LangSelector'
import AlertContainer from '@/layout/AppHeader/AlertContainer'
import User from '@/layout/AppHeader/User'
import LoginButton from '@/layout/AppHeader/LoginButton'
import { getters } from '@/common/store.js'

export default {
  components: {
    BusySpinner,
    LangSelector,
    User,
    AlertContainer,
    LoginButton
  },
  computed: {
    user() {
      return getters.getUser()
    }
  }
}
</script>
<template>
  <b-navbar id="navbar" toggleable="xl">
    <busy-spinner />
    <b-container>
      <!-- Make the Fairdata logo a link only if the user is logged in. -->
      <b-navbar-brand :to="user ? { name: 'DatasetsPage' } : null">
        <img
          v-if="$language.current === 'fi'"
          alt="Fairdata pitkäaikaissäilytys (PAS)"
          src="@/assets/images/fairdata-pas.svg"
          height="50px"
        />
        <img
          v-else
          alt="Fairdata Digital Preservation Service (DPS)"
          src="@/assets/images/fairdata-dps.svg"
          height="50px"
        />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav id="main-nav">
          <b-nav-item v-if="user" :to="{ name: 'DatasetsPage' }">
            <translate>Datasets</translate>
          </b-nav-item>
          <b-nav-item v-if="user" :to="{ name: 'AgreementsPage' }">
            <translate>Agreements</translate>
          </b-nav-item>
          <b-nav-item v-if="user" :to="{ name: 'FileStorageBrowserPage' }">
            <translate>Files</translate>
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav id="right-side-nav">
          <li class="nav-item">
            <b-button
              variant="primary"
              class="help"
              target="_blank"
              :href="
                $language.current === 'fi'
                  ? 'https://www.fairdata.fi/fairdata-pas/'
                  : 'https://www.fairdata.fi/en/dps-for-research-data/'
              "
            >
              <translate>Help</translate>
            </b-button>
          </li>
          <li>
            <LangSelector />
          </li>
          <li class="nav-item">
            <User v-if="user" />
            <LoginButton v-else />
          </li>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
    <alert-container />
  </b-navbar>
</template>

<style lang="scss">
@use '/src/assets/css/app';

$navbar-border: app.$gray-light;
$navbar-height: 4rem;

#navbar {
  border-top: 1px solid $navbar-border;
  border-bottom: 1px solid $navbar-border;
  height: $navbar-height;
  padding: 0 0 0 0;
}

#main-nav {
  display: flex;
  flex-direction: column;
  column-gap: 3rem;
  row-gap: 1.25rem;
  justify-content: center;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}

#main-nav .nav-link {
  padding: 0 0;
}

#navbar a:not(.help, .login-button) {
  color: app.$gray;
}

#main-nav .router-link-active span {
  color: app.$blue;
}

// Mobile/small screen stylings
@include app.media-breakpoint-down(lg) {
  #right-side-nav .nav-item,
  #right-side-nav .dropdown-item {
    text-align: center;
  }

  #right-side-nav {
    margin: 1rem 0;
  }

  #right-side-nav .nav-item {
    margin: 0.5rem 0;
  }

  #right-side-nav .dropdown-toggle {
    padding: 0 0;
  }

  #navbar .navbar-collapse {
    background-color: app.$white;
    border: 1px solid $navbar-border;
    border-top: none;
    margin-top: 2px;
    z-index: 100;
  }
}

// Desktop/big screen stylings
@include app.media-breakpoint-up(xl) {
  #navbar .navbar-collapse {
    background-color: transparent;
    margin: 0 0 0 0;
    border: none;
  }

  #main-nav {
    flex-direction: row;
  }

  #main-nav .router-link-active::after {
    content: '';
    position: absolute;
    display: block;

    // Draws a triangle pointing up
    border: 8px solid transparent;
    border-bottom: 8px solid app.$blue;

    //Centers triangle horizontally
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    bottom: 0;
    width: 1rem;
  }

  #navbar .nav-item {
    // Triangle positioning
    position: relative;
    height: $navbar-height;

    // Text center vertically
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #right-side-nav .nav-item {
    margin-left: 0.65rem;
    margin-right: 0.65rem;
  }
}
</style>
