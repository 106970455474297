<script>
import _ from 'lodash'

// Regexes used to detect different checksums.
// These are capable of parsing output created by {md5,sha256,sha512}sum
// CLI utilities.
const MULTIPLE_CHECKSUM_REGEXES = {
  sha256: [/^([a-fA-F0-9]{64})\s*[/|./|*]*(.*$)/],
  sha1: [/^([a-fA-F0-9]{40})\s*[/|./|*]*(.*$)/],
  md5: [/^([a-fA-F0-9]{32})\s*[/|./|*]*(.*$)/]
}

const SINGLE_CHECKSUM_REGEXES = {
  sha256: [/^([a-fA-F0-9]{64})$/],
  sha1: [/^([a-fA-F0-9]{40})$/],
  md5: [/^([a-fA-F0-9]{32})$/]
}

/**
 * Parse a line and return a {algorithm, checksum, path} result, or null
 * if the line didn't match any recognized checksum
 */
function parseChecksumAndAlgorithm(line) {
  for (const [algorithm, regexes] of Object.entries(MULTIPLE_CHECKSUM_REGEXES)) {
    for (const regex of regexes) {
      // Try parsing the line
      const result = regex.exec(line)

      if (result && result.length === 3) {
        return {
          algorithm: algorithm,
          checksum: result[1],
          path: result[2]
        }
      }
    }
  }

  return null
}

function parseChecksum(line) {
  for (const [algorithm, regexes] of Object.entries(SINGLE_CHECKSUM_REGEXES)) {
    for (const regex of regexes) {
      const result = regex.exec(line)

      if (result && result.length === 2) {
        return {
          algorithm: algorithm,
          checksum: result[1]
        }
      }
    }
  }

  return null
}

export default {
  name: 'FileChecksumListInput',
  props: {
    fileNames: {
      type: Array,
      required: true
    },
    single: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text: '',
      checkSumNames: ['sha256sum', 'sha1sum', 'md5sum']
    }
  },
  computed: {
    /**
     * Mapping of 'path -> {algorithm: String, checksum: String}'
     */
    checksumMap() {
      return this.parseInput()
    },
    /**
     * Count of files that don't have checksums
     */
    missingFileCount() {
      const filesToUpload = new Set(this.fileNames)
      const filesWithChecksums = new Set(Object.keys(this.checksumMap))

      for (const file of filesWithChecksums) {
        filesToUpload.delete(file)
      }

      return filesToUpload.size
    },
    detectedAlgorithms() {
      const detectedAlgorithms = new Set()

      for (const entry of Object.values(this.checksumMap)) {
        detectedAlgorithms.add(entry.algorithm)
      }
      return Array.from(detectedAlgorithms)
    },
    textPlaceholder() {
      return this.$pgettext('Text input placeholder', '<CHECKSUM> <NAME>\n<CHECKSUM> <NAME>\n...')
    },
    inputLabel() {
      if (this.single) {
        return this.$pgettext('Input label', 'File checksum')
      } else {
        return this.$pgettext('Input label', 'File checksums')
      }
    },
    isValid() {
      return this.fileNames.length > 0 && this.missingFileCount === 0
    }
  },
  watch: {
    checksumMap: function (newValue, old) {
      this.$emit('checksums-changed', this.checksumMap)
    },
    isValid: function (newValue, old) {
      this.$emit('is-valid-changed', this.isValid)
    }
  },
  methods: {
    /**
     * Parse the user provided text input and return a
     * {path: {algorithm, checksum}} mapping
     */
    parseInput() {
      // Use different parsing depending on whether the user has to
      // provide a single checksum or multiple
      if (this.single) {
        return this.parseSingle()
      } else {
        return this.parseMultiple()
      }
    },
    /**
     * Parse the user provided text line and return a
     * {path: {algorithm, checksum}} mapping which only contains one value
     */
    parseSingle() {
      const results = {}

      if (this.fileNames.length === 1) {
        const result = parseChecksum(this.text)

        if (result) {
          results[this.fileNames[0]] = {
            algorithm: result.algorithm,
            checksum: result.checksum
          }
        }
      }

      return results
    },
    /**
     * Parse the user provided text input and return a
     * {path: {algorithm, checksum}} mapping
     */
    parseMultiple() {
      const results = {}
      const lines = this.text.split('\n')

      for (const line of lines) {
        const result = parseChecksumAndAlgorithm(line)

        if (result !== null) {
          // Ignore unrelated files
          if (!_.includes(this.fileNames, result.path)) {
            continue
          }

          results[result.path] = {
            algorithm: result.algorithm,
            checksum: result.checksum
          }
        }
      }

      return results
    }
  }
}
</script>

<template>
  <b-form-group :label="inputLabel">
    <b-form-textarea v-if="!single" v-model="text" :placeholder="textPlaceholder" />
    <b-form-input v-else v-model="text" type="text" />
    <b-form-invalid-feedback :state="isValid">
      <template v-if="single && missingFileCount === 1">
        <translate>The file is missing a checksum.</translate>
      </template>
      <template v-else-if="!single && missingFileCount > 0">
        <translate
          :translate-n="missingFileCount"
          translate-plural="%{ missingFileCount } files are missing checksums."
        >
          %{ missingFileCount } file is missing a checksum.
        </translate>
      </template>
    </b-form-invalid-feedback>
    <b-form-valid-feedback :state="isValid">
      <template v-if="single && detectedAlgorithms.length === 1">
        <translate>Detected algorithm:</translate>
        {{ detectedAlgorithms[0].toUpperCase() }}
      </template>
      <template v-else-if="!single && detectedAlgorithms.length > 0">
        <translate>Detected algorithms:</translate>
        {{ detectedAlgorithms.join(', ').toUpperCase() }}
      </template>
    </b-form-valid-feedback>
    <b-form-text>
      <div v-if="single" v-translate>Provide a checksum for the file.</div>
      <div v-else v-translate>
        On each line provide a checksum and the file name separated by whitespace.
      </div>
      <div v-translate>Supported algorithms: SHA256, SHA1, MD5</div>
      <div v-if="!single">
        {{ $pgettext('Checksum algorithm list 1/2', 'Outputs from') }}
        <span v-for="(name, i) in checkSumNames" :key="name">
          <a :href="`https://linux.die.net/man/1/${name}`" target="_blank"> {{ name }}</a
          >{{ i | listComma(checkSumNames.length, true) }}
        </span>
        {{ $pgettext('Checksum algorithm list 2/2', 'utilities are supported.') }}
      </div>
    </b-form-text>
  </b-form-group>
</template>
