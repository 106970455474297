<script>
import DatasetListItem from './DatasetListItem.vue'

export default {
  components: {
    DatasetListItem
  },
  props: {
    isBusy: {
      type: Boolean
    },
    datasets: {
      type: Array,
      default: () => []
    },
    showOnlyId: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<template>
  <div>
    <b-overlay :show="isBusy" opacity="0.5" rounded="lg">
      <ul id="dataset-list">
        <li v-for="item in datasets" :key="item.identifier">
          <hr />
          <DatasetListItem
            :preservation-state="item.preservation_state"
            :identifier="item.identifier"
            :name="item.name"
            :created="item.created"
            :size="item.size"
            :agreement="item.agreement"
            :show-only-id="showOnlyId"
            @search-values-changed="filterValuesChangedHandler($event)"
          />
        </li>
        <hr v-if="datasets.length !== 0" />
      </ul>
    </b-overlay>
  </div>
</template>

<style lang="scss">
#dataset-list {
  padding: 0 2.5rem;
}
</style>
