<script>
import ClipboardCopyBadge from '@/common/ClipboardCopyBadge'
export default {
  components: {
    ClipboardCopyBadge
  },
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: [String, Array],
      required: true
    },
    isBadges: {
      type: Boolean,
      default: false
    },
    hasCopyButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    contentIsArray() {
      return Object.prototype.toString.call(this.content) === '[object Array]'
    }
  }
}
</script>
<template>
  <div>
    <dt class="title">
      <strong>{{ title }}</strong>
    </dt>
    <dd v-if="content.length === 0">-</dd>
    <dd v-else-if="!contentIsArray" class="d-flex align-items-center">
      <span class="mr-3">{{ content }}</span>
      <clipboard-copy-badge v-if="hasCopyButton" :value="content" />
    </dd>
    <dd v-else-if="isBadges" class="badgeContainer">
      <span v-for="item in content" :key="item" class="badge">{{ item }}</span>
    </dd>
    <dd v-else>
      {{ content.map((item, i) => item).join(', ') }}
    </dd>
  </div>
</template>
<style lang="scss" scoped>
@use '/src/assets/css/app';
div {
  background-color: app.$green-light;
  padding: 1.875rem 1.875rem;
}
dt {
  margin-bottom: 1rem;
}
.badgeContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.badge {
  background-color: app.$gray-keyword;
  border-radius: 0.25rem;
  color: app.$white;
  padding: 0 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.02875rem;
}
</style>
