<script>
import { titleMixin } from '@/mixins/titleMixin'

export default {
  mixins: [titleMixin],
  computed: {
    titleItems() {
      return [this.$gettext('Login')]
    },
    userGuideLink() {
      if (this.$language.current === 'fi') {
        return 'https://www.fairdata.fi/fairdata-pas/'
      } else {
        return 'https://www.fairdata.fi/en/dps-for-research-data/'
      }
    }
  }
}
</script>

<template>
  <main id="login-page" class="container">
    <div class="left-side">
      <h1>{{ $gettext('DPS') }}</h1>
      <h2>{{ $gettext('Digital Preservation Service for Research Data') }}</h2>
      <p>
        <translate
          >The Digital Preservation Service for Research Data ensures accessibility and
          preservation of digital research materials. The goal is to enable verifiability and
          reproducibility of the research at various stages of the data lifecycle, making it easy
          to utilize research results. In this way, research datasets can be reused, evaluated, and
          employed in decision-making processes. The availability of preserved datasets are also
          ensured for future generations of researchers, even as the volume of data grows rapidly
          due to the digitalisation of the society. With the Management Service, you are able to
          transfer datasets to digital preservation and fetch them. Using the service requires a
          service contract and a preservation agreement regarding the data to be
          transferred.</translate
        >
      </p>
      <b-link :href="userGuideLink">
        {{ $gettext('How to start using DPS and user guides') }}
      </b-link>
    </div>
    <div class="right-side">
      <div class="diagram-container-column">
        <div class="diagram-container-row">
          <div class="diagram-container">
            <h4>IDA</h4>
            <img src="@/assets/images/ida-diagram.svg" alt="IDA" />
            <p>{{ $gettext('Data stored in IDA') }}</p>
          </div>
          <div class="diagram-container highlighted-diagram-container">
            <h4>{{ $gettext('DPS') }}</h4>
            <img src="@/assets/images/pas-diagram.svg" alt="DPS" />
            <p>{{ $gettext('Data temporarily stored in Pre-Ingest File Storage') }}</p>
          </div>
        </div>
        <div class="diagram-arrow-row">
          <b-icon icon="arrow-down" font-scale="1.25" />
          <b-icon icon="arrow-down" font-scale="1.25" />
        </div>
        <div class="diagram-container-row">
          <div class="diagram-container">
            <h4>Qvain</h4>
            <img src="@/assets/images/qvain-diagram.svg" alt="Qvain" />
            <p>{{ $gettext('Describe your dataset and add metadata to it.') }}</p>
          </div>
          <div class="diagram-container">
            <h4>Metax</h4>
            <img src="@/assets/images/qvain-diagram.svg" alt="Metax" />
            <p>{{ $gettext('Describe your dataset and add metadata to it.') }}</p>
          </div>
        </div>
        <div class="diagram-arrow-row">
          <b-icon icon="arrow-down" font-scale="1.25" />
          <b-icon icon="arrow-down" font-scale="1.25" />
        </div>
      </div>
      <div class="wide-diagram-container highlighted-diagram-container">
        <h4>{{ $gettext('DPS') }}</h4>
        <img src="@/assets/images/pas-diagram.svg" alt="DPS" />
        <p>{{ $gettext('Select, propose and accept datasets for digital preservation.') }}</p>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
#login-page {
  margin-top: 5.25rem;
  margin-bottom: 5.25rem;
  display: flex;
  flex-direction: row;
}
.left-side {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 40%;
}
.right-side {
  padding-left: 2rem;
  width: 60%;
}
h1 {
  margin: 0 0;
}
h2 {
  font-weight: normal;
}
h4 {
  font-size: 1rem;
  font-weight: normal;
}
img {
  height: 4rem;
}
.diagram-container p {
  padding: 0 1rem;
  font-size: 0.875rem;
}
.diagram-container {
  display: grid;
  grid-template-columns: 3.75rem 5.625rem auto;
  padding: 1rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.067);
  box-shadow: rgba(0, 0, 0, 0.153) 0px 3px 6px;
  border-radius: 0.5rem;
  align-items: center;
}
.wide-diagram-container {
  @extend .diagram-container;
  width: 100%;
}
.highlighted-diagram-container {
  border-color: rgba(0, 127, 173, 0.2);
  box-shadow: rgba(0, 127, 173, 0.2) 0px 3px 6px;
  color: rgb(0, 77, 121);
}
.diagram-arrow-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 1rem;
  margin: 0.5rem 0;
}
.diagram-container-row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.diagram-container-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
