<script>
import ClipboardCopyBadge from '@/common/ClipboardCopyBadge'
import PasStateBadge from '@/common/PasStateBadge'

export default {
  components: { ClipboardCopyBadge, PasStateBadge },
  props: {
    preservationState: {
      required: true,
      type: Number
    },
    identifier: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    agreement: {
      required: true,
      type: String
    },
    created: {
      required: true,
      type: String
    },
    size: {
      required: true,
      type: String
    },
    showOnlyId: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
</script>
<template>
  <div class="dataset-list-item">
    <router-link
      :to="{ name: 'DatasetPage', params: { id: `${identifier}` } }"
      class="dataset-name-link"
    >
      {{ name }}
    </router-link>
    <pas-state-badge :pas-state="preservationState" />
    <dl class="dataset-info">
      <template v-if="showOnlyId">
        <dt class="center-vertically">
          {{ $gettext('Identifier') }}
        </dt>
        <dd class="center-vertically">
          {{ identifier }}
          <ClipboardCopyBadge :value="identifier" />
        </dd>
      </template>
      <template v-else>
        <dt>{{ $gettext('Agreement') }}</dt>
        <dd>{{ agreement }}</dd>
        <dt>{{ $gettext('Created') }}</dt>
        <dd>{{ created }}</dd>
        <dt>{{ $gettext('Size') }}</dt>
        <dd>{{ size }}</dd>
      </template>
    </dl>
  </div>
</template>

<style lang="scss" scoped>
.dataset-list-item {
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.125rem;
}
dd,
dl {
  margin-bottom: 0;
}
button {
  margin-left: 1rem;
}
.dataset-info {
  display: grid;
  grid-template-columns: 7.5rem auto;
  overflow-x: hidden;
  grid-row-gap: 1rem;
}
.dataset-list-item a {
  font-weight: bold;
  align-self: flex-start;
}
.center-vertically {
  display: flex;
  align-items: center;
}
</style>
