export default {
  getPreferredValue(localizedValues, datasetLanguages) {
    if (!localizedValues) {
      return ''
    }
    if (typeof localizedValues !== 'object') {
      return localizedValues
    }

    // Languages in order of preference
    // Per MetaX schema, 'und' and 'zxx' are fallbacks for text
    // that cannot be localized
    const languages = datasetLanguages.concat(['und', 'zxx'])

    for (const lang of languages) {
      if (Object.prototype.hasOwnProperty.call(localizedValues, lang)) {
        return localizedValues[lang]
      }
    }

    if (Object.keys(localizedValues).length > 0) {
      return localizedValues[Object.keys(localizedValues)[0]]
    }

    return ''
  }
}
