<script>
export default {
  name: 'ModalDialog',
  props: {
    dialogVariant: {
      type: String,
      required: true
    },
    dialogTitle: {
      type: String,
      required: true
    },
    dialogMessage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      modalShow: true
    }
  },
  computed: {
    buttonVariant() {
      return 'outline-' + this.dialogVariant
    }
  }
}
</script>
<template>
  <div>
    <b-modal
      v-model="modalShow"
      :header-bg-variant="dialogVariant"
      button-size="sm"
      ok-only
      :title="dialogTitle"
      :ok-variant="buttonVariant"
    >
      {{ dialogMessage }}
    </b-modal>
  </div>
</template>
