<script>
import FormInput from '@/common/FormInput'

export default {
  name: 'FileStorageNewDirectoryModal',
  components: { FormInput },
  props: {
    project: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      modalShow: false,
      rootPath: '',

      newDirectoryName: ''
    }
  },
  computed: {
    modalTitle() {
      return this.$pgettext('Modal title', 'New directory')
    },
    modalOkText() {
      return this.$pgettext('Button text', 'Create')
    },
    modalCancelText() {
      return this.$pgettext('Button text', 'Cancel')
    },
    directoryNameHasSlash() {
      return this.newDirectoryName.includes('/')
    },
    directoryNameTooLong() {
      return this.newDirectoryName.length >= 256
    },
    isNewDirectoryNameValid() {
      // Directory name cannot be empty or contain the path separator '/'
      return (
        this.newDirectoryName.length > 0 &&
        !this.directoryNameTooLong &&
        !this.directoryNameHasSlash
      )
    }
  },
  methods: {
    openDialog: function (path) {
      this.modalShow = true
      this.rootPath = path
      this.newDirectoryName = ''
    },
    createDirectory: function () {
      this.$emit('new-directory-selected', {
        path: this.rootPath,
        newDirName: this.newDirectoryName
      })
    }
  }
}
</script>

<template>
  <div>
    <b-modal
      v-model="modalShow"
      :title="modalTitle"
      :cancel-title="modalCancelText"
      :ok-title="modalOkText"
      :ok-disabled="!isNewDirectoryNameValid"
      :return-focus="`#file-entry-${rootPath.replaceAll('/', '-')}`"
      @ok="createDirectory"
      @show="$emit('show')"
      @hidden="$emit('hidden')"
    >
      <p class="new-title">
        <translate>Create new directory under</translate>:
        <strong>{{ rootPath }}</strong>
      </p>
      <form-input
        id="input-new-directory-name"
        v-model="newDirectoryName"
        :is-valid="isNewDirectoryNameValid"
        :label="$gettext('Directory name')"
        required
      >
        <template #description>
          <translate translate-context="Directory name feedback">
            Directory name must meet the following requirements:
          </translate>
          <ul>
            <li v-translate translate-context="Form validation requirement">
              length of 1-255 characters
            </li>
            <li
              v-translate="{ symbol: '<kbd>/</kbd>' }"
              render-html="true"
              translate-context="Form validation requirement"
            >
              cannot contain the %{ symbol } symbol
            </li>
          </ul>
        </template>
        <template #feedback>
          <b-form-invalid-feedback v-if="directoryNameTooLong">
            <translate>Directory name too long</translate>
          </b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="directoryNameHasSlash">
            <translate>Directory name can't contain </translate><kbd>/</kbd>
          </b-form-invalid-feedback>
        </template>
      </form-input>
    </b-modal>
  </div>
</template>

<style scoped>
.new-title {
  word-break: break-word;
}
</style>
