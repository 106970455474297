<script>
export default {
  name: 'ClipboardCopyBadge',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      copiedToClipboard: false
    }
  },
  computed: {
    title() {
      if (this.copiedToClipboard) {
        return this.$gettext('Copied to clipboard!')
      }

      return this.$gettext('Copy to clipboard')
    }
  },
  methods: {
    async copyValueToClipboard() {
      let success = false
      const value = this.value

      if (navigator.clipboard && window.isSecureContext) {
        // First, try the Clipboard API.
        // This is a modern API, but only accessible in a secure context
        // (HTTPS or localhost)
        await navigator.clipboard.writeText(value)
        success = true
      } else {
        // navigator.clipboard not available
        // Use "navigator.execCommand + invisible textarea element" as a
        // fallback
        // This is a deprecated API and ugly, so maybe remove this once
        // Clipboard API can be used more easily?
        const el = document.createElement('textarea')
        el.value = value
        el.setAttribute('readonly', '')
        el.style.position = 'absolute'
        el.style.left = '-9999px'
        document.body.appendChild(el)
        el.focus()
        el.select()

        success = document.execCommand('copy')

        document.body.removeChild(el)
      }

      if (success) {
        this.copiedToClipboard = true
      }
    },
    resetCopyToClipboardTitle() {
      // Reset the tooltip to ensure the "Copied to clipboard!" message
      // doesn't persist once the user stops hovering over the button
      this.copiedToClipboard = false
    }
  }
}
</script>

<template>
  <b-button
    ref="clipboard-copy-button"
    v-b-tooltip.hover
    variant="outline-primary"
    @click="copyValueToClipboard"
  >
    <translate>Copy</translate>
    <b-tooltip
      :target="() => $refs['clipboard-copy-button']"
      :title="title"
      @hidden="resetCopyToClipboardTitle"
    />
  </b-button>
</template>
