import BackendAccess from '@/services/BackendAccess'
import { getters } from '@/common/store'
import * as pathUtils from '@/common/path.js'

class FileStorageBackendAccess {
  async getProjectDirectory(project, path) {
    const access = new BackendAccess()
    path = encodeURI(pathUtils.stripLeadingSlash(path))
    const response = await access.service.get(`${access.uploadBasePath}/files/${project}/${path}`, {
      headers: {
        Authorization: `Bearer ${getters.getSessionToken()}`
      }
    })

    if (response.status === 404) {
      // Project directory does not exist yet; return an empty directory
      return {
        directories: [],
        files: []
      }
    }

    // Sort entries in an ascending order
    response.data.directories = response.data.directories.sort()
    response.data.files = response.data.files.sort()

    return response.data
  }

  async getProjectPathDetails(project, path) {
    const access = new BackendAccess()
    path = encodeURI(pathUtils.stripLeadingSlash(path))
    const response = await access.service.get(`${access.uploadBasePath}/files/${project}/${path}`, {
      headers: {
        Authorization: `Bearer ${getters.getSessionToken()}`
      }
    })

    if (response.status === 404) {
      return null
    }

    const result = response.data

    const details = {
      identifier: result.identifier
    }

    if ('directories' in response.data) {
      // This is a directory
      details.type = 'directory'
      details.directories = result.directories
      details.files = result.files
    } else {
      // This is a file
      details.type = 'file'
      details.filePath = result.file_path
      details.md5 = result.md5
      details.timestamp = result.timestamp
    }

    return details
  }

  /**
   * Create directory
   */
  async createDirectory(project, path) {
    const access = new BackendAccess()
    path = encodeURI(pathUtils.stripLeadingSlash(path))
    const response = await access.service.post(
      `${access.uploadBasePath}/directories/${project}/${path}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getters.getSessionToken()}`
        }
      }
    )

    return {
      dirPath: response.data.dir_data,
      status: response.data.status
    }
  }

  async deletePath(project, path) {
    const access = new BackendAccess()
    path = encodeURI(pathUtils.stripLeadingSlash(path))
    return await access.service.delete(`${access.uploadBasePath}/files/${project}/${path}`, {
      headers: {
        Authorization: `Bearer ${getters.getSessionToken()}`
      }
    })
  }

  /**
   * Get dataset information for the given path, which can point
   * to either a file or a directory.
   *
   * Returns an object with the fields:
   * {
   *   datasets: [{title, identifier, preservation_state}, ...],
   *   hasPendingDataset: true|false
   * }
   */
  async getEntryDatasetInfo(project, path) {
    const access = new BackendAccess()
    path = encodeURI(pathUtils.stripLeadingSlash(path))
    const response = await access.service.get(
      `${access.uploadBasePath}/datasets/${project}/${path}`,
      {
        headers: {
          Authorization: `Bearer ${getters.getSessionToken()}`
        }
      }
    )

    return {
      datasets: response.data.datasets,
      hasPendingDataset: response.data.has_pending_dataset
    }
  }

  /**
   * Get information for a background task with the given identifier.
   *
   * NOTE: Task information for a completed (successful or failed) task will
   *       be deleted after retrieval!
   *
   * Returns an object with the fields:
   * {
   *   status: 'done' | 'error' | 'pending',
   *   message: 'arbitrary message',
   *   errors: [list of error objects]
   * }
   */
  async getTaskInformation(identifier) {
    const access = new BackendAccess()
    const response = await access.service.get(`${access.uploadBasePath}/tasks/${identifier}`, {
      headers: {
        Authorization: `Bearer ${getters.getSessionToken()}`
      },
      validateStatus: (status) => status < 400
    })

    return response.data
  }

  async getFileSizeLimit() {
    const access = new BackendAccess()
    const response = await access.service.get(`${access.uploadBasePath}/files/get_size_limit`, {
      headers: {
        Authorization: `Bearer ${getters.getSessionToken()}`
      }
    })

    return response.data
  }
}

export default FileStorageBackendAccess
