<script>
import FileStorageBackendAccess from '@/services/FileStorageBackendAccess'
import ClipboardCopyBadge from '@/common/ClipboardCopyBadge'

export default {
  name: 'FileStorageDetailsModal',
  components: {
    ClipboardCopyBadge
  },
  props: {
    project: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      modalShow: false,
      path: '',

      loaded: false,

      metaxIdentifier: '',
      size: 0,
      md5: '',
      timestamp: null
    }
  },
  computed: {
    modalTitle() {
      return this.$pgettext('Modal title', 'Entry details')
    },
    modalOkText() {
      return this.$pgettext('Button action', 'Close')
    }
  },
  methods: {
    openDialog: async function (path) {
      this.modalShow = true
      this.loaded = false
      this.path = path

      // Clear the existing fields
      this.size = 0
      this.metaxIdentifier = ''
      this.md5 = ''
      this.timestamp = null

      const access = new FileStorageBackendAccess()
      const data = await access.getProjectPathDetails(this.project, this.path)

      this.metaxIdentifier = data.identifier

      if (data.type === 'file') {
        // Only set file specific fields if this is a file
        this.size = data.size
        this.md5 = data.md5
        this.timestamp = data.timestamp
      }

      this.loaded = true
    }
  }
}
</script>

<template>
  <b-modal
    v-model="modalShow"
    :title="modalTitle"
    :ok-title="modalOkText"
    :return-focus="`#file-entry-${path.replaceAll('/', '-')}`"
    ok-only
    size="lg"
    @show="$emit('show')"
    @hidden="$emit('hidden')"
  >
    <p>
      <strong>{{ path }}</strong>
    </p>
    <template v-if="loaded">
      <b-table-simple>
        <b-tbody>
          <b-tr>
            <b-th>
              <translate translate-context="File/directory field"> Identifier </translate>
            </b-th>
            <b-th v-if="metaxIdentifier">
              <span data-test="file-metax-identifier">{{ metaxIdentifier }}</span>
              <clipboard-copy-badge :value="metaxIdentifier" />
            </b-th>
            <b-th v-else>
              <translate>
                Identifier not found. Resource metadata generation may still be incomplete.
                Identifiers are not assigned to empty directories.
              </translate>
            </b-th>
          </b-tr>
          <b-tr v-if="size">
            <b-th>
              <translate translate-context="File field"> Size </translate>
            </b-th>
            <b-th>{{ size | formatSize }}</b-th>
          </b-tr>
          <b-tr v-if="md5">
            <b-th>
              <translate translate-context="File field"> MD5 checksum </translate>
            </b-th>
            <b-th>{{ md5 }}</b-th>
          </b-tr>
          <b-tr v-if="timestamp">
            <b-th>
              <translate translate-context="File field"> Timestamp </translate>
            </b-th>
            <b-th>
              {{ timestamp | formatDate }}
            </b-th>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </template>
    <template v-else>
      <translate>Loading...</translate>
    </template>
  </b-modal>
</template>
