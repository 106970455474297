<script>
import FileUploadProgressBar from '@/common/QuotaProgressBar'
import StorageItem from '@/common/StorageItem'
import FileStorageEntryDropdown from '@/views/FileStorageBrowserPage/FileStorageEntryDropdown'

export default {
  components: {
    StorageItem,
    FileStorageEntryDropdown,
    FileUploadProgressBar
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    extractPopover() {
      const title = this.$gettext('Archive extraction in progress')
      const explanation = this.$gettext(
        'Archive extraction is in progress to this directory. This directory will become visible shortly.'
      )

      return `<strong>${title}</strong><br>${explanation}`
    },
    fileTaskPopover() {
      const title = this.$gettext('Upload being finalized')
      const explanation = this.$gettext(
        'The file has been uploaded and will become visible shortly.'
      )

      return `<strong>${title}</strong><br>${explanation}`
    }
  },
  methods: {
    storageItemType(storageItem) {
      return storageItem.isDir ? 'dir' : 'file'
    }
  }
}
</script>

<template>
  <storage-item :storage-item="item" v-on="$listeners">
    <template #default="slotProps">
      <span class="item-info">
        <!-- ICON -->
        <b-icon v-if="slotProps.storageItem.type === 'file'" icon="file-text" />
        <b-icon v-else-if="slotProps.storageItem.type === 'dir'" icon="folder" />
        <b-icon v-else-if="slotProps.storageItem.type === 'extract'" icon="file-zip" />
        <b-icon v-else-if="slotProps.storageItem.type === 'fileFinalize'" icon="file-text" />
        <b-icon
          v-else
          :icon="slotProps.storageItem.uploadActive ? 'cloud-upload' : 'cloud-upload-fill'"
        />

        <!-- ITEM NAME -->
        <span>
          {{ slotProps.storageItem.title }}
        </span>

        <!-- EXTRACT TEXT -->
        <template v-if="slotProps.storageItem.type === 'extract'">
          <b-icon v-b-popover.hover.html="extractPopover" icon="exclamation-circle" />
          <span>
            <translate translate-context="Archive upload status">Extracting...</translate>
          </span>
        </template>

        <!-- FILE TASK TEXT -->
        <template v-if="slotProps.storageItem.type === 'fileTask'">
          <b-icon v-b-popover.hover.html="fileTaskPopover" icon="exclamation-circle" />
          <span>
            <translate translate-context="File upload status">Finishing upload...</translate>
          </span>
        </template>

        <!-- UPLOAD PROGRESS BAR -->
        <template
          v-else-if="
            slotProps.storageItem.type === 'fileUpload' ||
            slotProps.storageItem.type === 'archiveUpload'
          "
        >
          <file-upload-progress-bar
            v-if="slotProps.storageItem.uploadActive"
            :current-value="slotProps.storageItem.bytesUploaded"
            :max-value="slotProps.storageItem.bytesTotal"
            bar-is-on-right
          />
          <template v-else>
            <translate translate-context="Upload status">Enqueued</translate>
          </template>
        </template>
      </span>
    </template>
    <template #right-side-content="slotProps">
      <file-storage-entry-dropdown
        :type="storageItemType(slotProps.storageItem)"
        :path="slotProps.storageItem.id"
        v-on="$listeners"
      />
    </template>
  </storage-item>
</template>

<style scoped>
.item-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}
</style>
