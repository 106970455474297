<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: true
    }
  },
  methods: {
    emitEvent(name) {
      this.$emit(name, {
        path: this.path,
        type: this.type
      })
    },
    fixDropdownRendering: function () {
      // HACK: Fix dropdown being covered by the row by manually applying
      // 'z-index: 1' to the currently shown row
      document.querySelectorAll('.vue-recycle-scroller__item-view').forEach((elem) => {
        elem.style['z-index'] = ''
      })
      this.$el.parentElement.parentElement.parentElement.parentElement.style['z-index'] = 1
    }
  }
}
</script>

<template>
  <b-dropdown
    :data-test="path === '/' ? 'dropdown-root' : `dropdown-${path}`"
    class="file-dropdown"
    variant="link"
    @shown="fixDropdownRendering"
  >
    <template #button-content>
      <b-icon icon="three-dots-vertical" />
    </template>
    <b-dropdown-item v-if="path !== '/'" href="#" @click="emitEvent('details-selected')">
      <b-icon icon="info-circle" />&nbsp;
      <translate key="file-entry-details" translate-context="File/directory action">
        Details
      </translate>
    </b-dropdown-item>
    <b-dropdown-item v-if="type === 'dir'" href="#" @click="emitEvent('upload-files-selected')">
      <b-icon icon="upload" />&nbsp;
      <translate key="file-entry-upload-files" translate-context="File/directory action">
        Upload files
      </translate>
    </b-dropdown-item>
    <b-dropdown-item v-if="type === 'dir'" href="#" @click="emitEvent('upload-archive-selected')">
      <b-icon icon="patch-plus" />&nbsp;
      <translate key="file-entry-upload-archive" translate-context="File/directory action">
        Upload archive
      </translate>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="type === 'dir'"
      href="#"
      data-test="new-directory"
      @click="emitEvent('new-directory-selected')"
    >
      <b-icon icon="folder-plus" />&nbsp;
      <translate key="file-entry-new-directory" translate-context="File/directory action">
        New directory
      </translate>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="path !== '/' && (type === 'file' || type === 'dir')"
      href="#"
      variant="danger"
      @click="emitEvent('delete-selected')"
    >
      <b-icon icon="trash" />&nbsp;
      <translate key="file-entry-delete" translate-context="File/directory action">
        Delete
      </translate>
    </b-dropdown-item>
  </b-dropdown>
</template>

<style lang="scss">
@use '/src/assets/css/app';
.file-dropdown .dropdown-toggle::after {
  /* Hide downward arrow/triangle from dropdown menu icon*/
  display: none;
}
.file-dropdown {
  max-width: 2rem;
}
.file-dropdown button {
  @extend .no-style-button;
}
</style>
