<script>
import DatasetStateService from '@/services/DatasetStateService'
import DatasetStateLabelClassService from '@/services/DatasetStateLabelClassService'
export default {
  props: {
    pasState: {
      type: Number,
      required: true
    }
  },
  computed: {
    badgeText() {
      return DatasetStateService.getStateLabel(this.pasState)
    },
    badgeClass() {
      return DatasetStateLabelClassService.getClass(this.pasState)
    }
  }
}
</script>

<template>
  <p :class="badgeClass">
    {{ badgeText }}
  </p>
</template>

<style scoped>
.pas-status-label {
  max-width: fit-content;
}
</style>
