<script>
import BigQuotaProgressBar from '@/common/BigQuotaProgressBar'
import LineChart from '@/views/AgreementPage/LineChart'

export default {
  components: {
    BigQuotaProgressBar,
    LineChart
  },
  props: {
    agreement: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <div id="statistics-container">
    <big-quota-progress-bar :current-value="agreement.quota_used" :max-value="agreement.quota" />
    <div class="d-flex flex-wrap flex-xl-nowrap">
      <LineChart
        :title="$gettext('Accepted datasets')"
        :data="agreement.statistics.accepted_data"
      />
      <LineChart
        :title="$gettext('Used quota')"
        :data="agreement.statistics.quota_data"
        :format-as-bytes="true"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '/src/assets/css/app';

#statistics-container {
  margin-bottom: 6.5rem;
}
@include app.media-breakpoint-up(xl) {
  #statistics-container {
    width: 85%;
  }
}
</style>
