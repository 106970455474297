import axios from 'axios'
class BackendConfiguration {
  async getConfiguration(path, environment) {
    const url = path.endsWith('/') ? `${path}config.json` : `${path}/config.json`
    const response = await axios.get(url)
    const config = response.data

    /* Allow setting overriding configuration in development environment.
     * To use it, create file public/config.dev.json and set the configurations
     * there. You might have to clear your browser's cache and redeploy the
     * server for the changes to take place. */
    if (environment === 'development') {
      const devUrl = path.endsWith('/') ? `${path}config.dev.json` : `${path}/config.dev.json`
      try {
        const devResponse = await axios.get(devUrl)
        Object.assign(config, devResponse.data)
      } catch (e) {
        // If the dev config file doesn't exist, do nothing
      }
    }

    return config
  }
}

export default BackendConfiguration
