export const titleMixin = {
  watch: {
    '$language.current': {
      handler: function () {
        this.updateTitle()
      }
    }
  },
  created() {
    this.updateTitle()
  },
  activated() {
    // If the component has the keep-alive property, title is updated if the
    // component is activated
    this.updateTitle()
  },
  methods: {
    updateTitle() {
      // titleItems should be defined in the component code as
      // computed property that returns a list of strings to
      // be joined together as the page title
      document.title = `${this.titleItems.join(' – ')} | ${this.$gettext('DPS')}`
    }
  }
}
