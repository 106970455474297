<script>
import DatasetMetadataBlock from '@/views/DatasetPage/DatasetMetadataBlock'
import ButtonWithArrow from '@/common/ButtonWithArrow'
import TranslationService from '@/services/TranslationService'
import DatasetAgreementShowAndChange from '@/views/DatasetPage/DatasetAgreementShowAndChange'
import { getters } from '@/common/store.js'
import DatasetStateService from '@/services/DatasetStateService'
import DatasetBackendAccess from '@/services/DatasetBackendAccess'

export default {
  name: 'DatasetMetadata',
  components: {
    DatasetMetadataBlock,
    ButtonWithArrow,
    DatasetAgreementShowAndChange
  },
  props: {
    dataset: {
      type: Object,
      required: true
    },
    datasetStateChanging: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      buttonIsLoading: false
    }
  },
  computed: {
    datasetEtsinUrl() {
      const etsinDatasetUrl = getters.getBackendConfiguration().etsinDatasetUrl
      return `${etsinDatasetUrl}/${this.dataset.identifier}`
    },
    isIdentifyFilesButtonEnabled() {
      return (
        this.dataset.agreement &&
        DatasetStateService.canBeIdentified(this.dataset.passtate) &&
        !this.datasetStateChanging
      )
    },
    languageFullNames() {
      const lang = new Intl.DisplayNames([this?.$language.current], { type: 'language' })
      return this.dataset.datasetLanguages.map((code) => lang.of(code))
    },
    rightSideData() {
      return [
        {
          title: this.$gettext('Publisher'),
          content: this.getPreferredValue(this.dataset.publisher)
        },
        {
          title: this.$gettext('Fields of science'),
          content: this.dataset.fieldOfScience.map((field) => this.getPreferredValue(field))
        },
        {
          title: this.$gettext('Preferred identifier'),
          content: this.dataset.preferredIdentifier,
          hasCopyButton: true
        },
        {
          title: this.$gettext('Geographical locations'),
          content: this.dataset.geoLocation
        },
        {
          title: this.$gettext('Dataset languages'),
          content: this.languageFullNames
        },
        {
          title: this.$gettext('Keywords'),
          content: this.dataset.keyword,
          isBadges: true
        }
      ]
    },
    hasNextButton() {
      return DatasetStateService.isProposeTabEnabled(this.dataset.passtate)
    }
  },
  methods: {
    getPreferredValue(values) {
      return TranslationService.getPreferredValue(values, this.dataset.datasetLanguages)
    },
    async handleIdentifyFiles() {
      this.buttonIsLoading = true
      this.$emit('start-dataset-state-change')
      try {
        const access = new DatasetBackendAccess()
        await access.generateMetadata(this.dataset.identifier)
      } catch (e) {
        this.buttonIsLoading = false
        this.$emit('finish-dataset-state-change')
        throw e
      }
      this.$emit('update-dataset')
    }
  }
}
</script>

<template>
  <div>
    <div id="dataset-metadata">
      <dl id="dataset-metadata-left">
        <dt>{{ $gettext('Creators') }}</dt>
        <dd>
          <li v-for="(creator, i) in dataset.creator" :key="creator.index">
            <span>
              {{ getPreferredValue(creator.name) }}
            </span>
            <span v-if="creator.affiliation">
              - {{ getPreferredValue(creator.affiliation)
              }}{{ i | listComma(dataset.creator.length) }}
            </span>
          </li>
        </dd>
        <dt>{{ $gettext('Publication year') }}</dt>
        <dd v-if="dataset.publicationYear">{{ dataset.publicationYear }}</dd>
        <dd v-else>-</dd>
        <dt>{{ $gettext('Modified') }}</dt>
        <dd v-if="dataset.modified">{{ dataset.modified | formatDate }}</dd>
        <dd v-else>-</dd>
        <dt>{{ $gettext('Preservation agreement') }}</dt>
        <dataset-agreement-show-and-change
          :dataset="dataset"
          @local-dataset-change="(data) => $emit('local-dataset-change', data)"
          @update-dataset="$emit('update-dataset')"
        />
        <dt>{{ $gettext('Description') }}</dt>
        <dd>{{ getPreferredValue(dataset.description) }}</dd>
        <dt>{{ $gettext('Rights') }}</dt>
        <dd v-if="dataset.license.length > 0">
          <div v-for="license in dataset.license" :key="license.identifier">
            <p>{{ getPreferredValue(license.title) }}</p>
            <a :href="license.identifier" target="_blank">{{ license.identifier }}</a>
          </div>
        </dd>
        <dd v-else>-</dd>
        <dt>{{ $gettext('Themes') }}</dt>
        <dd v-if="dataset.theme.length > 0">
          <ul>
            <li v-for="(theme, i) in dataset.theme" :key="theme.index">
              {{ getPreferredValue(theme) }}{{ i | listComma(dataset.theme.length) }}
            </li>
          </ul>
        </dd>
        <dd v-else>-</dd>
      </dl>
      <dl id="dataset-metadata-right">
        <dataset-metadata-block
          v-for="data in rightSideData"
          :key="data.title"
          :title="data.title"
          :content="data.content"
          :has-copy-button="data.hasCopyButton"
          :is-badges="data.isBadges"
        />
      </dl>
    </div>
    <hr />
    <div class="bottom-buttons">
      <div>
        <button-with-arrow
          :text="$gettext('Identify files')"
          :disabled="!isIdentifyFilesButtonEnabled"
          data-test="identify-files"
          :is-loading="buttonIsLoading"
          @click="handleIdentifyFiles"
        />
        <small v-if="!dataset.agreement">
          {{ $gettext('Must select preservation agreement before identifying files.') }}
        </small>
        <small v-if="dataset.passtate >= 10">
          {{ $gettext('You have to reset the dataset to identify files again.') }}
        </small>
      </div>
      <button-with-arrow
        v-if="hasNextButton"
        :text="$gettext('Next')"
        variant="outline-primary"
        @click="$emit('next-page', 1)"
      />
    </div>
    <hr />
    <p id="other-services">
      {{ $gettext('View dataset in') }}
      <a :href="datasetEtsinUrl" target="_blank">Etsin <b-icon icon="box-arrow-up-right" /></a>
    </p>
  </div>
</template>

<style lang="scss" scoped>
@use '/src/assets/css/app';
#dataset-metadata {
  display: flex;
  margin-top: 2.375rem;
  margin-bottom: 1.625rem;
}
@include app.media-breakpoint-down(lg) {
  #dataset-metadata {
    flex-direction: column;
  }
}
#dataset-metadata-right {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 400px;
  margin-left: 3rem;
  margin-bottom: 0;
}
$left-margin: 10.5rem;
#dataset-metadata-left {
  display: grid;
  grid-template-columns: $left-margin auto;
  grid-row-gap: 1.375rem;
  grid-auto-rows: min-content;
}
.bottom-buttons {
  display: flex;
  margin: 2.5rem 0 2.5rem $left-margin;
  justify-content: space-between;
}
#main-container #other-services {
  margin: 2.5rem $left-margin;
}
dd {
  margin-bottom: 0;
}
</style>
