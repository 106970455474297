<script>
import { mutations, getters } from '@/common/store.js'
import UserProfileBackendAccess from '@/services/UserProfileBackendAccess'
import AuthenticationService from '@/services/AuthenticationService'

export default {
  data() {
    return {
      user: undefined
    }
  },
  async mounted() {
    if (!getters.getUser().name) {
      const access = new UserProfileBackendAccess()
      await access.getProfile()
    } else {
      this.user = getters.getUser()
    }
  },
  methods: {
    callback(data) {
      mutations.setUser(data)
      this.user = data
    },
    async logout() {
      const authenticationService = new AuthenticationService()
      await authenticationService.logout()
    }
  }
}
</script>

<template>
  <b-nav-item-dropdown v-if="user" :text="user.name" right>
    <b-dropdown-item :to="{ name: 'ProfilePage' }">
      <translate>Profile</translate>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'FileStorageTokenPage' }">
      <translate>File Storage tokens</translate>
    </b-dropdown-item>
    <b-dropdown-item @click="logout">
      <translate>Sign Out</translate>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>
