<script>
import UserProfileBackendAccess from '@/services/UserProfileBackendAccess'
import { titleMixin } from '@/mixins/titleMixin'
import dayjs from 'dayjs'

export default {
  mixins: [titleMixin],
  data() {
    return {
      tokens: [],
      isBusy: true
    }
  },
  computed: {
    tokenFields() {
      return [
        {
          key: 'name',
          label: this.$gettext('Name')
        },
        {
          key: 'projects',
          label: this.$pgettext('Table column', 'Projects')
        },
        {
          key: 'expirationDate',
          label: this.$pgettext('Table column', 'Expiration date')
        },
        {
          key: 'actions',
          label: '',
          class: 'action-cell'
        }
      ]
    },
    titleItems() {
      return [this.$gettext('File Storage tokens')]
    }
  },
  async mounted() {
    await this.getTokens()
  },
  methods: {
    async getTokens() {
      const access = new UserProfileBackendAccess()
      const tokens = await access.getTokens()
      this.tokens = tokens
      this.isBusy = false
    },
    expirationDatePassed(ymd) {
      if (ymd === null) {
        // If no expiration date exists, the token is valid until revoked
        return false
      }
      const now = dayjs()
      return dayjs(ymd).isBefore(now)
    },
    async deleteToken(token) {
      const deleted = await this.confirmDeleteToken(token)

      if (deleted) {
        // Only delete if the user confirmed it
        const access = new UserProfileBackendAccess()
        await access.deleteUserToken(token.identifier)
        await this.getTokens()
      }
    },

    async confirmDeleteToken(token) {
      const title = `${this.$gettext('Are you sure you want to delete the token')} ${token.name}?`
      return await this.$bvModal.msgBoxConfirm(title, {
        okTitle: this.$gettext('Yes'),
        cancelTitle: this.$gettext('No'),
        cancelVariant: 'outline-primary',
        bodyClass: 'text-center'
      })
    }
  }
}
</script>

<template>
  <page-container id="storage-token-page">
    <header>
      <h1>
        <translate>File Storage tokens</translate>
      </h1>
      <b-button
        id="button-create-new-token"
        v-translate
        class="mb-3"
        variant="outline-primary"
        :to="{ name: 'CreateFileStorageTokenPage' }"
      >
        Create new
      </b-button>
    </header>
    <hr class="mt-0" />
    <div>
      <span>
        <translate
          >File Storage tokens can be used to access the Pre-Ingest File Storage API.
        </translate>
      </span>
      <span>
        <translate translate-comment="File storage description part 1/2"
          >For additional instructions regarding the API and the client application, see</translate
        >
        {{ ' ' }}
        <a href="https://manage.fairdata.fi/docs/tutorial-example-workflow.html"
          ><translate translate-comment="File storage description part 2/2"
            >the official documentation</translate
          > </a
        >.
      </span>
      <hr />
    </div>
    <b-table
      v-if="isBusy || tokens.length > 0"
      :key="$language.current"
      :items="tokens"
      :fields="tokenFields"
      stacked="xl"
      :busy="isBusy"
    >
      <template #cell(projects)="data">
        <span v-for="(project, i) in data.item.projects" :key="project"
          >{{ project }}{{ data.item.projects.length - 1 > i ? ', ' : '' }}
        </span>
      </template>
      <template #cell(expirationDate)="data">
        <template v-if="data.item.expirationDate">
          <span :class="{ 'text-danger': expirationDatePassed(data.item.expirationDate) }">
            {{ data.item.expirationDate | formatDate(true, true, true)
            }}<translate v-if="expirationDatePassed(data.item.expirationDate)">, Expired</translate>
          </span>
        </template>
        <template v-else>
          <p v-translate>No expiration date</p>
        </template>
      </template>
      <template #cell(actions)="data">
        <b-button v-translate variant="outline-primary" @click="deleteToken(data.item)">
          Delete
        </b-button>
      </template>
    </b-table>
    <p v-else show>
      <strong><translate>No tokens have been created.</translate></strong>
    </p>
  </page-container>
</template>

<style>
#storage-token-page hr {
  margin: 2rem 0;
}
#storage-token-page {
  margin-bottom: 6.5rem;
}
#storage-token-page .action-cell div {
  display: flex;
  justify-content: flex-end;
}
</style>
