import axios from 'axios'
import { getters, mutations } from '@/common/store.js'
import useAlertStore from '@/stores/alert'
import AuthenticationService from './AuthenticationService'

class BackendAccess {
  constructor() {
    const bec = getters.getBackendConfiguration()
    this.apiBasePath = bec.apiBasePath
    this.basePath = bec.apiUrl + this.apiBasePath
    this.uploadBasePath = `${bec.uploadApiUrl}v1`
    const service = axios.create()
    service.interceptors.request.use(function (config) {
      mutations.setHttpOperationPending(true)
      return config
    })
    service.interceptors.response.use(this.handleSuccess, this.handleError)
    this.service = service
  }

  async handleSuccess(response) {
    mutations.setHttpOperationPending(false)
    return response
  }

  handleError(error) {
    mutations.setHttpOperationPending(false)
    if (error?.response?.status === 401 && !error?.response?.config?.preventLoginRedirect) {
      /*
       * Not authorized, redirect user to SSO login page.
       */
      mutations.setUser(undefined)
      document.location = new AuthenticationService().loginUrl()
    }

    const silenceAlertForStatuses = error?.response?.config?.silenceAlertForStatuses || []
    const showAlert = !silenceAlertForStatuses.includes(error?.response?.status)

    if (showAlert) {
      const alertStore = useAlertStore()
      alertStore.setAlert({
        variant: 'danger',
        content: error?.response?.data?.error || error.message,
        title: 'Request error'
      })
    }
    return Promise.reject(error)
  }

  get(subPath, callback, errorCallback) {
    return this.service
      .get(this.basePath + subPath)
      .then((response) => callback(response.data))
      .catch((error) => this.relayErrorIfNotSessionTimeout(error, errorCallback))
  }

  post(subPath, data, callback, errorCallback) {
    const bodyFormData = this.encodeForm(data)
    return this.service
      .post(this.basePath + subPath, bodyFormData)
      .then((response) => callback(response.data))
      .catch((error) => this.relayErrorIfNotSessionTimeout(error, errorCallback))
  }

  postAsJSON(subPath, data, callback, errorCallback) {
    return this.service
      .post(this.basePath + subPath, data)
      .then((response) => callback(response.status))
      .catch((error) => this.relayErrorIfNotSessionTimeout(error, errorCallback))
  }

  patchAsJSON(subPath, data, callback, errorCallback) {
    return this.service
      .patch(this.basePath + subPath, data)
      .then((response) => callback(response.status))
      .catch((error) => this.relayErrorIfNotSessionTimeout(error, errorCallback))
  }

  relayErrorIfNotSessionTimeout(error, errorCallback = () => null) {
    if (error.response) {
      errorCallback(error)
    }
  }

  encodeForm(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }
}

export default BackendAccess
