<script>
import FileStorageUploadArchiveModal from '@/views/FileStorageBrowserPage/FileStorageUploadArchiveModal'
import FileStorageUploadFilesModal from '@/views/FileStorageBrowserPage/FileStorageUploadFilesModal'
import FileStorageNewDirectoryModal from '@/views/FileStorageBrowserPage/FileStorageNewDirectoryModal'
import FileStorageDetailsModal from '@/views/FileStorageBrowserPage/FileStorageDetailsModal'
import FileStorageDeleteModal from '@/views/FileStorageBrowserPage/FileStorageDeleteModal'
import FileStorageBackendAccess from '@/services/FileStorageBackendAccess'
import BackendAccess from '@/services/BackendAccess'
import { getTaskFromTusUpload, TaskStatus } from '@/common/FileBackgroundTask'
import { getters } from '@/common/store.js'
import * as pathUtils from '@/common/path.js'
import _ from 'lodash'
import * as tus from 'tus-js-client'
import FileStorageEntries from './FileStorageEntries'

// How many concurrent uploads to have active at the same time.
// This could improve performance when the user is uploading a lot of small
// files all at once.
const MAXIMUM_CONCURRENT_UPLOADS = 2
const UPLOAD_CHUNK_SIZE = 50 * 1024 * 1024 // 50 MB

// Check for finished background jobs every 15 sec
const BACKGROUND_CHECK_INTERVAL = 15000

export default {
  components: {
    FileStorageUploadArchiveModal,
    FileStorageUploadFilesModal,
    FileStorageNewDirectoryModal,
    FileStorageDetailsModal,
    FileStorageDeleteModal,
    FileStorageEntries
  },
  beforeRouteLeave: async function (from, to, next) {
    // If uploads are active, warn user before proceeding
    if (!this.isUploadActive) {
      await this.clear()
      next()
      return
    }

    const proceed = await this.$bvModal.msgBoxConfirm(
      this.$gettext('Uploads will be cancelled if you leave the page! ' + 'Are you sure?')
    )

    if (proceed) {
      await this.clear()
    }

    next(proceed)
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    fileMaxSize: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      // List of absolute directory path names
      directories: [],
      // Absolute path -> list of file names mapping
      files: {},
      // Directories opened by the user
      openedDirectories: ['/'],
      pendingDirectories: [],

      // Uploads that are currently under way
      // (either being uploaded or in the queue) as a
      // "directory name -> list of { dir, name, bytesUploaded, bytesTotal }
      // objects" mapping
      pendingUploadsByDir: {},
      // "directory name -> list of { dir, name, bytesUploaded, bytesTotal }
      // objects" mapping
      activeUploadsByDir: {},
      // Extractions that are underway in a directory as a
      // "directory name -> list of directory name" mappings
      // These are checked regularly. Once the destination directory exists,
      // we'll assume the extraction has finished.
      activeExtractionsByDir: {},
      // Finished file uploads that are undergoing server-side finalization
      // as "directory name -> list of file name" mappings.
      // Small uploads finalize immediately upon the final HTTP request,
      // and larger files will need to be tracked via background task until
      // completion.
      activeFileTasksByDir: {},

      lastLocationY: 0
    }
  },
  // '$options.store' used to store non-reactive data such as file handles.
  // This is a bit weird, but recommended by Vue devs:
  // https://forum.vuejs.org/t/non-reactive-variables-in-single-file-components/28049
  store: {
    tusUploadsByPath: {},
    filesByPath: {},
    backgroundTasksByPath: {},
    backgroundCheckTimeout: null
  },
  computed: {
    entries() {
      // Return a list of entries in the following order
      // 1. directories in an alphabetical order.
      //    For any directories that have been opened, retrieve entries
      //    in a recursive manner.
      // 2. files in an alphabetical order
      return this.getEntries('/', 0)
    },
    pendingUploads() {
      const allUploads = []
      for (const uploads of Object.values(this.pendingUploadsByDir)) {
        allUploads.push(...uploads)
      }

      return allUploads
    },
    activeUploads() {
      const allUploads = []
      for (const uploads of Object.values(this.activeUploadsByDir)) {
        allUploads.push(...uploads)
      }

      return allUploads
    },
    isUploadActive() {
      return this.pendingUploads.length > 0 || this.activeUploads.length > 0
    },
    uploadAsyncThresholdBytes() {
      return getters.getBackendConfiguration().uploadAsyncThresholdBytes
    }
  },
  watch: {
    // Enable/disable 'are you sure you want to leave the page' dialog
    // depending on whether uploads are active
    isUploadActive: function (newValue, oldValue) {
      if (newValue) {
        window.onbeforeunload = function (evt) {
          evt.preventDefault()
          evt.returnValue = true // Chrome requires this to be set
          return true
        }
      } else {
        window.onbeforeunload = undefined
      }
    },
    project(newProject, oldProject) {
      if (newProject.identifier !== oldProject.identifier) {
        this.getFiles()
      }
    }
  },
  mounted() {
    this.getFiles()
  },
  methods: {
    async getFiles() {
      // Reset component data
      Object.assign(this.$data, this.$options.data())

      this.$options.store.tusUploadsByPath = {}
      this.$options.store.filesByPath = {}

      this.$options.store.backgroundCheckTimeout = setTimeout(
        this.performBackgroundChecks,
        BACKGROUND_CHECK_INTERVAL
      )

      await this.retrieveDirectory('/')
    },
    /**
     * Get a list of entries to display in the UI.
     *
     * Each entry corresponds to a single clickable UI element, such as a file
     * or a directory.
     */
    getEntries: function (root, depth) {
      const entries = []
      if (depth === 0) {
        // Root consists only of the root directory
        entries.push({
          type: 'dir',
          path: '/',
          name: '',
          depth: 0,
          opened: true
        })
        depth += 1
      }

      entries.push(...this.getExtractEntries(root, depth))
      entries.push(...this.getUploadEntries(root, depth))
      entries.push(...this.getFileTaskEntries(root, depth))
      entries.push(...this.getDirEntries(root, depth))
      entries.push(...this.getFileEntries(root, depth))

      return entries
    },
    getExtractEntries: function (root, depth) {
      const entries = []

      const extractDirs = this.activeExtractionsByDir[root] || []

      for (const dir of extractDirs) {
        entries.push({
          type: 'extract',
          path: pathUtils.join(root, dir),
          name: dir,
          depth: depth
        })
      }

      return entries
    },
    getFileTaskEntries: function (root, depth) {
      const entries = []

      const fileNamesWithTasks = this.activeFileTasksByDir[root] || []

      for (const name of fileNamesWithTasks) {
        entries.push({
          type: 'fileTask',
          path: pathUtils.join(root, name),
          name: name,
          depth: depth
        })
      }

      return entries
    },
    getUploadEntries: function (root, depth) {
      const entries = []

      const activeUploads = this.activeUploadsByDir[root] || []
      const pendingUploads = this.pendingUploadsByDir[root] || []

      for (const upload of activeUploads) {
        entries.push({
          type: upload.extractDirName ? 'archiveUpload' : 'fileUpload',
          path: pathUtils.join(root, upload.name),
          name: upload.name,
          extractDirName: upload.extractDirName || null,
          depth: depth,
          uploadActive: true,
          bytesUploaded: upload.bytesUploaded,
          bytesTotal: upload.bytesTotal
        })
      }

      for (const upload of pendingUploads) {
        entries.push({
          type: upload.extractDirName ? 'archiveUpload' : 'fileUpload',
          path: pathUtils.join(root, upload.name),
          name: upload.name,
          extractDirName: upload.extractDirName || null,
          depth: depth,
          uploadActive: false,
          bytesUploaded: upload.bytesUploaded,
          bytesTotal: upload.bytesTotal
        })
      }

      return entries
    },
    getDirEntries: function (root, depth) {
      const entries = []

      // Get directories that start with the given root
      const basePath = pathUtils.ensureTrailingSlash(root)
      const directories = this.directories.filter(
        (name) => name.startsWith(basePath) && name !== root
      )

      // Get list of directories to display on this level. This excludes
      // any subdirectories.
      let displayDirs = directories.filter(
        // Find the correct directories based on number of slashes in the
        // full path
        (name) => Array.from(name.matchAll(/\//g)).length === depth
      )
      displayDirs = displayDirs.sort()

      for (const displayDir of displayDirs) {
        const opened = _.includes(this.openedDirectories, displayDir)
        const displayName = depth === 1 ? displayDir.substr(1) : displayDir.substr(root.length + 1)
        entries.push({
          type: 'dir',
          path: displayDir,
          name: displayName,
          depth: depth,
          opened: opened
        })

        // If the user has opened this directory, recurse into it and
        // display any contained directories and files
        if (opened) {
          entries.push(...this.getEntries(displayDir, depth + 1))
        }

        // Add loading component for directories that are opened but have no content yet
        const directoryThatIsLoading = this.pendingDirectories.find((dir) => dir === displayDir)
        if (directoryThatIsLoading) {
          entries.push({
            type: 'loading',
            path: pathUtils.join(root, directoryThatIsLoading),
            depth: depth + 1
          })
        }
      }

      return entries
    },
    getFileEntries: function (root, depth) {
      const entries = []
      const files = this.files[root] || []

      for (const fileName of files) {
        const path = pathUtils.join(root, fileName)
        entries.push({
          type: 'file',
          path: path,
          name: fileName,
          depth: depth
        })
      }

      return entries
    },
    /**
     * Perform background tasks such as checking whether upload jobs have
     * finished on the server-side.
     *
     * This will be called automatically every 30 seconds.
     */
    performBackgroundChecks: async function () {
      // Check if any of the extraction tasks have finished
      const tasks = Object.values(this.$options.store.backgroundTasksByPath)
      const pollPromises = []
      for (const task of tasks) {
        pollPromises.push(task.poll())
      }

      // This will launch every promise at once, practically sending one HTTP
      // request per promise. If users have lots of pending uploads at once,
      // we might want to throttle the HTTP requests using something like
      // `p-throttle`.
      await Promise.allSettled(pollPromises)

      // Every task has been checked, update and remove tasks that have
      // finished
      for (const [path, task] of Object.entries(this.$options.store.backgroundTasksByPath)) {
        if (!task.complete) {
          continue
        }

        await this.finishUploadTask(path, task)

        delete this.$options.store.backgroundTasksByPath[path]
      }

      // Check again in 30 seconds
      setTimeout(this.performBackgroundChecks, BACKGROUND_CHECK_INTERVAL)
    },
    /**
     * Clear the page state
     *
     * This takes care of tasks such as aborting background uploads that need
     * to be done before the page can be changed.
     */
    clear: async function () {
      // Terminate all tus uploads
      for (const tusUpload of Object.values(this.$options.store.tusUploadsByPath)) {
        await tusUpload.abort(true)
      }

      // Clear the "are you sure you want to leave the page" dialog
      window.onbeforeunload = undefined

      // Stop the periodic background check
      clearTimeout(this.$options.store.backgroundCheckTimeout)
    },
    isDirectoryOpen: function (path) {
      return _.includes(this.openedDirectories, path)
    },
    retrieveDirectory: async function (path) {
      const access = new FileStorageBackendAccess()
      const data = await access.getProjectDirectory(this.project.identifier, path)

      const directoryNames = data.directories
      const directories = directoryNames.map((name) => pathUtils.join(path, name))

      // Remove any extraction jobs that now have a corresponding directory
      const extractionDirNames = this.activeExtractionsByDir[path] || []
      this.$set(this.activeExtractionsByDir, path, _.without(extractionDirNames, ...directoryNames))

      // Add new directories
      this.directories = _.union(this.directories, directories)
      this.$set(this.files, path, data.files)
    },
    createDirectory: async function ({ path, newDirName } = {}) {
      const newPath = pathUtils.join(path, newDirName)
      const access = new FileStorageBackendAccess()
      try {
        // Try creating the directory
        await access.createDirectory(this.project.identifier, newPath)
      } catch (error) {
        if (error.response.status !== 409) {
          throw error
        }
        // Directory already exists, so ignore the error and open the directory
        // as usual.
      }

      this.directories.push(newPath)

      // Open the directory we created
      await this.toggleFolder(
        {
          isDir: true,
          id: newPath
        },
        true
      )
    },

    // Functions to run for user interactions
    toggleFolder: async function (storageItem) {
      const path = storageItem.id
      const isOpen = !storageItem.isOpen

      if (!storageItem.isDir) {
        // Clicking file entries does nothing
        return
      }

      if (isOpen) {
        if (_.includes(this.pendingDirectories, path)) {
          return
        }

        this.pendingDirectories.push(path)
        // Retrieve directory contents
        await this.retrieveDirectory(path)

        this.openedDirectories.push(path)
        this.pendingDirectories = _.without(this.pendingDirectories, path)
      } else {
        const basePath = pathUtils.ensureTrailingSlash(path)

        // Close the directory and subdirectories
        this.openedDirectories = this.openedDirectories.filter(
          (p) => !p.startsWith(basePath) && p !== path
        )

        // Remove subdirectories that were inside the closed directory
        this.directories = this.directories.filter((path) => !path.startsWith(basePath))
      }
    },
    openUploadFilesDialog: async function (entry) {
      await this.$refs.uploadFilesModal.openDialog(entry.path)
    },
    openUploadArchiveDialog: async function (entry) {
      await this.$refs.uploadArchiveModal.openDialog(entry.path)
    },
    openNewDirectoryDialog: async function (entry) {
      this.$refs.newDirectoryModal.openDialog(entry.path)
    },
    openDetailsDialog: async function (entry) {
      await this.$refs.detailsModal.openDialog(entry.path)
    },
    openDeleteDialog: async function (entry) {
      this.$refs.deleteModal.openDialog(entry.path, entry.type)
    },

    /**
     * Store the viewport location so it can be restored after the modal is
     * closed.
     */
    onModalOpened: function () {
      this.lastLocationY = window.scrollY
    },
    /**
     * Restore the viewwport location after closing the modal.
     */
    onModalClosed: function () {
      // HACK: This is a bit janky, and causes the window to "jerk" towards
      // the correct position instead of staying there in the first place.
      const lastLocationY = this.lastLocationY
      window.setTimeout(function () {
        window.scrollTo(0, lastLocationY)
      }, 0)
    },
    enqueueArchiveToUpload: async function (
      path,
      { file, extractDir, checksum = null, checksumAlgorithm = null } = {}
    ) {
      this.enqueueFileToUpload({
        dir: path,
        file: file,
        extractIntoDir: extractDir,
        checksumAlgorithm: checksumAlgorithm,
        checksum: checksum
      })

      await this.launchUploads()
    },
    enqueueFilesToUpload: async function (path, entries) {
      for (const entry of entries) {
        this.enqueueFileToUpload({
          dir: path,
          file: entry.file,
          checksumAlgorithm: entry.checksumAlgorithm,
          checksum: entry.checksum
        })
      }

      // Launch new uploads unless the maximum amount of concurrent uploads
      // has been exceeded
      await this.launchUploads()
    },
    /**
     * Enqueue a file to be uploaded under `dir`.
     *
     * If `extractIntoDir` is provided, extract the archive under the given
     * directory name under `dir` after the upload is finished.
     */
    enqueueFileToUpload: function ({
      dir,
      file,
      extractIntoDir = null,
      checksumAlgorithm = null,
      checksum = null
    } = {}) {
      if (!(dir in this.pendingUploadsByDir)) {
        this.$set(this.pendingUploadsByDir, dir, [])
      }

      const directoryUploads = this.pendingUploadsByDir[dir]

      // Check if the file is already being uploaded.
      // If it is, drop the upload silently to prevent issues.
      if (directoryUploads.find((upload) => upload.name === file.name)) {
        // Upload with same name is already active, skip this
        return
      }

      const entry = {
        dir: dir,
        name: file.name,
        bytesUploaded: 0,
        bytesTotal: file.size,
        checksumAlgorithm: checksumAlgorithm,
        checksum: checksum
      }

      if (extractIntoDir) {
        // Archive upload
        entry.uploadPath = pathUtils.join(dir, extractIntoDir)
        entry.type = 'archive'
        entry.extractDirName = extractIntoDir
      } else {
        // File upload
        entry.uploadPath = pathUtils.join(dir, file.name)
        entry.type = 'file'
      }

      directoryUploads.push(entry)

      this.$options.store.filesByPath[entry.uploadPath] = file
    },
    launchUploads: async function () {
      while (this.activeUploads.length < MAXIMUM_CONCURRENT_UPLOADS) {
        if (this.pendingUploads.length === 0) {
          // We've enqueued all uploads we can
          break
        }

        const newUpload = this.pendingUploads.at(-1)
        await this.launchUpload(newUpload)
      }
    },
    launchUpload: async function (entry) {
      const access = new BackendAccess()
      const uploadUrl = `${access.uploadBasePath}/files_tus`

      let uploadPath

      if (entry.type === 'file') {
        uploadPath = pathUtils.ensureLeadingSlash(pathUtils.join(entry.dir, entry.name))
      } else if (entry.type === 'archive') {
        uploadPath = pathUtils.ensureLeadingSlash(pathUtils.join(entry.dir, entry.extractDirName))
      }

      const file = this.$options.store.filesByPath[uploadPath]

      const metadata = {
        project_id: this.project.identifier,
        filename: file.name, // flask-tus-io requires this
        upload_path: pathUtils.stripLeadingSlash(uploadPath),
        create_metadata: 'true',

        // These properties are not handled server-side at all, but it
        // makes things a bit easier on our side
        dir: entry.dir,
        name: file.name
      }

      if (entry.checksum !== null) {
        metadata.checksum = `${entry.checksumAlgorithm}:${entry.checksum}`
      }

      if (entry.type === 'archive') {
        metadata.type = 'archive'
      } else {
        metadata.type = 'file'
      }

      const self = this
      // TODO: We can use `tus.Upload#findPreviousUploads` to see if the user
      // started an upload previously and continue from it after asking
      // the user.
      const tusUpload = new tus.Upload(file, {
        chunkSize: UPLOAD_CHUNK_SIZE,
        endpoint: uploadUrl,
        metadata: metadata,
        storeFingerprintForResuming: false,
        onBeforeRequest: function (req) {
          // Set the token before each request. We use this function
          // instead of `Upload.headers` because the token is rotated
          // periodically
          const xhr = req.getUnderlyingObject()
          const sessionToken = getters.getSessionToken()
          xhr.withCredentials = true
          xhr.setRequestHeader('Authorization', `Bearer ${sessionToken}`)
        },
        retryDelays: [0, 3000],
        onError: async (error) => {
          await self.onUploadError(tusUpload, error)
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          self.onUploadProgress(tusUpload, bytesUploaded, bytesTotal)
        },
        onSuccess: async () => {
          await self.onUploadSuccess(tusUpload)
        }
      })

      if (!(entry.dir in this.activeUploadsByDir)) {
        this.$set(this.activeUploadsByDir, entry.dir, [])
      }

      const dir = entry.dir

      // Push the new active upload
      this.activeUploadsByDir[entry.dir].push({
        uploadPath: uploadPath,
        dir: dir,
        extractDirName: entry.extractDirName || null,
        name: entry.name,
        bytesUploaded: 0,
        bytesTotal: file.size
      })
      this.$options.store.tusUploadsByPath[uploadPath] = tusUpload

      // Remove the pending upload
      this.$set(
        this.pendingUploadsByDir,
        dir,
        this.pendingUploadsByDir[dir].filter((entry) => entry.uploadPath !== uploadPath)
      )

      tusUpload.start()
    },

    removeUpload: function (tusUpload) {
      const normUploadPath = pathUtils.ensureLeadingSlash(tusUpload.options.metadata.upload_path)
      delete this.$options.store.tusUploadsByPath[normUploadPath]
      delete this.$options.store.filesByPath[normUploadPath]
      const dir = tusUpload.options.metadata.dir
      if (this.activeUploadsByDir[dir]) {
        this.$set(
          this.activeUploadsByDir,
          dir,
          this.activeUploadsByDir[dir].filter((entry) => entry.uploadPath !== normUploadPath)
        )
        this.$set(
          this.pendingUploadsByDir,
          dir,
          this.pendingUploadsByDir[dir].filter((entry) => entry.uploadPath !== normUploadPath)
        )
      }
    },

    // Handlers for tus uploads
    onUploadError: async function (tusUpload, error) {
      // Display an error and remove the upload from list
      const title = this.$pgettext('Toast notification', 'Upload failed!')
      const messageTmp = this.$pgettext(
        'Toast text',
        'Failed to upload file %{ path }. Error: %{ error }'
      )
      const responseStatus = error.originalResponse.getStatus()
      let responseErrorMsg = ''
      try {
        // TODO: This error is passed as is from the response and is not
        // localized
        responseErrorMsg = JSON.parse(error.originalResponse.getBody()).error
      } catch (error) {
        responseErrorMsg = this.$pgettext('Toast text', 'Error message not available')
      }

      const message = this.$gettextInterpolate(messageTmp, {
        path: tusUpload.options.metadata.upload_path,
        error: `${responseStatus} - ${responseErrorMsg}`
      })
      this.$bvToast.toast(message, {
        title: title,
        noAutoHide: true,
        variant: 'danger'
      })

      this.removeUpload(tusUpload)
      await this.launchUploads()
    },
    onUploadProgress: function (tusUpload, bytesUploaded, bytesTotal) {
      // Update the progress
      const activeUpload = this.activeUploads.find((entry) => {
        return (
          entry.dir === tusUpload.options.metadata.dir &&
          entry.name === tusUpload.options.metadata.name
        )
      })

      if (activeUpload === undefined) {
        // Upload finished and was already removed
        return
      }

      activeUpload.bytesUploaded = bytesUploaded
    },
    /**
     * Mark the file upload as finished (if upload is small enough and
     * finishes instantly)
     * OR track the file background task (if uploaded file is large enough
     * and is finished in a background task)
     */
    trackFileTask: async function (tusUpload) {
      const dir = tusUpload.options.metadata.dir
      const name = tusUpload.options.metadata.filename

      // Retrieve the upload entry before we remove it; we need to know
      // the size of the uploaded file
      const upload = _.find(
        this.activeUploadsByDir[dir],
        (entry) => entry.dir === dir && entry.name === name
      )
      const uploadSize = upload.bytesTotal

      this.removeUpload(tusUpload)

      const projectIdentifier = tusUpload.options.metadata.project_id
      const fileIsInCurrentlyOpenProject = projectIdentifier === this.project.identifier

      const hasBackgroundTask = uploadSize >= this.uploadAsyncThresholdBytes

      if (hasBackgroundTask) {
        // Upload is large: track the background task to completion
        if (!(dir in this.activeFileTasksByDir)) {
          this.$set(this.activeFileTasksByDir, dir, [])
        }

        this.activeFileTasksByDir[dir].push(name)

        // Add background task that will be tracked automatically
        const task = getTaskFromTusUpload(tusUpload)
        this.$options.store.backgroundTasksByPath[task.path] = task
      } else {
        // Upload was small: the upload is complete immediately
        // We could perform an actual API call for below updates,
        // but this could cause a lot of traffic if the user is uploading a lot  of small files.
        if (fileIsInCurrentlyOpenProject) {
          const dir = tusUpload.options.metadata.dir

          // Update the directory listing.
          this.files[dir].push(tusUpload.options.metadata.name)
          this.$set(this.files, dir, this.files[dir].sort())
        }

        const title = this.$pgettext('Toast notification', 'Upload succeeded!')
        const messageTmp = this.$pgettext('Toast text', 'File %{ path } was uploaded successfully')
        const message = this.$gettextInterpolate(messageTmp, {
          path: tusUpload.options.metadata.upload_path
        })
        this.$bvToast.toast(message, {
          title: title,
          autoHideDelay: 5000,
          variant: 'success'
        })

        // Update project used quota locally
        const newUsedQuota = this.project.usedQuota + uploadSize
        this.$emit('update-project-quota', projectIdentifier, { usedQuota: newUsedQuota })
      }
    },
    /*
     * Start tracking a finished archive upload background task
     */
    trackArchiveTask: async function (tusUpload) {
      const title = this.$pgettext('Toast notification', 'Extraction started')
      const messageTmp = this.$pgettext(
        'Toast text',
        'Archive %{ name } was uploaded successfully. Extraction to %{ path } will be started in the background.'
      )
      const message = this.$gettextInterpolate(messageTmp, {
        name: tusUpload.options.metadata.filename,
        path: tusUpload.options.metadata.upload_path
      })
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 10000,
        variant: 'success'
      })
      this.removeUpload(tusUpload)

      const dir = tusUpload.options.metadata.dir
      const extractDirName = pathUtils.split(tusUpload.options.metadata.upload_path)[1]

      if (!(dir in this.activeExtractionsByDir)) {
        this.$set(this.activeExtractionsByDir, dir, [])
      }

      this.activeExtractionsByDir[dir].push(extractDirName)

      // Add background task that will be tracked automatically
      const task = getTaskFromTusUpload(tusUpload)
      this.$options.store.backgroundTasksByPath[task.path] = task
    },
    finishUploadTask: async function (path, task) {
      const type = task.type
      const name = task.name
      const parentDir = task.dir
      let extractDirName

      if (type === 'archive') {
        extractDirName = pathUtils.split(task.path)[1]
      }

      let title
      let message
      if (task.status === TaskStatus.DONE) {
        title = this.$pgettext('Toast notification', 'Upload succeeded!')
        let messageTmp
        if (type === 'file') {
          messageTmp = this.$pgettext('Toast text', 'File %{ path } was uploaded successfully')
        } else if (type === 'archive') {
          messageTmp = this.$pgettext(
            'Toast text',
            'Archive %{ name } was extracted successfully to %{ path }.'
          )
        }
        message = this.$gettextInterpolate(messageTmp, { name: task.name, path: task.path })
      } else if (task.status === TaskStatus.ERROR) {
        title = this.$pgettext('Toast noficiation', 'Upload failed!')
        let messageTmp
        if (type === 'file') {
          messageTmp = this.$pgettext(
            'Toast text',
            'Failed to upload file %{ path }. Error: %{ error }'
          )
        } else if (type === 'archive') {
          messageTmp = this.$pgettext(
            'Toast text',
            'Archive %{ name } extraction to %{ path } failed! Error: %{ error }'
          )
        }
        message = this.$gettextInterpolate(messageTmp, {
          name: task.name,
          path: task.path,
          error: task.error
        })
      }

      if (task.status === TaskStatus.DONE) {
        this.$bvToast.toast(message, {
          title: title,
          autoHideDelay: 10000,
          variant: 'success'
        })

        const dirIsInCurrentlyOpenProject = this.project.identifier === task.project
        if (dirIsInCurrentlyOpenProject) {
          // Add the directory/file created after upload
          if (type === 'file') {
            this.files[parentDir].push(name)
            this.$set(this.files, parentDir, this.files[parentDir].sort())
          } else if (type === 'archive') {
            this.directories.push(pathUtils.join(parentDir, extractDirName))
          }
        }

        this.$emit('update-project-quota', task.project)
      } else {
        this.$bvToast.toast(message, {
          title: title,
          noAutoHide: true,
          variant: 'danger'
        })
      }

      // Remove the active extraction
      if (type === 'archive') {
        this.$set(
          this.activeExtractionsByDir,
          parentDir,
          this.activeExtractionsByDir[parentDir].filter((dirName) => dirName !== extractDirName)
        )
      } else if (type === 'file') {
        this.$set(
          this.activeFileTasksByDir,
          parentDir,
          this.activeExtractionsByDir[parentDir].filter((name_) => name_ !== name)
        )
      }
    },
    onUploadSuccess: async function (tusUpload) {
      const uploadType = tusUpload.options.metadata.type

      switch (uploadType) {
        case 'file':
          await this.trackFileTask(tusUpload)
          break

        case 'archive':
          // Finished archive upload starts a background task.
          // Track that until it's finished.
          await this.trackArchiveTask(tusUpload)
          break
      }

      await this.launchUploads()
    },
    deletePath: async function (path, type) {
      const access = new FileStorageBackendAccess()
      try {
        await access.deletePath(this.project.identifier, path)
        this.onDeleteSuccess(path, type)
      } catch (error) {
        this.onDeleteError(error, path)
      }
    },
    onDeleteSuccess: async function (path, type) {
      const title = this.$pgettext('Toast notification', 'Delete succeeded!')
      const messageTmp = this.$pgettext('Toast text', 'Path %{ path } was deleted successfully')
      const message = this.$gettextInterpolate(messageTmp, { path: path })
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 5000,
        variant: 'success'
      })

      // Update the directory listing
      if (type === 'dir') {
        // Remove the deleted directory and its subdirectories from relevant
        // data properties
        const basePath = pathUtils.ensureTrailingSlash(path)
        this.directories = this.directories.filter(
          (directory) => directory !== path && !directory.startsWith(basePath)
        )
        this.openedDirectories = this.openedDirectories.filter(
          (directory) => directory !== path && !directory.startsWith(basePath)
        )
        this.files = _.pickBy(this.files, (val, key) => key !== path && !key.startsWith(basePath))
      } else if (type === 'file') {
        // Remove deleted file from this.files
        const pathElements = pathUtils.split(path)
        const directory = pathElements[0]
        const filename = pathElements[1]
        this.files[directory] = _.without(this.files[directory], filename)
      }
      this.$emit('update-project-quota')
    },
    onDeleteError: async function (error, path) {
      // Display an error and remove the upload from list
      const title = this.$pgettext('Toast notification', 'Delete failed!')
      const messageTmp = this.$pgettext(
        'Toast text',
        'Failed to delete path %{ path }. Error: %{ error }'
      )
      const responseStatus = error.response.status
      let responseErrorMsg = ''
      try {
        // TODO: This error is passed as is from the response and is not
        // localized
        responseErrorMsg = error.response.data.error
      } catch (error) {
        responseErrorMsg = this.$pgettext('Toast text', 'Error message not available')
      }

      const message = this.$gettextInterpolate(messageTmp, {
        path: path,
        error: `${responseStatus} - ${responseErrorMsg}`
      })
      this.$bvToast.toast(message, {
        title: title,
        noAutoHide: true,
        variant: 'danger'
      })
    }
  }
}
</script>

<template>
  <div data-test="file-browser">
    <file-storage-entries
      :entries="entries"
      :pending-directories="pendingDirectories"
      @toggle-storage-item-folder="toggleFolder"
      @upload-files-selected="openUploadFilesDialog"
      @upload-archive-selected="openUploadArchiveDialog"
      @new-directory-selected="openNewDirectoryDialog"
      @details-selected="openDetailsDialog"
      @delete-selected="openDeleteDialog"
    />
    <file-storage-upload-files-modal
      ref="uploadFilesModal"
      :files="files"
      :project="project"
      :file-max-size="fileMaxSize"
      @files-submitted="enqueueFilesToUpload"
      @show="onModalOpened"
      @hidden="onModalClosed"
      @update-project-quota="$emit('update-project-quota')"
    />
    <file-storage-upload-archive-modal
      ref="uploadArchiveModal"
      :project="project"
      :archive-max-size="fileMaxSize"
      @archive-submitted="enqueueArchiveToUpload"
      @show="onModalOpened"
      @hidden="onModalClosed"
      @update-project-quota="$emit('update-project-quota')"
    />
    <file-storage-new-directory-modal
      ref="newDirectoryModal"
      :project="project.identifier"
      @new-directory-selected="createDirectory"
      @show="onModalOpened"
      @hidden="onModalClosed"
    />
    <file-storage-details-modal
      ref="detailsModal"
      :project="project.identifier"
      @show="onModalOpened"
      @hidden="onModalClosed"
    />
    <file-storage-delete-modal
      ref="deleteModal"
      :project="project.identifier"
      @delete-selected="deletePath"
      @show="onModalOpened"
      @hidden="onModalClosed"
    />
  </div>
</template>
