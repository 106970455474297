<script>
import { States } from '@/services/DatasetStates'
import DatasetStateLabelClassService from '@/services/DatasetStateLabelClassService'
import DatasetReports from '@/views/DatasetPage/DatasetReports'
import Download from '@/views/DatasetPage/Download'

export default {
  components: {
    DatasetReports,
    Download
  },
  props: {
    dataset: {
      type: Object,
      required: true
    },
    datasetStateChanging: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    stages() {
      const pasState = this.dataset.passtate
      return [
        {
          inProgress: {
            text: this.$pgettext('verb', 'packaging'),
            // Note that States.IN_PACKAGING_SERVICE would be more logical here
            isActive: pasState === States.ACCEPTED_TO_DIGITAL_PRESERVATION
          },
          done: {
            text: this.$gettext('packaged'),
            isActive: pasState >= States.SIP_SENT_TO_INGESTION_IN_DPRES_SERVICE
          },
          fail: {
            text: this.$gettext('packaging failed'),
            isActive: pasState === States.PACKAGING_FAILED
          }
        },
        {
          inProgress: {
            text: this.$gettext('validating'),
            isActive: pasState === States.SIP_SENT_TO_INGESTION_IN_DPRES_SERVICE
          },
          done: {
            text: this.$gettext('validated'),
            isActive:
              pasState === States.IN_DIGITAL_PRESERVATION || pasState === States.IN_DISSEMINATION
          },
          fail: {
            text: this.$gettext('validation failed'),
            isActive: pasState === States.REJECTED_IN_DIGITAL_PRESERVATION_SERVICE
          }
        }
      ]
    },
    statusText() {
      const description = this.dataset.passtateDescription
      const time = this.$options.filters.formatDate(this.dataset.passtateModified)
      return `${description} ${time}`
    },
    statusTextClass() {
      return DatasetStateLabelClassService.getTextClass(this.dataset.passtate)
    },
    canDownloadReports() {
      return this.dataset.passtate >= States.SIP_SENT_TO_INGESTION_IN_DPRES_SERVICE
    },
    canDownloadDIP() {
      return [States.IN_DIGITAL_PRESERVATION, States.IN_DISSEMINATION].includes(
        this.dataset.passtate
      )
    }
  },

  methods: {
    stageText(stage) {
      const item = Object.values(stage).find((item) => item.isActive)
      return item ? item.text : ' '
    }
  }
}
</script>

<template>
  <div class="status-container">
    <hr />
    <h2 :class="statusTextClass">
      {{ statusText }}
    </h2>
    <div class="stages">
      <div v-for="(stage, i) in stages" :key="stage.inProgress.text" class="stage">
        <div
          :class="[
            'indicator-container',
            i === 0 ? 'indicator-container-left' : 'indicator-container-right'
          ]"
        >
          <b-spinner
            v-if="stage.inProgress.isActive"
            class="text-success state-indicator"
            data-test="in-progress"
          />
          <b-icon
            v-else-if="stage.fail.isActive"
            icon="x-circle"
            class="text-danger state-indicator"
            data-test="fail"
          />
          <b-icon
            v-else-if="stage.done.isActive"
            icon="check-circle"
            class="text-success state-indicator"
            data-test="done"
          />
          <b-icon v-else icon="circle" class="text-light state-indicator" data-test="not-started" />
        </div>
        <span class="stage-text">{{ stageText(stage) }}</span>
      </div>
      <span :class="['bar', stages[0].done.isActive ? 'bg-success' : 'bg-light']"> </span>
    </div>
    <dataset-reports v-if="canDownloadReports" :dataset="dataset" />
    <download
      v-if="canDownloadDIP"
      :dataset="dataset"
      :dataset-state-changing="datasetStateChanging"
      @start-dataset-state-change="$emit('start-dataset-state-change')"
      @finish-dataset-state-change="$emit('finish-dataset-state-change')"
    />
  </div>
</template>

<style scoped lang="scss">
@use '/src/assets/css/app';
h2 {
  font-size: 1rem;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
}
.status-container {
  margin-bottom: 4rem;
}

.state-indicator {
  height: 2rem;
  width: 2rem;
}
.indicator-container {
  background-color: app.$white;
  z-index: 2;
}
.indicator-container-left {
  padding-left: 100%;
  margin-right: 100%;
}
.indicator-container-right {
  padding-right: 100%;
  margin-left: 100%;
}
.stage-text {
  height: 1.5rem;
}
.stage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stages {
  display: flex;
  position: relative;
  width: fit-content;
  gap: 4rem;
}
$bar-width: 0.25rem;
.bar {
  position: absolute;
  width: 100%;
  height: $bar-width;
  top: 1rem - calc($bar-width / 2);
}
</style>
