<script>
import DatasetStateSelect from '@/views/DatasetsPage/DatasetStateSelect.vue'
import DatasetsPerPage from '@/views/DatasetsPage/DatasetsPerPage.vue'
import { States } from '@/services/DatasetStates'

export default {
  components: {
    DatasetStateSelect,
    DatasetsPerPage
  },
  props: {
    datasetsPerPageDefault: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      datasetState: States.ALL_STATES.toString(),
      perPage: this.datasetsPerPageDefault
    }
  },
  watch: {
    perPage() {
      this.searchValuesChanged()
    },
    datasetState() {
      this.searchValuesChanged()
    }
  },
  methods: {
    searchValuesChanged() {
      this.$emit('search-values-changed', {
        perPage: this.perPage,
        datasetState: this.datasetState
      })
    }
  }
}
</script>
<template>
  <div id="dataset-filter-form">
    <dataset-state-select @dataset-state-changed="datasetState = $event" />
    <datasets-per-page
      :datasets-per-page-default="datasetsPerPageDefault"
      @datasets-per-page-changed="perPage = $event"
    />
  </div>
</template>

<style>
#dataset-filter-form {
  display: flex;
  flex-wrap: wrap;
  max-width: fit-content;
  gap: 1.5rem;
  padding: 2rem 12.5rem 2rem 12.5rem;
}
#dataset-filter-form select {
  max-width: fit-content;
  min-width: 8rem;
}

#dataset-filter-form .form-group > div {
  display: flex;
  flex-direction: column;
}
</style>
