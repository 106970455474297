<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    rows: {
      type: Number,
      required: true
    },
    itemsAmount: {
      type: Number,
      required: true
    },
    input: {
      type: Function,
      required: true
    },
    ariaControls: {
      type: String,
      required: true
    }
  },
  computed: {
    visilibityClass() {
      const itemsExist = this.itemsAmount > 0
      const moreThanOnePage = this.itemsAmount < this.rows
      // This is done instead of v-if, because we still want the margin of the pagination, even when it's hidden
      return itemsExist && moreThanOnePage ? 'visible' : 'invisible'
    }
  }
}
</script>
<template>
  <b-pagination
    :value="currentPage"
    limit="6"
    :total-rows="rows"
    :per-page="perPage"
    :aria-controls="ariaControls"
    first-number
    last-number
    pills
    next-text=">"
    prev-text="<"
    :class="`d-flex justify-content-end my-5 d-none ${visilibityClass}`"
    @change="(page) => $emit('update:currentPage', page)"
    @input="input"
  />
</template>
