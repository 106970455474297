<script>
import { getters } from '@/common/store.js'
export default {
  computed: {
    isBusy() {
      const ret = getters.isHttpOperationPending()
      return ret
    }
  }
}
</script>

<template>
  <div v-if="isBusy" id="busy-spinner">
    <b-spinner variant="primary" small />
  </div>
</template>

<style scoped>
#busy-spinner {
  display: block;
  position: fixed;
  z-index: 10;
  top: 20px;
  right: 20px;
}
</style>
