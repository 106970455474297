<script>
import ResetDataset from './ResetDataset'
import ButtonWithArrow from '@/common/ButtonWithArrow'
import DatasetData from '@/views/DatasetPage/DatasetData'
import DatasetStateService from '@/services/DatasetStateService'
import DatasetBackendAccess from '@/services/DatasetBackendAccess'

export default {
  name: 'ProposeDataset',
  components: {
    ResetDataset,
    ButtonWithArrow,
    DatasetData
  },
  props: {
    dataset: {
      type: Object,
      required: true
    },
    datasetStateChanging: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      metadataCheckboxChecked: false,
      proposeJustification: '',
      buttonIsLoading: false
    }
  },
  computed: {
    justificationTooLong() {
      return this.proposeJustification.length > 200
    },
    isProposeButtonEnabled() {
      return (
        this.metadataCheckboxChecked &&
        this.dataset.rights.propose &&
        !this.justificationTooLong &&
        DatasetStateService.canBeProposed(this.dataset.passtate)
      )
    },
    proposeCanBeSkipped() {
      return this.dataset.rights.approve && this.dataset.rights.propose
    },
    hasNextButton() {
      return DatasetStateService.isAcceptTabEnabled(this.dataset.passtate)
    },
    shouldShowSubmittedValues() {
      return DatasetStateService.shouldShowProposePageAsDone(this.dataset.passtate)
    }
  },
  mounted() {
    if (this.shouldShowSubmittedValues) {
      this.metadataCheckboxChecked = true
      this.proposeJustification = this.dataset.passtateReasonDesc
    }
  },
  methods: {
    async handleProposeDataset() {
      this.buttonIsLoading = true
      this.$emit('start-dataset-state-change')
      try {
        const access = new DatasetBackendAccess()
        await access.proposeDataset(this.dataset.identifier, this.proposeJustification)
      } catch (e) {
        this.$emit('finish-dataset-state-change')
        throw e
      }
      this.$emit('update-dataset')
    },
    async handleConfirmDataset() {
      this.buttonIsLoading = true
      this.$emit('start-dataset-state-change')
      try {
        const access = new DatasetBackendAccess()
        await access.confirmDataset(this.dataset.identifier, this.proposeJustification)
      } catch (e) {
        this.$emit('finish-dataset-state-change')
        throw e
      }
      this.$emit('update-dataset')
    },
    updateDataset() {
      this.$emit('update-dataset')
    }
  }
}
</script>

<template>
  <div>
    <hr />
    <h2 v-translate>Check metadata</h2>
    <p>
      {{
        $gettext(
          "Please check that the following metadata is correct. Fairdata preservation service can't check the correctness of required metadata at this time."
        )
      }}
    </p>
    <div class="check-list">
      <h3>{{ $gettext('Files') }}</h3>
      <div class="d-flex">
        <ul>
          <li v-translate>Use category</li>
          <li v-translate>File format</li>
          <li v-translate>File format version</li>
          <li v-translate>Text encoding for text files</li>
          <li v-translate>Technical metadata for text, audio, video and image files</li>
        </ul>
        <ul class="csv-list">
          <li v-translate>When the CSV file is preserved in CSV format instead of text</li>
          <ul class="csv-sublist">
            <li>{{ $gettext('File format should be') }} <code>text/csv</code></li>
            <li>{{ $gettext('Set delimiter character for fields, e.g.') }} <code>;</code></li>
            <li>
              {{ $gettext('Set line terminator character for records, e.g.') }} <code>CR+LF</code>
            </li>
            <li>{{ $gettext('Set quote character for text entries, e.g.') }} <code>"</code></li>
            <li v-translate>Report whether the CSV file has a header</li>
          </ul>
        </ul>
      </div>
    </div>
    <dataset-data :dataset="dataset" />
    <div id="metadata-checked-box">
      <b-form-checkbox
        v-model="metadataCheckboxChecked"
        name="confirm-metadata-checked"
        :disabled="!dataset.rights.propose || shouldShowSubmittedValues"
      >
        <span>
          <b><translate>I have checked the metadata</translate></b>
        </span>
      </b-form-checkbox>
    </div>
    <b-form-group
      :label="$gettext('Justification (optional)')"
      label-for="justification"
      label-class="font-weight-bold mb-3"
      class="justification-group"
    >
      <b-form-textarea
        id="justification"
        v-model="proposeJustification"
        rows="4"
        :state="justificationTooLong ? false : null"
        :placeholder="$gettext('Describe here why the dataset is being submitted to preservation')"
        :disabled="shouldShowSubmittedValues"
      />
      <p :class="[{ 'text-danger': justificationTooLong }, 'justification-length']">
        {{ proposeJustification.length }} / 200 {{ $pgettext('letters', 'characters') }}
      </p>
    </b-form-group>
    <hr />
    <div class="bottom-buttons">
      <div class="button-container">
        <button-with-arrow
          :text="$gettext('Previous')"
          :disabled="datasetStateChanging"
          left
          variant="outline-primary"
          @click="$emit('previous-page')"
        />
        <reset-dataset
          id="reset-button"
          :dataset="dataset"
          :dataset-state-changing="datasetStateChanging"
          @reset-dataset="updateDataset"
          @start-dataset-state-change="$emit('start-dataset-state-change')"
          @finish-dataset-state-change="$emit('start-dataset-state-change')"
        />
      </div>
      <div class="button-container">
        <button-with-arrow
          v-if="!proposeCanBeSkipped"
          :text="$gettext('Propose for preservation')"
          :disabled="!isProposeButtonEnabled || datasetStateChanging"
          :is-loading="buttonIsLoading"
          data-test="propose-for-preservation"
          @click="handleProposeDataset"
        />
        <button-with-arrow
          v-if="proposeCanBeSkipped"
          :text="$gettext('Confirm metadata')"
          :disabled="!isProposeButtonEnabled || datasetStateChanging"
          :is-loading="buttonIsLoading"
          data-test="confirm-dataset"
          @click="handleConfirmDataset"
        />
        <button-with-arrow
          v-if="hasNextButton"
          :text="$gettext('Next')"
          variant="outline-primary"
          @click="$emit('next-page')"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '/src/assets/css/app';
.justification-length {
  text-align: end;
  margin-top: 0.5rem;
}
.justification-group {
  margin-bottom: 3.25rem;
  max-width: 49rem;
}
#metadata-checked-box {
  margin: 2.5rem 0 2rem;
}
#input-propose-reason {
  margin-bottom: 1em;
}
h2 {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}
h3 {
  margin-bottom: 0.5rem;
}
li {
  list-style-type: disc;
  list-style-position: inside;
  line-height: 2rem;
}
code {
  line-height: 1rem;
}

.csv-sublist {
  margin-left: 2rem;
}
.csv-list {
  margin-left: 6rem;
}
.check-list {
  padding: 2rem;
  background-color: app.$green-light;
  margin-top: 1.5rem;
}
.bottom-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2.375rem;
  margin-bottom: 2.5rem;
}
</style>
