<script>
// @ is an alias to /src
import { getters } from '@/common/store.js'
import AppHeader from '@/layout/AppHeader'
import AppFooter from '@/layout/AppFooter'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter
  },
  computed: {
    config() {
      return getters.getBackendConfiguration()
    }
  },
  mounted() {
    // User authentication is done in router navigation guard, for authentication see router files

    if (!this.config) {
      // Backend configuration does not exist, so trying to do anything is pointless
      return
    }

    this.cleanTusEntries()

    this.addSSOCookieNotification()
  },
  methods: {
    addSSOCookieNotification() {
      // More info in https://sso.fd-stable.csc.fi/swagger
      const cookieNotificationLink = document.createElement('link')
      cookieNotificationLink.setAttribute('rel', 'stylesheet')
      cookieNotificationLink.setAttribute('href', `${this.config.ssoApiUrl}/notification.css`)
      document.head.appendChild(cookieNotificationLink)
      const cookieNotificationScript = document.createElement('script')
      cookieNotificationScript.setAttribute('type', 'text/javascript')
      cookieNotificationScript.setAttribute('src', `${this.config.ssoApiUrl}/notification.js`)
      document.head.appendChild(cookieNotificationScript)
    },

    /**
     * Delete unused tus fingerprints. These are no longer created by default,
     * and were never used in the first place for the intended purpose
     * (continuing unfinished uploads).
     */
    cleanTusEntries() {
      const keysToDelete = Object.keys(window.localStorage).filter((key) => key.startsWith('tus::'))

      for (const key of keysToDelete) {
        window.localStorage.removeItem(key)
      }
    }
  }
}
</script>

<template>
  <div>
    <b-alert v-if="!config" show variant="danger">
      <div v-translate class="text-center">Failed to resolve backend configuration</div>
    </b-alert>
    <div id="main-container" class="d-flex flex-column">
      <AppHeader />
      <keep-alive include="AgreementsPage,DatasetsPage,FileStorageBrowserPage">
        <router-view :key="$route.fullPath" />
      </keep-alive>
      <AppFooter />
    </div>
  </div>
</template>
