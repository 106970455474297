<script>
export default {
  props: {
    storageItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    fileCharacteristicFields() {
      // https://github.com/CSCfi/fairdata-metax/blob/master/src/metax_api/api/rest/v2/schemas/file_schema.json#L304
      return [
        ['file_format', this.$pgettext('File field', 'File format')],
        ['format_version', this.$pgettext('File field', 'File format version')],
        ['encoding', this.$pgettext('File field', 'Encoding')],
        [
          'open_access',
          this.$pgettext('File field', 'Open access'),
          (x) => (x ? this.$gettext('Yes') : this.$gettext('No'))
        ],
        ['title', this.$pgettext('File field', 'Title')],
        ['description', this.$pgettext('File field', 'Description')],
        ['application_name', this.$pgettext('File field', 'Application name')],
        ['csv_delimiter', this.$pgettext('File field', 'CSV delimiter')],
        ['csv_record_separator', this.$pgettext('File field', 'CSV record separator')],
        ['csv_quoting_char', this.$pgettext('File field', 'CSV quoting character')],
        [
          'csv_has_header',
          this.$pgettext('File field', 'Has CSV header'),
          (x) => (x ? this.$gettext('Yes') : this.$gettext('No'))
        ]
      ]
    },
    metadataEntries() {
      const metadata = this.storageItem.metadata
      if (!metadata) {
        return []
      }

      const fileCharacteristics = JSON.parse(JSON.stringify(metadata.fileCharacteristics))

      const byteSize = metadata.fileSize
      const humanSize = this.$options.filters.formatBytes(metadata.fileSize)

      const entries = [[this.$pgettext('File field', 'Identifier'), metadata.identifier]]

      // File storage
      if (metadata.fileStorage) {
        let fileStorage = this.$pgettext('File storage', 'Unknown storage')
        switch (metadata.fileStorage.identifier) {
          case 'urn:nbn:fi:att:file-storage-pas':
            fileStorage = this.$pgettext('File storage', 'Pre-Ingest File Storage')
            break
          case 'urn:nbn:fi:att:file-storage-ida':
            fileStorage = this.$pgettext('File storage', 'Fairdata IDA')
            break
        }

        entries.push([this.$pgettext('File field', 'File source'), fileStorage])
      }

      entries.push(
        [
          this.$pgettext('File field', 'File size'),
          this.$gettextInterpolate(
            this.$ngettext(
              '%{ humanSize } (%{ byteSize } byte)',
              '%{ humanSize } (%{ byteSize } bytes)',
              byteSize
            ),
            { humanSize, byteSize }
          )
        ],
        [this.$pgettext('File field date', 'Uploaded'), metadata.fileUploaded]
      )

      // There are three modification timestamps in Metax:
      // * `file.modified` in Metax V2 / `file.record_modified` in V3.
      //   Updated whenever there is any update to the database table.
      //
      // * `file.file_modified` in Metax V2 / `file.modified` in V3.
      //   Updated whenever the file is updated in IDA. Not used in upload-rest-api
      //   since we don't support file updates.
      //
      // * `file.file_characteristics.metadata_modified`.
      //   This is the optional modification timestamp embedded in the file metadata
      //   (eg. EXIF field in image saved by Photoshop or a digital camera)
      //
      // `file.file_modified` probably best matches user expectations here,
      // so use it here. Displaying all three would probably introduce more
      // confusion than it's worth.
      if (!metadata.fileUploaded.isSame(metadata.fileModified)) {
        entries.push([this.$pgettext('File field date', 'Modified'), metadata.fileModified])
      }

      const checksum = metadata.checksum

      if (checksum) {
        if (checksum.algorithm && checksum.value) {
          entries.push(
            [this.$pgettext('File field', 'Checksum algorithm'), checksum.algorithm],
            [this.$pgettext('File field', 'Checksum'), checksum.value]
          )
        }
      }

      for (const [field, name, convertFunc = String] of this.fileCharacteristicFields) {
        if (field in fileCharacteristics) {
          // Some fields (eg. booleans) are converted to a string using a custom
          // function. Convert to string directly if no function is provided.
          const value = convertFunc(fileCharacteristics[field])

          entries.push([name, value])
        }
      }

      // TODO: There's also `fileCharacteristicsExtension`, which can contain
      // any kind of arbitrary object, even a nested one.
      // We currently use it store the list of streams scraped by file-scraper,
      // but should we display it here somehow? Just dump it as JSON?

      return entries
    }
  }
}
</script>
<template>
  <div v-if="storageItem.metadataOpen" class="metadata-container">
    <dl v-if="metadataEntries.length > 0" class="metadata-list">
      <template v-for="[label, value] in metadataEntries">
        <dt :key="label">{{ label }}:</dt>
        <dd :key="`${label}-value`">{{ value }}</dd>
      </template>
    </dl>
    <div v-else class="text-center">
      <b-spinner type="grow" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '/src/assets/css/app';

.metadata-container {
  padding: 1rem 0.5rem;
  border: 1px solid app.$gray-light;
  border-top: none;
  margin-bottom: 0.5rem;
}
.metadata-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.75rem;
  line-height: 2rem;
  align-items: center;
}
.metadata-list > dt {
  font-weight: normal;
  text-align: right;
}
.metadata-list > dd {
  margin-bottom: 0;
  word-break: break-all;
}
</style>
