<script>
import { titleMixin } from '@/mixins/titleMixin'
import FileStorageBrowser from '@/views/FileStorageBrowserPage/FileStorageBrowser'
import UserProfileBackendAccess from '@/services/UserProfileBackendAccess'
import QuotaProgressBar from '@/common/QuotaProgressBar'
import FileStorageBackendAccess from '@/services/FileStorageBackendAccess'
import TaskStatus from '@/services/TaskStatus'

export default {
  name: 'FileStorageBrowserPage',
  components: { FileStorageBrowser, QuotaProgressBar },
  mixins: [titleMixin],
  data() {
    return {
      TaskStatus,

      currentProject: null,
      projects: [],
      fileMaxSize: null,

      projectFetchStatus: TaskStatus.PENDING
    }
  },
  computed: {
    titleItems() {
      return [this.$pgettext('Page heading', 'Files')]
    },
    options() {
      return this.projects.map((project) => ({ value: project, text: project.identifier }))
    },
    errorMessage() {
      if (this.projectFetchStatus === TaskStatus.FAILURE) {
        return this.$pgettext('Page error message', 'Could not retrieve list of projects')
      }
      if (this.projectFetchStatus === TaskStatus.SUCCESS && this.projects.length === 0) {
        return this.$pgettext(
          'Page error message',
          'You do not have access to any projects in Pre-Ingest File Storage. Please contact the service administration.'
        )
      }

      return null
    },
    hasError() {
      return Boolean(this.errorMessage)
    }
  },
  async mounted() {
    const access = new FileStorageBackendAccess()
    const data = await access.getFileSizeLimit()
    this.fileMaxSize = data.file_size_limit
    await this.getProjects()
  },
  methods: {
    async getProjects() {
      try {
        const access = new UserProfileBackendAccess()
        this.projects = await access.getProjects()
        this.currentProject = this.projects[0]
        this.projectFetchStatus = TaskStatus.SUCCESS
      } catch (e) {
        this.projectFetchStatus = TaskStatus.FAILURE
        throw e
      }
    },
    async updateProject(projectIdentifier = this.currentProject.identifier, updatedFields) {
      let updatedProject

      // If updatedFields,get project data from local variable, otherwise fetch from server
      if (updatedFields) {
        const oldProject = this.projects.find((project) => project.identifier === projectIdentifier)
        updatedProject = { ...oldProject, ...updatedFields }
      } else {
        const access = new UserProfileBackendAccess()
        updatedProject = await access.getProject(projectIdentifier)
      }

      if (updatedProject.identifier === this.currentProject.identifier) {
        this.currentProject = updatedProject
      }
      // Update projects with updated project
      this.projects = this.projects.map((project) =>
        project.identifier === updatedProject.identifier ? updatedProject : project
      )
    }
  }
}
</script>

<template>
  <page-container :has-error="hasError" :error-message="errorMessage">
    <div v-if="currentProject">
      <div class="top-items">
        <h1 class="d-flex align-items-center">
          {{ $gettext('Files of project') }}
          <b-form-select v-model="currentProject" :options="options" data-test="project-select" />
        </h1>
        <span class="project-quota-bar">
          <quota-progress-bar
            :max-value="currentProject.quota"
            :current-value="currentProject.usedQuota"
          />
        </span>
      </div>
      <file-storage-browser
        :project="currentProject"
        :file-max-size="fileMaxSize"
        @update-project-quota="
          (projectIdentifier, updatedFields) => updateProject(projectIdentifier, updatedFields)
        "
      />
    </div>
  </page-container>
</template>

<style scoped>
.top-items {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.project-quota-bar {
  width: 15rem;
}
h1 {
  font-size: 1.25rem;
}
select {
  font-size: 1.125rem;
  max-width: fit-content;
  padding-right: 2.5rem;
  margin-left: 0.5rem;
}
hr {
  margin-bottom: 2rem;
}
</style>
