<script>
import DatasetBackendAccess from '@/services/DatasetBackendAccess'

export default {
  name: 'Download',
  props: {
    dataset: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dipExpiryUnix: 0,
      ready: false,
      dipExists: null,
      isDipCreatable: true,
      dipCreationInProgress: false
    }
  },
  computed: {
    downloadUrl() {
      const access = new DatasetBackendAccess()
      return access.getDipDownloadUrl(this.dataset.identifier)
    },
    dipExpiryUnixMilliSeconds() {
      return this.dipExpiryUnix * 1000
    }
  },
  mounted() {
    this.checkDipStatus()
  },
  methods: {
    async createDip() {
      try {
        this.dipCreationInProgress = true
        const access = new DatasetBackendAccess()
        await access.createDip(this.dataset.identifier)
        this.dipExists = true
        this.dipCreationInProgress = false
        setTimeout(this.checkDipStatus, 60000)
      } catch {
        this.isDipCreatable = false
        this.dipCreationInProgress = false
      }
    },
    async checkDipStatus() {
      try {
        const access = new DatasetBackendAccess()
        const data = await access.getDipStatus(this.dataset.identifier)
        this.ready = data.complete
        this.dipExpiryUnix = data.expiry
        this.dipExists = data.expiry !== 0
        setTimeout(this.checkDipStatus, 60000)
      } catch {
        this.dipExists = false
      }
    }
  }
}
</script>

<template>
  <div>
    <h2>{{ $gettext('Dissemination information package (DIP)') }}</h2>
    <p v-if="!isDipCreatable">
      <i>{{ $gettext(`Can't create DIP yet. Try again later.`) }}</i>
    </p>
    <b-spinner v-else-if="dipExists === null" />
    <div v-else-if="!dipExists">
      <b-button variant="outline-primary" :disabled="dipCreationInProgress" @click="createDip">
        <b-icon icon="download" class="mr-2" />
        <translate>Create DIP</translate>
      </b-button>
      <p>
        <small>{{ $gettext('Create a new DIP to download dataset.') }}</small>
      </p>
    </div>
    <div v-else-if="ready">
      <a :href="downloadUrl" download>
        <b-icon icon="file-zip" class="mr-2" />{{ $pgettext('DIP download', 'DIP') }}</a
      >
      <p>
        <small>
          {{ $gettext('Expires on') }}
          {{ dipExpiryUnixMilliSeconds | formatDate }}.
        </small>
      </p>
    </div>
    <div v-else class="text-primary d-flex align-items-center">
      <b-spinner class="mr-2" />
      <strong>
        {{
          $gettext('Preparing dissemination information package. This might take several hours.')
        }}
      </strong>
    </div>
  </div>
</template>

<style scoped>
h2 {
  font-size: 1rem;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
}
</style>
