<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'primary'
    },
    dataTest: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
// Have to use inline SVG to get use same color as text
</script>
<template>
  <b-button
    :variant="variant"
    class="arrow-button"
    :disabled="disabled"
    :data-test="dataTest"
    @click="$emit('click')"
  >
    <svg
      v-if="left"
      width="20"
      height="20"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="arrow left"
    >
      <g>
        <path
          id="Vector"
          d="M12.2792 5.5575L11.2217 4.5L6.72168 9L11.2217 13.5L12.2792 12.4425L8.84418 9L12.2792 5.5575Z"
          fill="currentColor"
        />
      </g>
    </svg>
    {{ text }}
    <svg
      v-if="!left && !isLoading"
      width="20"
      height="20"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="arrow right"
    >
      <g>
        <path
          id="Vector"
          d="M7.27869 5L6.22119 6.0575L9.65619 9.5L6.22119 12.9425L7.27869 14L11.7787 9.5L7.27869 5Z"
          fill="currentColor"
        />
      </g>
    </svg>
    <b-spinner v-else-if="isLoading" small class="ml-2" />
  </b-button>
</template>
<style scoped>
#main-container .arrow-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
