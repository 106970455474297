<script>
import FileChecksumListInput from '@/views/FileStorageBrowserPage/FileChecksumListInput'
import FormInput from '@/common/FormInput'
import * as pathUtils from '@/common/path.js'

const SUPPORTED_ARCHIVE_FORMATS = ['tar', 'tar.gz', 'tar.xz', 'tar.bz2', 'zip']

export default {
  name: 'FileStorageUploadArchiveModal',
  components: {
    FileChecksumListInput,
    FormInput
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    archiveMaxSize: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      modalShow: false,
      path: '',
      newDirectoryName: '',

      archiveFile: null,

      addChecksum: false,
      isChecksumValid: false,
      checksum: '',
      checksumAlgorithm: ''
    }
  },
  computed: {
    directoryNameHasSlash() {
      return this.newDirectoryName.includes('/')
    },
    directoryNameTooLong() {
      return this.newDirectoryName.length >= 256
    },
    isNewDirectoryNameValid() {
      // Directory name cannot be empty or contain the path separator '/'
      return (
        this.newDirectoryName.length > 0 &&
        !this.directoryNameTooLong &&
        !this.directoryNameHasSlash
      )
    },
    isArchiveFileValid() {
      if (!this.archiveFile) {
        return false
      }

      const name = this.archiveFile.name.toLowerCase()

      return SUPPORTED_ARCHIVE_FORMATS.some((archiveFormat) => name.endsWith(`.${archiveFormat}`))
    },
    canUploadArchive() {
      return (
        this.isNewDirectoryNameValid &&
        this.isArchiveFileValid &&
        !this.archiveFileIsTooLarge &&
        (!this.addChecksum || this.isChecksumValid)
      )
    },

    modalTitle() {
      return this.$pgettext('Modal title', 'Upload archive')
    },
    modalOkText() {
      return this.$pgettext('Button text', 'Upload')
    },
    modalCancelText() {
      return this.$pgettext('Button text', 'Cancel')
    },
    remainingQuotaText() {
      return this.$options.filters.formatBytes(this.totalQuota - this.usedQuota, 2)
    },
    newDirectoryLabel() {
      return this.$pgettext('Input label', 'New directory name')
    },
    fileBrowseText() {
      return this.$pgettext('File form text', 'Browse')
    },
    uploadPlaceholder() {
      return this.$gettext('Choose file or drop it here...')
    },
    uploadDropPlaceholder() {
      return this.$gettext('Drop file here...')
    },
    extractionPath() {
      return pathUtils.join(this.path, this.newDirectoryName)
    },
    totalQuota() {
      return this.project.quota
    },
    usedQuota() {
      return this.project.usedQuota
    },
    archiveFileIsTooLarge() {
      return this.archiveFile?.size >= this.archiveMaxSize
    },
    formattedMaxArchiveSize() {
      return this.$options.filters.formatBytes(this.archiveMaxSize)
    },
    archiveFileInputState() {
      if (!this.archiveFile) {
        return null
      }
      return this.isArchiveFileValid && !this.archiveFileIsTooLarge
    }
  },
  methods: {
    async openDialog(path) {
      this.path = path
      this.newDirectoryName = ''
      this.archiveFile = null
      this.modalShow = true

      // Update the remaining project quota to ensure the up-to-date value
      // is displayed
      this.$emit('update-project-quota')
    },
    async uploadArchive() {
      const entry = {
        file: this.archiveFile,
        extractDir: this.newDirectoryName
      }

      if (this.addChecksum) {
        entry.checksumAlgorithm = this.checksumAlgorithm
        entry.checksum = this.checksum
      }

      this.$emit('archive-submitted', this.path, entry)
    },
    /**
     * Handler called when the list of checksums detected by
     * FileChecksumListInput changes.
     *
     * The event value only contains one value despite the event name
     * since we're dealing with a single file.
     */
    checksumsChanged(result) {
      if (!this.archiveFile) {
        return
      }

      if (!(this.archiveFile.name in result)) {
        return
      }

      const entry = result[this.archiveFile.name]

      this.checksum = entry.checksum
      this.checksumAlgorithm = entry.algorithm
    }
  }
}
</script>

<template>
  <b-modal
    v-model="modalShow"
    :title="modalTitle"
    :ok-disabled="!canUploadArchive"
    :ok-title="modalOkText"
    :cancel-title="modalCancelText"
    :return-focus="`#file-entry-${path.replaceAll('/', '-')}`"
    @ok="uploadArchive"
    @show="$emit('show')"
    @hidden="$emit('hidden')"
  >
    <p class="upload-title">
      <translate>Upload archive contents under directory</translate>:
      <template v-if="isNewDirectoryNameValid">
        <strong>{{ extractionPath }}</strong>
      </template>
      <template v-else>
        <strong>{{ path }}</strong
        >/<code>?</code>
      </template>
    </p>
    <form-input
      id="input-new-directory-name"
      v-model="newDirectoryName"
      :is-valid="isNewDirectoryNameValid"
      :label="newDirectoryLabel"
      required
    >
      <template #description>
        <translate translate-context="Directory name requirements">
          Directory name must meet the following requirements:
        </translate>
        <ul>
          <li v-translate translate-context="Form validation requirement">
            length of 1-255 characters
          </li>
          <li
            v-translate="{ symbol: '<kbd>/</kbd>' }"
            render-html="true"
            translate-context="Form validation requirement"
          >
            cannot contain the %{ symbol } symbol
          </li>
        </ul>
      </template>
      <template #feedback>
        <b-form-invalid-feedback v-if="directoryNameTooLong">
          <translate>Directory name too long</translate>
        </b-form-invalid-feedback>
        <b-form-invalid-feedback v-if="directoryNameHasSlash">
          <translate>Directory name can't contain </translate><kbd>/</kbd>
        </b-form-invalid-feedback>
      </template>
    </form-input>
    <b-form-group>
      <b-form-file
        id="input-archive-file"
        v-model="archiveFile"
        :browse-text="fileBrowseText"
        :placeholder="uploadPlaceholder"
        :drop-placeholder="uploadDropPlaceholder"
        :state="archiveFileInputState"
        required
      />
      <small>{{ $gettext('Archive file format must be either TAR or ZIP.') }}</small>
      <b-form-invalid-feedback v-if="!isArchiveFileValid" translate-context="Input feedback">
        {{ $gettext('Invalid archive file format') }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-else>
        {{
          $gettextInterpolate(
            $gettext(
              'Archive file is too large (maximum file size is %{formattedMaxArchiveSize}).'
            ),
            { formattedMaxArchiveSize }
          )
        }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group>
      <b-form-checkbox v-model="addChecksum" switch>
        <translate>Verify archive integrity</translate>
      </b-form-checkbox>
      <b-collapse v-model="addChecksum">
        <file-checksum-list-input
          :file-names="archiveFile ? [archiveFile.name] : []"
          :single="true"
          @checksums-changed="checksumsChanged"
          @is-valid-changed="isChecksumValid = $event"
        />
      </b-collapse>
    </b-form-group>
    <hr />
    <b-progress v-if="totalQuota > 0" class="mt-2" :max="totalQuota">
      <b-progress-bar :value="usedQuota" variant="secondary" />
    </b-progress>
    <p>
      <translate>Remaining quota before upload</translate>:
      {{ remainingQuotaText }}
    </p>
    <b-alert show variant="warning">
      {{
        $gettext(
          'Ensure you have enough remaining quota to extract this file. The total size of extracted files cannot be calculated before upload.'
        )
      }}
    </b-alert>
  </b-modal>
</template>

<style scoped>
.upload-title {
  word-break: break-word;
}
</style>
