import FileStorageBackendAccess from '@/services/FileStorageBackendAccess'

const TaskStatus = Object.freeze({
  PENDING: 'pending',
  ERROR: 'error',
  DONE: 'done'
})

class FileBackgroundTask {
  constructor({ taskId, project, name, dir, path, type } = {}) {
    this.access = new FileStorageBackendAccess()
    this.taskId = taskId
    this.project = project

    this.name = name
    this.dir = dir
    this.path = path

    this.type = type

    // Error message shown to the end user. This can be compiled from multiple
    // errors into one string.
    this.error = null
    this.status = 'pending'
  }

  get complete() {
    return [TaskStatus.ERROR, TaskStatus.DONE].includes(this.status)
  }

  async poll() {
    if (this.complete) {
      return
    }

    try {
      const data = await this.access.getTaskInformation(this.taskId)

      this.status = data.status

      if (this.status === TaskStatus.ERROR) {
        // The task failed, and a list of errors were returned.
        // Format them into one string.
        if (data.errors) {
          const errorMessages = data.errors.map((error) => error.message)
          this.error = errorMessages.join(', ')
        } else {
          this.error = data.message
        }
      } else {
        // The task was successful. Include the success message.
        this.message = data.message
        this.error = null
      }
    } catch (error) {
      // The poll request itself failed. Return an error message based on the
      // JSON returned, if any. In this case, only one error will be included.
      let errorMessage
      if ('error' in error.response.data) {
        errorMessage = error.response.data.error
      } else {
        errorMessage = this.$pgettext('Error message', 'Error message not available')
      }

      this.error = `${error.response.status} - ${errorMessage}`
      this.message = null
    }
  }
}

/**
 * Create a FileBackgroundTask from a tusUpload instance to allow
 * tracking a background job resulting from a finished upload
 */
function getTaskFromTusUpload(tusUpload) {
  // The resource ID is the last part of the upload URL
  const resourceId = tusUpload.url
    .split('/')
    .filter((x) => x.length > 0) // Eliminate leading slash, if any
    .at(-1)

  const task = new FileBackgroundTask({
    taskId: resourceId,
    project: tusUpload.options.metadata.project_id,
    name: tusUpload.options.metadata.name,
    dir: tusUpload.options.metadata.dir,
    path: tusUpload.options.metadata.upload_path,
    type: tusUpload.options.metadata.type
  })

  return task
}

export default FileBackgroundTask
export { FileBackgroundTask, TaskStatus, getTaskFromTusUpload }
