<script>
import DatasetStateService from '@/services/DatasetStateService'
import DatasetSubPages from '@/services/DatasetSubPages'
const {
  isDatasetTabEnabled,
  isProposeTabEnabled,
  isAcceptTabEnabled,
  isGeneratingMetadata,
  isValidatingMetadata
} = DatasetStateService

export default {
  props: {
    pasState: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    datasetStateChanging: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    pages() {
      const values = [
        {
          name: this.$gettext('Dataset'),
          isEnabledFunc: isDatasetTabEnabled,
          isSpinner: false,
          number: DatasetSubPages.IDENTIFY
        },
        {
          name: this.$gettext('Propose'),
          isEnabledFunc: isProposeTabEnabled,
          isSpinner: isGeneratingMetadata(this.pasState),
          number: DatasetSubPages.PROPOSE
        },
        {
          name: this.$gettext('Accept'),
          isEnabledFunc: isAcceptTabEnabled,
          isSpinner: isValidatingMetadata(this.pasState),
          number: DatasetSubPages.ACCEPT
        }
      ]
      return values.map(({ name, isEnabledFunc, isSpinner, number }) => {
        const isEnabled = isEnabledFunc(this.pasState)
        const isCurrentPage = this.currentPage === number
        let variant = ''
        if (isCurrentPage) {
          variant = 'primary'
        } else if (isEnabled) {
          variant = 'success'
        }
        return {
          name,
          number,
          isEnabledFunc,
          isSpinner,
          variant,
          isBold: isEnabled,
          textClass: variant ? `text-${variant}` : '',
          isDisabled: !isEnabled,
          onClick: () => this.$emit('set-page', number)
        }
      })
    },
    spinnerClass() {
      const isOnSpinnerPage =
        this.currentPage === DatasetSubPages.IDENTIFY_IN_PROGRESS ||
        this.currentPage === DatasetSubPages.VALIDATION_IN_PROGRESS
      return isOnSpinnerPage ? 'text-primary' : ''
    }
  },
  watch: {
    pasState() {
      this.goToLastValidPage()
    }
  },
  mounted() {
    this.goToLastValidPage()
  },
  methods: {
    goToLastValidPage() {
      let lastValidPage = this.pages.findLast((page) => page.isEnabledFunc(this.pasState)).number
      if (isGeneratingMetadata(this.pasState)) {
        lastValidPage = DatasetSubPages.IDENTIFY_IN_PROGRESS
      } else if (isValidatingMetadata(this.pasState)) {
        lastValidPage = DatasetSubPages.VALIDATION_IN_PROGRESS
      }
      this.$emit('set-page', lastValidPage)
    }
  }
}
</script>
<template>
  <div class="breadcrumbs-container">
    <b-icon icon="house-door-fill" />
    <template v-for="page in pages">
      <button
        v-if="page.isSpinner"
        :key="`${page.name}-spinner`"
        class="no-style-button d-flex align-items-center"
        @click="goToLastValidPage"
      >
        <b-spinner :class="spinnerClass" />
      </button>
      <b-icon v-else :key="`${page.name}-arrow`" icon="arrow-right-short" :variant="page.variant" />
      <button
        :key="`${page.name}-button`"
        data-test="breadcrumb-button"
        :class="['no-style-button', page.textClass, { 'font-weight-bold': page.isBold }]"
        :disabled="page.isDisabled || datasetStateChanging"
        @click="page.onClick"
      >
        {{ page.name }}
      </button>
    </template>
  </div>
</template>

<style scoped lang="scss">
.breadcrumbs-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #707070;
  margin: 1rem 0;
}
.bi-arrow-right-short,
.spinner-border {
  height: 1.5rem;
  width: 1.5rem;
}
.bi-house-door-fill {
  height: 1.5rem;
  width: 1.5rem;
}
</style>
