<script>
export default {
  name: 'StorageItem',
  props: {
    storageItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    fileCount() {
      return this.storageItem.fileCount
    },
    depthMargin() {
      if (this.storageItem.depth > 0) {
        return 3 * this.storageItem.depth
      }
      return 0
    }
  }
}
</script>

<template>
  <div :class="{ 'root-item': storageItem.isRoot }">
    <div
      v-if="!storageItem.isLoadingItem"
      :class="[
        'storage-item-grid',
        'storage-item',
        { 'metadata-opened': storageItem.metadataOpen }
      ]"
    >
      <span
        v-if="!storageItem.isRoot"
        :style="{ 'padding-left': `${depthMargin}rem` }"
        class="d-flex"
      >
        <button
          v-if="storageItem.isDir"
          class="no-style-button d-flex align-items-center"
          :data-test="storageItem.id"
          @click="$emit('toggle-storage-item-folder', storageItem)"
        >
          <b-icon v-if="!storageItem.isOpen" icon="chevron-right" />
          <b-icon v-else icon="chevron-down" />
          <span class="item-title">
            <slot :storageItem="storageItem"></slot>
          </span>
        </button>
        <span v-else class="item-title">
          <slot :storageItem="storageItem"></slot>
        </span>
      </span>
      <h2 v-else>{{ $gettext('Data') }}</h2>
      <span v-if="storageItem.size !== undefined" id="size-field">
        <span v-if="storageItem.isDir">
          {{
            $gettextInterpolate(
              $ngettext('%{ fileCount } file,', '%{ fileCount } files,', fileCount),
              { fileCount }
            )
          }}
        </span>
        {{ storageItem.size | formatBytes(0) }}
      </span>
      <slot name="right-side-content" :storageItem="storageItem"></slot>
    </div>
    <b-spinner v-else type="grow" :style="{ 'margin-left': `${depthMargin}rem` }" />
    <slot name="extra" :storageItem="storageItem"></slot>
    <hr v-if="storageItem.isRoot" class="top-divider" />
  </div>
</template>

<style lang="scss" scoped>
@use '/src/assets/css/app';
.root-item {
  height: 2.5rem;
}
.root-item .storage-item {
  padding-top: 0;
}
.top-divider {
  margin-bottom: 0.75rem;
}
.storage-item-grid {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  column-gap: 1rem;
}
h2 {
  font-size: 1rem;
}
.storage-item.metadata-opened {
  background-color: app.$blue-light;
}
.storage-item:has(> span button:focus) {
  background-color: app.$blue-light;
}
.item-title {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  width: 100%;
}
.storage-item {
  padding: 0.5rem 0;
}
.b-icon:not(.bi-chevron-down, .bi-chevron-right) {
  height: 1.5rem;
  width: 1.875rem;
}
.bi-chevron-right,
.bi-chevron-down {
  width: 3rem;
}
.spinner-border {
  width: 1.25rem;
  height: 1.25rem;
}
#main-container .show-metadata-button {
  padding: 0 0;
  color: app.$blue;
}
</style>
