<script>
import { getters } from '@/common/store.js'
import AgreementListTable from '@/views/AgreementsPage/AgreementListTable.vue'
import { titleMixin } from '@/mixins/titleMixin'
export default {
  name: 'AgreementsPage',
  components: {
    AgreementListTable
  },
  mixins: [titleMixin],
  computed: {
    titleItems() {
      return [this.$gettext('Agreements')]
    }
  },
  methods: {
    isAdminUser() {
      return getters.getUser().admin
    }
  }
}
</script>

<template>
  <page-container>
    <header>
      <h1>
        <translate> Agreements </translate>
      </h1>
      <b-button
        v-if="isAdminUser()"
        id="button-create-new-agreement"
        v-translate
        variant="outline-primary"
        :to="{ name: 'CreateAgreementPage' }"
        class="header-button"
      >
        Create new
      </b-button>
    </header>
    <agreement-list-table />
  </page-container>
</template>
