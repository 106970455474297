<script>
export default {
  props: {
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <main :id="id" class="container">
    <div v-if="hasError" class="centered-container">
      <p v-if="hasError">
        {{ errorMessage || $gettext('An error occured when loading the page') }}
      </p>
      <b-button variant="outline-primary" @click="$router.back()">
        {{ $gettext('Back to previous page') }}
      </b-button>
    </div>
    <slot v-else>
      <div class="centered-container">
        <b-spinner style="width: 3rem; height: 3rem" />
      </div>
    </slot>
  </main>
</template>

<style scoped>
.centered-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  width: 100%;
  margin: 6rem 0;
  text-align: center;
}
p {
  font-size: 1.125rem;
  max-width: 35rem;
}
</style>
