import BackendAccess from '@/services/BackendAccess'

class AgreementBackendAccess {
  constructor() {
    this.access = new BackendAccess()
  }

  async searchAgreements(limit, offset) {
    const response = await this.access.service.get(`${this.access.basePath}agreements/`, {
      params: { limit: limit, offset: offset }
    })
    return response.data
  }

  createAgreement(agreement, callback, errorCallback) {
    return this.access.postAsJSON('agreements/', agreement, callback, errorCallback)
  }

  async getAgreement(agreementId) {
    const path = `${this.access.basePath}agreements/${agreementId}/`

    try {
      const response = await this.access.service.get(path, { silenceAlertForStatuses: [404] })

      return response.data
    } catch (e) {
      if (e.response.status === 404) {
        return null
      }

      throw e
    }
  }

  modifyAgreement(agreementId, agreementJson, callback, errorCallback) {
    return this.access.patchAsJSON(
      'agreements/' + agreementId + '/',
      agreementJson,
      callback,
      errorCallback
    )
  }
}

export default AgreementBackendAccess
