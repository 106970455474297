<script>
import _ from 'lodash'
export default {
  data() {
    return {
      isTyping: false,
      searchQuery: ''
    }
  },
  watch: {
    searchQuery: _.debounce(function () {
      this.isTyping = false
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.handleChange(this.searchQuery)
      }
    }
  },
  methods: {
    handleChange(searchQuery) {
      this.$emit('dataset-search-word-changed', searchQuery)
    },
    placeHolder() {
      return this.$gettext('Dataset title')
    }
  }
}
</script>

<template>
  <div id="dataset-search-bar">
    <b-container>
      <b-form-group id="dataset-search-bar-group">
        <label id="input-search-word-label" for="input-search-word">
          <translate>Search your available datasets</translate>
        </label>
        <b-input-group>
          <b-form-input
            id="input-search-word"
            v-model="searchQuery"
            type="search"
            :placeholder="placeHolder()"
            name="input-search-word"
            @input="isTyping = true"
          />
          <b-input-group-append class="search-icon" is-text>
            <img src="@/assets/images/search.svg" alt="search" height="24px" width="24px" />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
@use '/src/assets/css/app';

#dataset-search-bar {
  background-color: app.$blue;
}
#dataset-search-bar-group {
  padding: 2rem 12.5rem 2rem 12.5rem;
  margin: 0 0;
}
#dataset-search-bar .input-group-text {
  background-color: app.$white;
  color: app.$blue;
}
#input-search-word {
  border-right: none;
}
#input-search-word-label {
  color: app.$white;
  font-size: 2rem;
  margin-bottom: 0.75rem;
}
</style>
