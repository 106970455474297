import BackendAccess from '@/services/BackendAccess'

class UserProfileBackendAccess {
  async getProfile() {
    const access = new BackendAccess()
    const response = await access.service.get(`${access.basePath}profile/`, { preventLoginRedirect: true })
    return response.data
  }

  async createUserToken({ name, expirationDate = null, projects } = {}) {
    const access = new BackendAccess()

    const params = new URLSearchParams()
    params.append('name', name)
    // List of projects sent as comma-separated list
    params.append('projects', projects.join(','))

    if (expirationDate !== null) {
      params.append('expiration_date', expirationDate)
    }

    const response = await access.service.post(`${access.basePath}tokens/create`, params)

    return response.data.token
  }

  async createUserSessionToken() {
    const access = new BackendAccess()

    const response = await access.service.post(`${access.basePath}tokens/create-session`)

    return response.data.token
  }

  async getTokens() {
    const access = new BackendAccess()

    const response = await access.service.get(`${access.basePath}tokens/list`)
    const tokens = response.data.tokens

    for (const token of tokens) {
      token.expirationDate = token.expiration_date
      delete token.expiration_date
    }

    return tokens
  }

  async getProjects() {
    const access = new BackendAccess()

    const response = await access.service.get(`${access.basePath}profile/projects`)
    const projects = response.data.projects

    for (const project of projects) {
      project.usedQuota = project.used_quota
      delete project.used_quota
    }

    return projects
  }

  async getProject(identifier) {
    const projects = await this.getProjects()
    const result = projects.find((project) => project.identifier === identifier)

    if (result) {
      return result
    } else {
      return null
    }
  }

  async deleteUserToken(tokenId) {
    const access = new BackendAccess()

    const params = new URLSearchParams()
    params.append('token_id', tokenId)

    const response = await access.service.delete(`${access.basePath}tokens/`, {
      data: params
    })

    return response.data.deleted
  }
}

export default UserProfileBackendAccess
