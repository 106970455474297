<script>
import DatasetBackendAccess from '@/services/DatasetBackendAccess'
import DatasetStateService from '@/services/DatasetStateService'
import RejectDataset from './RejectDataset'
import ResetDataset from './ResetDataset'
import BigQuotaProgressBar from '@/common/BigQuotaProgressBar'
import { States } from '@/services/DatasetStates'
import ButtonWithArrow from '@/common/ButtonWithArrow'

export default {
  name: 'AcceptDataset',
  components: {
    RejectDataset,
    ResetDataset,
    BigQuotaProgressBar,
    ButtonWithArrow
  },
  props: {
    dataset: {
      type: Object,
      required: true
    },
    datasetStateChanging: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      buttonIsLoading: false
    }
  },
  computed: {
    agreementQuotaUsedAfterDatasetAccepted() {
      return this.dataset.size + this.dataset.agreementQuotaUsed
    },
    agreementQuotaLeft() {
      return this.dataset.agreementQuota - this.agreementQuotaUsedAfterDatasetAccepted
    },
    quotaExceeded() {
      return this.agreementQuotaLeft < 0
    },
    preservable() {
      return DatasetStateService.canBeAcceptedOrRejected(this.dataset.passtate)
    },
    inPackaging() {
      const packagingState = States.IN_PACKAGING_SERVICE
      return this.dataset.passtate === packagingState
    },
    rejected() {
      const rejectedState = States.REJECTED_IN_DIGITAL_PRESERVATION_SERVICE
      return this.dataset.passtate === rejectedState
    },
    inPreservation() {
      const inPreservationState = States.IN_DIGITAL_PRESERVATION
      return this.dataset.passtate === inPreservationState
    },
    isAgreeButtonEnabled() {
      return (
        !this.quotaExceeded &&
        !this.datasetStateChanging &&
        this.dataset.rights.approve &&
        this.preservable
      )
    }
  },
  methods: {
    async handleAccept() {
      this.buttonIsLoading = true
      this.$emit('start-dataset-state-change')
      try {
        const access = new DatasetBackendAccess()
        await access.acceptDataset(this.dataset.identifier)
      } catch (e) {
        this.$emit('finish-dataset-state-change')
        throw e
      }
      this.$emit('update-dataset')
    },
    updateDataset() {
      this.$emit('update-dataset')
    }
  }
}
</script>

<template>
  <div id="dataset-accept-page">
    <hr />
    <h2>{{ $gettext('Accept dataset') }}</h2>
    <p>
      {{
        $gettext(
          'After the dataset is accepted, it will be packaged and submitted to the preservation service. An ingest report detailing the result will be generated and can be viewed later.'
        )
      }}
    </p>
    <h3>{{ $gettext('Quota usage') }}</h3>
    <template v-if="!quotaExceeded">
      <p>
        <translate>Space remaining after dataset is accepted:</translate>
        <strong> {{ agreementQuotaLeft | formatBytes(2) }}</strong>
      </p>
    </template>
    <template v-else>
      <p class="text-danger">
        <translate>Dataset exceeds remaining quota by</translate>
        <strong> {{ (-1 * agreementQuotaLeft) | formatBytes(2) }}</strong
        >.
      </p>
    </template>
    <big-quota-progress-bar
      :current-value="agreementQuotaUsedAfterDatasetAccepted"
      :max-value="dataset.agreementQuota"
    />
    <h3>{{ $gettext('Justification for preserving dataset') }}</h3>
    <p class="justification">
      <span v-if="dataset.passtateReasonDesc">{{ dataset.passtateReasonDesc }}</span>
      <i v-else>{{ $gettext('No justification provided.') }}</i>
    </p>
    <hr />
    <div class="bottom-buttons-container">
      <div class="button-container">
        <button-with-arrow
          :text="$gettext('Previous')"
          :disabled="datasetStateChanging"
          left
          variant="outline-primary"
          @click="$emit('previous-page')"
        />
        <reset-dataset
          v-if="!inPreservation && !inPackaging"
          id="button-reset-container"
          :dataset="dataset"
          :dataset-state-changing="datasetStateChanging"
          @start-dataset-state-change="$emit('start-dataset-state-change')"
          @finish-dataset-state-change="$emit('finish-dataset-state-change')"
          @reset-dataset="updateDataset"
        />
      </div>
      <div class="button-container">
        <reject-dataset
          :dataset="dataset"
          :dataset-state-changing="datasetStateChanging"
          @start-dataset-state-change="$emit('start-dataset-state-change')"
          @finish-dataset-state-change="$emit('finish-dataset-state-change')"
          @reject-dataset="$emit('update-dataset')"
        />
        <b-button
          id="button-accept"
          variant="primary"
          :disabled="!isAgreeButtonEnabled"
          data-test="accept-dataset"
          @click="handleAccept"
        >
          <translate>Accept</translate>
          <b-spinner v-if="buttonIsLoading" small class="ml-1" />
          <b-icon v-else icon="check-circle" class="ml-1" />
        </b-button>
      </div>
    </div>
  </div>
</template>
<style scoped>
h2 {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}
h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.bottom-buttons-container {
  display: flex;
  justify-content: space-between;
  margin: 2.5rem 0;
}
#dataset-accept-page .justification {
  margin-bottom: 3.75rem;
}
</style>

<style>
#dataset-accept-page .big-quota-bar {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
</style>
