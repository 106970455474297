<script>
import { getters } from '@/common/store.js'
import QuotaProgressBar from '@/common/QuotaProgressBar'
import HeaderWithBackButton from '@/common/HeaderWithBackButton'
import AgreementBackendAccess from '@/services/AgreementBackendAccess'
import DatasetListAndFilter from '@/views/DatasetsPage/DatasetListAndFilter'
import Statistics from '@/views/AgreementPage/Statistics'
import { titleMixin } from '@/mixins/titleMixin'
import { States } from '@/services/DatasetStates'
import TaskStatus from '@/services/TaskStatus'

export default {
  name: 'AgreementPage',
  components: {
    DatasetListAndFilter,
    QuotaProgressBar,
    Statistics,
    HeaderWithBackButton
  },
  mixins: [titleMixin],
  data() {
    return {
      TaskStatus,

      agreement: null,
      agreementFetchStatus: TaskStatus.PENDING,

      // Datasets
      datasetCurrentPage: 1,
      datasetPerPage: 10,
      datasetState: States.ALL_STATES.toString(),
      datasetsFakeLoading: false
    }
  },
  computed: {
    titleItems() {
      const items = [this.$gettext('Agreement')]
      if (this.agreement) {
        items.push(this.agreement.name)
      }
      return items
    },
    filteredDatasets() {
      return (
        this.agreement?.datasets.filter(
          (dataset) =>
            this.datasetState === States.ALL_STATES.toString() ||
            this.datasetState === dataset.passtate
        ) || []
      )
    },
    datasets() {
      const startI = (this.datasetCurrentPage - 1) * this.datasetPerPage
      const endI = startI + this.datasetPerPage
      return this.filteredDatasets.slice(startI, endI)
    },
    datasetsLength() {
      return this.filteredDatasets.length
    },
    errorMessage() {
      if (this.agreementFetchStatus === TaskStatus.FAILURE) {
        return this.$pgettext('Page error message', 'Agreement could not be retrieved')
      }

      if (this.agreementFetchStatus === TaskStatus.SUCCESS && !this.agreement) {
        return this.$pgettext('Page error message', 'Agreement not found')
      }

      return null
    },
    hasError() {
      return Boolean(this.errorMessage)
    }
  },
  watch: {
    datasets() {
      this.datasetsFakeLoading = true
      setTimeout(() => {
        this.datasetsFakeLoading = false
      }, 200)
    },
    datasetPerPage() {
      this.datasetCurrentPage = 1
    },
    datasetState() {
      this.datasetCurrentPage = 1
    }
  },
  async mounted() {
    try {
      const access = new AgreementBackendAccess()
      this.agreement = await access.getAgreement(this.$route.params.id)
      this.updateTitle()
      this.agreementFetchStatus = TaskStatus.SUCCESS
    } catch (e) {
      this.agreementFetchStatus = TaskStatus.FAILURE
      throw e
    }
  },
  methods: {
    isAdminUser() {
      return getters.getUser().admin
    },
    filterValuesChangedHandler({ perPage, datasetState } = {}) {
      this.datasetPerPage = perPage
      this.datasetState = datasetState
    }
  }
}
</script>

<template>
  <page-container id="agreement-page" :has-error="hasError" :error-message="errorMessage">
    <template v-if="agreement">
      <header-with-back-button :title="agreement.name">
        <b-button
          v-if="isAdminUser()"
          id="button-edit-agreement"
          class="header-button"
          variant="outline-primary"
          name="button-edit-agreement"
          :to="{
            name: 'EditAgreementPage',
            params: { id: `${agreement.identifier}` }
          }"
          >{{ $gettext('Edit') }}</b-button
        >
      </header-with-back-button>
      <dl id="agreement-info">
        <dt><translate>Identifier</translate></dt>
        <dd>{{ agreement.identifier }}</dd>
        <dt><translate>Organization</translate></dt>
        <dd>{{ agreement.organization.name }}</dd>
        <dt><translate>Used quota</translate></dt>
        <dd class="quota-bar">
          <quota-progress-bar
            :current-value="agreement.quota_used"
            :max-value="agreement.quota"
            bar-is-on-right
          />
        </dd>
        <dt><translate>Contacts</translate></dt>
        <dd id="agreemeent-contacts">
          <dl v-for="contact in agreement.contact" :key="contact.name">
            <dt class="contact-name">
              {{ contact.name }}
            </dt>
            <dd>
              <p>
                <b-icon icon="envelope" class="mr-1" />
                <a :href="'mailto:' + contact.email">{{ contact.email }}</a>
              </p>
              <p v-if="contact.phone"><b-icon icon="phone" class="mr-1" /> {{ contact.phone }}</p>
            </dd>
          </dl>
        </dd>
      </dl>
      <b-tabs id="agreement-tabs">
        <b-tab active :title="$gettext('Datasets')">
          <DatasetListAndFilter
            :datasets="datasets"
            :current-page.sync="datasetCurrentPage"
            :rows="datasetsLength"
            :per-page="datasetPerPage"
            :show-only-id="true"
            :is-busy="datasetsFakeLoading"
            @search-values-changed="filterValuesChangedHandler($event)"
          />
        </b-tab>
        <b-tab :title="$gettext('Statistics')">
          <statistics :agreement="agreement" />
        </b-tab>
      </b-tabs>
    </template>
  </page-container>
</template>

<style>
#button-edit-agreement {
  float: right;
}
#agreement-page #dataset-list {
  padding: 0 0;
}
#agreement-page #dataset-filter-form {
  padding: 0 0;
}
#agreement-info {
  display: grid;
  grid-template-columns: 10.25rem auto;
  overflow-x: hidden;
  grid-row-gap: 1rem;
}
#agreemeent-contacts {
  display: flex;
  gap: 8rem;
}
#agreemeent-contacts .contact-name {
  font-weight: normal;
}
</style>
